import axios from "axios";
import config from './config';
const API_URL = config.API_URL;



const register = (username, email, password) => {
  return axios
    .post(API_URL + "customersignup", {
      username,
    email,
    password,
    })
    .then((response) => {
      
    

      if (response.data.data && response.data.data.username) {
        localStorage.setItem("user", JSON.stringify(response.data.data));
        localStorage.setItem("token", response.data.data.apptoken);
        console.log("response.data.apptoken")
      console.log(response.data.data.apptoken)

      } else {
        console.warn("No username found in response data.");
      }

      return response.data;
    });
};


const login = (username, password) => {
  return axios
    .post(API_URL + "customerlogin", {
      username, 
      password,
    })
    .then((response) => {
      
      if (response.data.errorMessage) {
        throw new Error(response.data.errorMessage);
      }

      if (response.data.data && response.data.data.username) {
        localStorage.setItem("user", JSON.stringify(response.data.data));
        localStorage.setItem("token", response.data.data.apptoken);
        console.log("response.data.apptoken")
      console.log(response.data.data.apptoken)

      } else {
        console.warn("No username found in response data.");
      }

      return response.data;
    });
};


const logout = () => {
  localStorage.removeItem("user");
  return axios.post(API_URL + "signout").then((response) => {
    return response.data;
  });
};

const getCurrentUser = () => {

  return JSON.parse(localStorage.getItem("user"));
};



const APILogin = (email, password) => {
  return axios
    .post(API_URL + "customerlogincodebase", {
      email, 
      password,
    })
    .then((response) => {
      
      if (response.data.errorMessage) {
        throw new Error(response.data.errorMessage);
      }

      if (response.data.data && response.data.data.username) {
        localStorage.setItem("user", JSON.stringify(response.data.data));
        localStorage.setItem("token", response.data.data.apptoken);
        console.log("response.data.apptoken")
      console.log(response.data.data.apptoken)

      } else {
        console.warn("No username found in response data.");
      }

      return response.data;
    });
};



const APIRegister = (username, email, password, company_name) => {
  return axios
    .post(API_URL + "customersignup", {
      username,
    email,
    password,
    company_name,
    applegmail: 1 ,
    })
    .then((response) => {
      
    

      if (response.data.data && response.data.data.username) {
        localStorage.setItem("user", JSON.stringify(response.data.data));
        localStorage.setItem("token", response.data.data.apptoken);
        console.log("response.data.apptoken")
      console.log(response.data.data.apptoken)

      } else {
        console.warn("No username found in response data.");
      }

      return response.data;
    });
};

const APIAppleRespnseCheck = (code, id_token) => {
  return axios
    .post(API_URL + "applecheckcode", {
      code: code,
      id_token: id_token,
   
    })
    .then((response) => {
      
    

      if (response.data.data && response.data.data.username) {
        localStorage.setItem("user", JSON.stringify(response.data.data));
        localStorage.setItem("token", response.data.data.apptoken);
        console.log("response.data.apptoken")
      console.log(response.data.data.apptoken)

      } else {
        console.warn("No username found in response data.");
      }

      return response.data;
    });
};


const AuthService = {
  register,
  login,
  logout,
  getCurrentUser,
  APILogin,
  APIRegister,
  APIAppleRespnseCheck,
}

export default AuthService;
