/* eslint-disable */
import React, { useState, useEffect } from "react";
import Header from "../layouts/Header";
import Sidebar from "../layouts/Sidebar";


import SettingsSidebar from "../layouts/SettingsSidebar";
import staticmessages from '../services/staticmessages';
import UserService from "../services/user.service";
import alertify from "alertifyjs";
import * as yup from 'yup'; // Import Yup
import "../../node_modules/alertifyjs/build/css/alertify.css";
import "../../node_modules/alertifyjs/build/css/themes/semantic.css";

const InvoiceSetting = () => {


  const [errors, setErrors] = useState({});
  const [isProfileLoaded, setIsProfileLoaded] = useState(false);
  const [profile, setProfile] = useState({
    invoice_prefix: "",
    invoice_autogenerate: false,
    invoice_start_no: "",

    estimate_prefix: "",
    estimate_autogenerate: false,
    estimate_start_no: "",

  });


  const fetchProfile = async () => {
    UserService.getUserProfile()
      .then((response) => {

        const {
          invoice_prefix,
          invoice_autogenerate,
          invoice_start_no,
          estimate_prefix,
          estimate_autogenerate,
          estimate_start_no,
        } = response.data;

        setProfile({
          invoice_prefix: invoice_prefix || '',
          invoice_autogenerate: invoice_autogenerate || false,
          invoice_start_no: invoice_start_no || '',
          estimate_prefix: estimate_prefix || '',
          estimate_autogenerate: estimate_autogenerate || false,
          estimate_start_no: estimate_start_no || '',
        });
        setIsProfileLoaded(true)
      })
      .catch((error) => {
        console.error("UserService error:", error);
      });
  };


  useEffect(() => {
    fetchProfile();

  }, []);

  const toastrError = (message) => {
    alertify.set("notifier", "position", "top-center");
    alertify.error(message);
  };


  const toastrSuccess = (message) => {
    alertify.set("notifier", "position", "top-center");
    alertify.success(message);
  };


  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setProfile({ ...profile, [name]: value });
  };

  const handleInputChangeCBox = (e) => {
    const { name, value } = e.target;
    const isChecked = e.target.checked;
    console.log("Checkbox is checked:", isChecked);
    setProfile({ ...profile, [name]: isChecked });
  };

  const schema = yup.object().shape({
    invoice_prefix: yup.string().required('Invoice Prefix is required'),
    invoice_autogenerate: yup.string().required('Invoice autogenerate is required'),
    invoice_start_no: yup.string()
      .matches(/^[0-9]*$/, 'Invoice Next No. can only contain numeric characters')
      .required('Invoice Next No. is required'),

    estimate_prefix: yup.string().required('Estimate Prefix is required'),
    estimate_autogenerate: yup.string().required('Estimate autogenerate is required'),
    estimate_start_no: yup.string()
      .matches(/^[0-9]*$/, 'Estimate Next No. can only contain numeric characters')
      .required('Estimate Next No. is required'),
  });

  const handleSubmit = async (e) => {
    e.preventDefault();

    console.error('Error fetching states:', "");

    try {
      await schema.validate(profile, { abortEarly: false });

      console.error('fetchStates currency' + profile.currency);
      console.error('fetchStates symbol' + profile.symbol);

      UserService.updateINVEstSer(profile)
        .then((response) => {
          setErrors(null);
          toastrSuccess(staticmessages.SETTING_ESSUCCESS);

        })
        .catch((error) => {
          toastrSuccess(staticmessages.SETTING_ERROR);

        });
    } catch (validationError) {


      if (validationError instanceof yup.ValidationError) {
        validationError.inner.forEach((err) => {
          const errorsObj = {};
          validationError.inner.forEach((error) => {
            errorsObj[error.path] = error.message;
          });
          setErrors(errorsObj);
        });
      } else {
        console.error(validationError);
      }
    }
  };

  const [menu, setMenu] = useState(false);
  const toggleMobileMenu = () => {
    setMenu(!menu);
  };

  const forceMenuClose = () => {  
    setMenu(false);
  };

  return (
    <>
      <div className={`main-wrapper ${menu ? "slide-nav" : ""}`}>
      <Header onMenuClick={() => toggleMobileMenu()} forceMenuClose={forceMenuClose} />
      <Sidebar />
      <div className="page-wrapper">
        <div className="content container-fluid">
          {/* Page Header */}
          {/* /Page Header */}
          <div className="row">
            <div className="col-xl-3 col-md-4">
              <SettingsSidebar />
            </div>
            <div className="col-xl-9 col-md-8">
              <div className="card">
                <div className="card-body w-100">
                  <div className="content-page-header">
                    <h5>Invoice/Estimate Series</h5>
                  </div>
                  <div className="row">
                    <div className="col-lg-4 col-12">
                      <div className="input-block mb-3">
                        <label>Invoice Prefix<span className="alertstrik">*</span></label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter Invoice Prefix"
                          onChange={handleInputChange}
                          id="invoice_prefix"
                          name="invoice_prefix"
                          value={profile.invoice_prefix}

                        />
                        <small className="error">{errors?.invoice_prefix}</small>
                      </div>
                    </div>
                    <div className="col-lg-4 col-12">
                      <div className="input-block mb-3">
                        <label>Invoice Next Number<span className="alertstrik">*</span></label>
                        {isProfileLoaded && (
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter Invoice Next Number"
                            onChange={handleInputChange}
                            id="invoice_start_no"
                            name="invoice_start_no"
                            value={profile.invoice_start_no}
                            maxLength="10"
                            onClick={(e) => e.target.select()}
                          />
                        )}
                        <small className="error">{errors?.invoice_start_no}</small>
                      </div>
                    </div>
                    <div className="col-lg-4 col-12">
                      <div className="input-block mb-3">
                        <label>Invoice Auto Generation</label>
                        <div className="status-toggle">
                          {isProfileLoaded && (
                            <input

                              className="check"
                              type="checkbox"
                              onChange={handleInputChangeCBox}
                              id="invoice_autogenerate"
                              name="invoice_autogenerate"
                              defaultChecked={profile.invoice_autogenerate}
                            />)}
                          <label htmlFor="invoice_autogenerate" className="checktoggle checkbox-bg">
                            checkbox
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row">

                    <div className="col-lg-4 col-12">
                      <div className="input-block mb-3">
                        <label>Estimate Prefix<span className="alertstrik">*</span></label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter Estimate Prefix"
                          onChange={handleInputChange}
                          id="estimate_prefix"
                          name="estimate_prefix"
                          value={profile.estimate_prefix}
                        />
                        <small className="error">{errors?.estimate_prefix}</small>
                      </div>
                    </div>
                    <div className="col-lg-4 col-12">
                      <div className="input-block mb-3">
                        <label>Estimate Next Number<span className="alertstrik">*</span></label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter Estimate Next Number"
                          onChange={handleInputChange}
                          id="estimate_start_no"
                          name="estimate_start_no"
                          value={profile.estimate_start_no}
                          maxLength="10"
                          onClick={(e) => e.target.select()}
                        />
                        <small className="error">{errors?.estimate_start_no}</small>
                      </div>
                    </div>
                    <div className="col-lg-4 col-12">
                      <div className="input-block mb-3">
                        <label>Estimate Auto Generation</label>
                        <div className="status-toggle">
                          {isProfileLoaded && (
                            <input

                              className="check"
                              type="checkbox"

                              onChange={handleInputChangeCBox}
                              id="estimate_autogenerate"
                              name="estimate_autogenerate"
                              defaultChecked={profile.estimate_autogenerate}
                            />
                          )}
                          <label htmlFor="estimate_autogenerate" className="checktoggle checkbox-bg">
                            checkbox
                          </label>
                        </div>
                      </div>
                    </div>



                    <div className="col-lg-12">
                      <form onSubmit={handleSubmit} className="text-end">
                        <div className="btn-path text-end">
                          <button
                            type="submit"
                            to="#"
                            className="btn btn-primary"
                            onClick={handleSubmit}
                          >
                            Save Changes
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div >
    </>
  );
};
export default InvoiceSetting;
