import React, { useEffect, useState } from "react";

import { basicPlan } from "../_components/Imagepath";
import SettingsSidebar from "../layouts/SettingsSidebar";
import Header from "../layouts/Header";
import Sidebar from "../layouts/Sidebar";
import staticmessages from '../services/staticmessages';
import AuthService from "../services/auth.service";
import UserService from "../services/user.service";

const PlanBilling = () => {

  // eslint-disable-next-line no-unused-vars
  const [profile, setProfile] = useState();
  const currentUser = AuthService.getCurrentUser();
 // eslint-disable-next-line no-unused-vars
  const [subscriptions, setSubscriptions] = useState([]);


                const handlePayment = async (amount, plan, sub) => {
                  // Fetch order_id from your server

                  const options = {
                    key: "rzp_test_QhCT8IdEtEhNN8", // Enter the Key ID generated from the Razorpay Dashboard rzp_test_QhCT8IdEtEhNN8
                    // amount: amount, // Amount is in currency subunits. Example: 50000 = 50000 paise = INR 500.
                    // currency: profile.currency,
                    subscription_id: sub,
                
                    name: staticmessages.APP_NAME,
                    description: "Test Transaction",
                    reference_id: currentUser.apptoken,


                    handler: function (response) {
                      console.log(response);
                      fetchProfile();
                    },
                    prefill: {
                      name: profile.data.company_name ? profile.data.company_name : "",
                      email: profile.data.work_email ? profile.data.work_email : "",
                      contact: profile.data.company_phone ? profile.data.company_phone : "",
                    },
                    notes: {
                      address: profile.data.company_address_line_1 ? profile.data.company_address_line_1 : "",
                      custom_param_1: profile.data.id ? profile.data.id : "",
                      custom_param_2: profile.data.username ? profile.data.username : "",
                      custom_param_3: plan ? plan : "",
                    },
                    theme: {
                      color: "#3399cc",
                    },
                  };

                  const rzp1 = new window.Razorpay(options);
                  rzp1.open();
                };



               





  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://checkout.razorpay.com/v1/checkout.js";
    script.async = true;
    document.body.appendChild(script);
  }, []);


  useEffect(() => {


    fetchProfile();

  }, []);

  // Simulating profile data loading
  const fetchProfile = async () => {
    const profileData = await UserService.getUserProfileWithPlan(); // Replace with your data fetching logic
    setProfile(profileData);

    const fsubscription = await UserService.fetchWebSubscriptions(); // Replace with your data fetching logic
    console.log(fsubscription.data[0].id);
    
          setSubscriptions(fsubscription);
  };

//{subscriptions.data[1].id}
   

  

const [menu, setMenu] = useState(false);
const toggleMobileMenu = () => {
  setMenu(!menu);
};


const forceMenuClose = () => {  
  setMenu(false);
};


  return (
    <div className={`main-wrapper ${menu ? "slide-nav" : ""}`}>
      <Header onMenuClick={() => toggleMobileMenu()} forceMenuClose={forceMenuClose} />
         <Sidebar />
      <div className="page-wrapper">
        <div className="content container-fluid">
          <div className="row">
            <div className="col-xl-3 col-md-4">
              <SettingsSidebar />
            </div>
            <div className="col-xl-9 col-md-8">
              <div className="card">
                <div className="card-body w-100">
                  <div className="content-page-header">
                    <h5 className="setting-menu">Plan &amp; Billing</h5>
                  </div>
                  <div className="packages card active">
                    <div className="package-header d-sm-flex justify-content-between">
                      <div className="d-sm-flex">
                        <span className="icon-frame d-flex align-items-center justify-content-center">
                          <img src={basicPlan} alt="img" />
                        </span>
                        <div className="">




                        </div>
                      </div>


                      <div>
                        <h5>
                          {subscriptions.data && (
                            <span>{subscriptions.data[0].currency} {subscriptions.data[0].amount}  / Month</span>
                          )}
                        </h5>

                        {profile && (
                          profile.data?.productId === "invmonthlyid" ? (
                            <button className="btn btn-block btn-outline-success active">Active</button>
                          ) : (
                            <button
                              className="btn btn-rounded btn-outline-primary me-1"
                              onClick={() => handlePayment(profile.plan.monthly * 100, "invmonthlyid", "sub_OqCv5JXJqq0VEJ")}
                            >
                              Upgrade
                            </button>
                          )
                        )}
                      </div>



                    </div>
                  </div>

                  {/* <div className="packages card">
                    <div className="package-header d-md-flex justify-content-between">
                      <div className="d-md-flex">
                        <span className="icon-frame d-flex align-items-center justify-content-center">
                          <img src={enterPrice} alt="img" />
                        </span>
                        <div className="">

                        </div>
                      </div>


                      <div>
                        <h5>
                        {subscriptions.data && (
                            <span>{subscriptions.data[2].currency} {subscriptions.data[2].amount}  / {subscriptions.data[2].plan}</span>
                          )}
                        
                        </h5>

                        {profile && (
                          profile.data?.productId === "invyearlyid" ? (
                            <button className="btn btn-block btn-outline-success active">Active</button>
                          ) : (
                            <button
                              className="btn btn-rounded btn-outline-primary me-1"
                              onClick={() => handlePayment(profile.plan.yearly * 100, "invyearlyid", "sub_OnsAausOivHOCp")}
                            >
                              Upgrade
                            </button>
                          )
                        )}
                      </div>





                    </div>
                  </div> */}







                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PlanBilling;
