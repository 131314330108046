import React from "react";
import FeatherIcon from "feather-icons-react";
import { Link } from "react-router-dom";
import { filterIcon } from "../../_components/Imagepath";
import { Tooltip } from "antd";
import PropTypes from "prop-types";
import staticmessages from '../../services/staticmessages';
import { useHistory, useLocation } from 'react-router-dom';

const ViewClientLedger = ({ show, setShow , client, openPaymentForm ,  showaddpaymet = true, isShowD = false, clickDownload}) => {
  ViewClientLedger.propTypes = {
    show: PropTypes.any.isRequired,
    setShow: PropTypes.func.isRequired,
    client: PropTypes.object.isRequired,
    openPaymentForm: PropTypes.func.isRequired,
    showaddpaymet: PropTypes.any.isRequired,

    isShowD: PropTypes.any.isRequired,
    clickDownload: PropTypes.func.isRequired,
  };

    // eslint-disable-next-line no-unused-vars
    const history = useHistory();
    // eslint-disable-next-line no-unused-vars
    const location = useLocation();







  return (
    <>
      <div className="page-header">
        <div className="content-page-header">
        <h5>{staticmessages.INVOICE_CLIENTLEDGER} : {client && client.client_name}</h5>

     
          <div className="list-btn">
            <ul className="filter-list">
           
              <li>
                <button
                 
                  className="btn btn-filters w-auto popup-toggle"
                  onClick={() => setShow(!show)}
                >
                  <span className="me-2">
                    <Tooltip title="Filter">
                      <img src={filterIcon} alt="filter" />
                    </Tooltip>
                  </span>
                  Filter
                </button>
              </li>
              <li>
                <Link className="btn-filters" to="/settings">
                  <span>
                  <Tooltip title="Settings" placement="bottom">
                    <FeatherIcon icon="settings" />
                    </Tooltip>
                  </span>
                </Link>
              </li>


              {isShowD && (
              <li className="">
                <div className="dropdown dropdown-action">
                  <Link
                    to="#"
                    className="btn-filters me-2"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                   
                    onClick={() => clickDownload()}
                  >
                    <span>
                      <FeatherIcon icon="download" />
                    </span>
                  </Link>
                  <div className="dropdown-menu dropdown-menu-end">
                    <ul className="d-block">
                      
                      <li>
                        <Link
                          className="d-flex align-items-center download-item"
                          to="#"
                          download=""
                          onClick={() => clickDownload()}

                        >
                          <i className="far fa-file-text me-2" />
                          CVS
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </li>
  )}
              


              {showaddpaymet && (
  <li>
    <button className="btn btn-primary" onClick={openPaymentForm}>
      <i className="fa fa-plus-circle me-2" aria-hidden="true" />
      Add Receipt
    </button>
  </li>
)}



            </ul>
          </div>
        </div>
      </div>
      <div id="filter_inputs" className="card filter-card">
        <div className="card-body pb-0">
          <div className="row">
            <div className="col-sm-6 col-md-3">
              <div className="input-block mb-3">
                <label>Name</label>
                <input type="text" className="form-control" />
              </div>
            </div>
            <div className="col-sm-6 col-md-3">
              <div className="input-block mb-3">
                <label>Email</label>
                <input type="text" className="form-control" />
              </div>
            </div>
            <div className="col-sm-6 col-md-3">
              <div className="input-block mb-3">
                <label>Phone</label>
                <input type="text" className="form-control" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ViewClientLedger;
