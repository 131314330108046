// eslint-disable-next-line no-unused-vars
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import { BillwizerLogoLight, GoogleSignIn , AppleSignIn} from "../_components/Imagepath";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { emailrgx } from "../assets/constant";
import PropTypes from "prop-types";
import { useForm, Controller } from "react-hook-form";
import Captcha from "../services/Captcha";

import AuthService from "../services/auth.service";

import { useHistory } from "react-router-dom";

import { useGoogleLogin } from '@react-oauth/google';
import axios from 'axios';
import AppleLogin from 'react-apple-login';

const schema = yup
  .object({

    email: yup
      .string()
      .matches(emailrgx, "Email is required")
      .required("Email is required")
      .trim(),

    captcha: yup
      .string()
      .required("Captcha is required")
      .trim(),

  })
  .required();
// eslint-disable-next-line no-unused-vars
const generateCaptcha = () => {
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let captchaText = '';
  for (let i = 0; i < 6; i++) {
    captchaText += characters.charAt(Math.floor(Math.random() * characters.length));
  }
  return captchaText;
};

// eslint-disable-next-line no-unused-vars
const Register = (props) => {

  // eslint-disable-next-line no-unused-vars
  const [captchaText, setCaptchaText] = useState(generateCaptcha());

  // eslint-disable-next-line no-unused-vars
  const history = useHistory();


  Register.propTypes = {
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
    }).isRequired,
  };


  const inputValues = {
    email: "",
    captcha: "",
  };


  const {
    handleSubmit,
    control,
    // eslint-disable-next-line no-unused-vars
    setError,
    // eslint-disable-next-line no-unused-vars
    clearErrors,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: inputValues,
  });



  const validateForm = async (formData) => {
    try {
      await schema.validate(formData, { abortEarly: false });
      return true; // Validation passed
    } catch (error) {
      // Yup validation error handling
      return error.errors.join("\n");
    }
  };



  const onSubmit = async (data) => {
    console.log("data", data);


    const formData = {
      email: data.email,
      captcha: data.captcha, // Assuming inputCaptcha is the user input for CAPTCHA
    };
    // eslint-disable-next-line no-unused-vars
    const validationError = await validateForm(formData);


    if (data.captcha !== captchaText) {
      alert('CAPTCHA does not match');
      setCaptchaText(generateCaptcha());
      return; // Stop form submission if CAPTCHA does not match
    }

    if (validationError) {

      console.log("data rrrrr");
    }

    try {
      let res = await AuthService.register(data.email, data.email, data.email); // Assuming AuthService.login expects email and password as parameters

      setError("email", { message: res.errorMessage });
      if (res.errorMessage) {
        console.log("data rrrrr");

        setError("email", { message: res.errorMessage }); // Set error for the 'email' field
        return; // Exit early if there's an error
      }

      if (res.data.id) {
        console.log("data rrrrr");
        history.push("/step1form");
        // history.push("/otp-auth");
        window.location.reload();
      }


      console.log("data rrrrr");
      console.log(res.data.errorMessage)
      // history.push("/index");

      //props.history.push("index");
      // window.location.reload(); // Refresh the page (optional)
    } catch (error) {
      console.error('Login failed:', error);
      setError("sss");
      // Handle login failure (e.g., display error message)
      // Example: setMessage(error.message);
    }
  };






  const [user, setUser] = useState([]);
  const [profile, setProfile] = useState([]);

  const GoogleRegister = useGoogleLogin({
    onSuccess: codeResponse => {
      setUser(codeResponse);
      console.log(codeResponse);
    }
  });


  useEffect(
    () => {
      if (user) {


        axios
          .get(`https://www.googleapis.com/oauth2/v1/userinfo?access_token=${user.access_token}`, {
            headers: {
              Authorization: `Bearer ${user.access_token}`,
              Accept: 'application/json'
            }
          })
          .then((res) => {
            console.log(res);
            setProfile(res.data);
          })
          .catch((err) => console.log(err));
      }
    },
    [user]
  );



  useEffect(() => {
    const RegAndRedirect = async () => {
      if (profile.id) {
        console.log(profile);
        try {


          let res = await AuthService.APIRegister(profile.email, profile.email, profile.id, profile.name); // Assuming AuthService.login expects email and password as parameters

          console.log("data rrrrr");
          console.log(res.data.name);


          if (res.errorMessage) {
            console.log("data rrrrr");

            setError("apierror", { message: res.errorMessage }); // Set error for the 'email' field
            return; // Exit early if there's an error
          }

          if (res.data.id) {
            console.log("data rrrrr");
            history.push("/step1form");
            // history.push("/otp-auth");
            window.location.reload();
          }


        } catch (error) {
          console.error('Login failed:', error);
          // Handle login failure (e.g., display error message)
          // Example: setMessage(error.message);
        }
      }
    };

    RegAndRedirect();
  }, [profile]);


  // eslint-disable-next-line no-unused-vars
  const [appleresponse, setAppleResponse] = useState(null);


  const appleResponseReg = response => {

    console.log("data appleResponseReg");
    console.log(response.authorization);
    if (response.authorization) {
      setAppleResponse(response.authorization);
    }
   
  };

 
 
   useEffect(() => {
    
     const loginCheckResponse = async () => {
       if (appleresponse) {
         console.log(appleresponse);
         try {
 
           let res = await AuthService.APIAppleRespnseCheck(appleresponse.code, appleresponse.id_token); // Assuming AuthService.login expects email and password as parameters
 

           setProfile({email: res.email, name: "",id:""});
           console.log("data rrrrr");
           console.log(res);
          
      
         
         } catch (error) {
           console.error('Login failed 222:', error);
 
           const errorMessage = error.message || "An error occurred during login";
 
           setError("apierror", { message: errorMessage });
           // Handle login failure (e.g., display error message)
           // Example: setMessage(error.message);
         }
       }
     };
 
     loginCheckResponse();
   }, [appleresponse]);
 

  return (
    <div className="main-wrapper  login-body">
      <div className="login-wrapper">
        <div className="container">
          <img
            className="img-fluid logo-dark mb-2"
            src={BillwizerLogoLight}
            alt="Logo"
          />

          <div className="loginbox boardingwizard">
            <div className="login-right">
              <div className="login-right-wrap">
                <h1>Register</h1>
                     <form onSubmit={handleSubmit(onSubmit)}>

                  <div className="input-block mb-3">
                    <label className="form-control-label">Email Address</label>
                    <Controller
                      name="email"
                      control={control}
                      render={({ field: { value, onChange } }) => (
                        <input
                          className={`form-control  ${errors?.email ? "error-input" : ""
                            }`}
                          type="text"
                          value={value}
                          onChange={onChange}
                          autoComplete="false"
                        />
                      )}
                    />

                    <small className="error">{errors?.email?.message}</small>
                  </div>

                  <div className="input-block mb-3">
                    <label className="form-control-label">Captcha</label>

                    : <Captcha captchaText={captchaText} />
                    <Controller
                      name="captcha"
                      control={control}
                      render={({ field: { value, onChange } }) => (
                        <input
                          className={`form-control  ${errors?.email ? "error-input" : ""
                            }`}
                          type="text"
                          value={value}
                          onChange={onChange}
                          autoComplete="false"
                        />
                      )}
                    />

                    <small className="error">{errors?.captcha?.message}</small>
                  </div>

                  <div className="input-block mb-0">
                    <button className="btn btn-lg btn-block btn-primary w-100" type="submit">
                      Register
                    </button>
                  </div>
                </form>
                {/* /Form */}

                <div className="login-or">
                  <span className="or-line"></span>
                  <span className="span-or">or</span>
                </div>
                {/* Social Login */}



                <div className="social-login">

                  <div className="row">
                    <div className="col-12 col-sm col-md-6 col-lg-6">
                      <button className="custom-google-signin-button" onClick={() => GoogleRegister()}>
                      <img src={GoogleSignIn} alt="Google logo" className="google-logo" />
                       
                      </button>
                    </div>
                    <div className="col-12 col-sm col-md-6 col-lg-6">



                      <AppleLogin
                        clientId={"com.billwizer.webapp"}
                        scope={"email name"}
                        redirectURI={"https://app.billwizer.com/register"}
                        responseType={"code"}
                        responseMode={"query"}
                        usePopup={true}
                        callback={appleResponseReg}
                      
                        render={({ onClick }) => (
                          <button onClick={onClick} className="custom-google-signin-button">
                            <img
                              className="google-logo"
                              src={AppleSignIn}
                              alt="Sign in with Apple"
                            />
                          </button>
                        )}
                      />
                    </div>

                    <small className="error">{errors?.apierror?.message}</small>


                  </div>

                </div>
                {/* /Social Login */}
                <div className="text-center dont-have">
                  Already have an account? <Link to="/login">Login</Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Register;
