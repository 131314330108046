/* eslint-disable */
import React, { useState, useEffect } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import Header from "../layouts/Header";
import Sidebar from "../layouts/Sidebar";
import { avatar14 } from "../_components/Imagepath";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import Select from 'react-select';
import ClientService from '../services/client.service';
import staticmessages from '../services/staticmessages';

import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, Controller } from "react-hook-form";
import * as yup from "yup";
import { emailrgx } from "../assets/constant";

import alertify from "alertifyjs";
import "../../node_modules/alertifyjs/build/css/alertify.css";
import "../../node_modules/alertifyjs/build/css/themes/semantic.css";
import UserService from "../services/user.service";


const AddCustomer = () => {
  const [menu, setMenu] = useState();
  const [phoneNumber, setPhoneNumber] = useState("");
  const [selectedProduct, setSelectedProduct] = useState(null);

  const [countriesorg, setCountriesOrg] = useState([]);
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [isCountriesLoaded, setIsCountriesLoaded] = useState(false);

  const [valerror, setValError] = useState(null);

  const history = useHistory();
  const location = useLocation();

  const inputValues = {
    id: null,
    client_name: '',
    address: '',
    city: '',
    postcode: '',
    country: '',
    state: '',
    state_code: '',
    email: '',
    registration_number: '',
    contact_number: '',
    contact_person: '',
    mobile_number: '',
    fax_number: '',
    gst_number: '',
    pan_number: '',
    closing_balance: '',
    user_id: null,
    isSelected: false,
  };

  const [client, setClient] = useState(location.state?.client || inputValues);

  const [profile, setProfile] = useState({
    company_name: "",
    mobile: "",
    work_email: "",
    company_address_line_1: "",
    city: "",
    state: "",
    postcode: "",
    company_phone: "",
    company_business_number: "",
    company_pan: "",
    company_cin: "",
    country: "",
    symbol: "",
    currency: "",
    dateformat: "",
    company_website: "",
    review_link: "",
    company_business_label: ""
  });

  useEffect(() => {
    const fetchData = async () => {
      await fetchCountries();
      if (location.state?.client) {
        setClient(location.state.client);
        if (isCountriesLoaded) {
          fetchStates(location.state.client.country);
          CheckShowStateAlert(location.state.client.country);
        }
      }
    };
    fetchData();
    fetchProfile();
  }, [location.state, isCountriesLoaded]);



  const fetchProfile = async () => {
    UserService.getUserProfile()
      .then((response) => {

       
        const {
          company_name,
          mobile,
          work_email,
          company_address_line_1,
          city,
          state,
          postcode,
          company_phone,
          company_business_number,
          company_pan,
          company_cin,
          country,
          symbol,
          currency,
          dateformat,
          company_website,
          review_link,
          company_business_label,
        } = response.data;

        setProfile({
          company_name: company_name || '',
          mobile: mobile || '',
          work_email: work_email || '',
          company_address_line_1: company_address_line_1 || '',
          city: city || '',
          state: state || '',
          postcode: postcode || '',
          company_phone: company_phone || '',
          company_business_number: company_business_number || '',
          company_pan: company_pan || '',
          company_cin: company_cin || '',
          country: country || '',
          symbol: symbol || '',
          currency: currency || '',
          dateformat: dateformat || '',
          company_website: company_website || '',
          review_link: review_link || '',
          company_business_label: company_business_label || '',
        });
        setIsProfileLoaded(true);
      })
      .catch((error) => {
        console.error("UserService error:", error);
      });
  };

  const fetchCountries = async () => {
    try {
      const response = await ClientService.fetchCountries();
      setCountriesOrg(response.data);
     
      const responsecountry = response.data.map((item) => ({
        value: item.country_name,
        label: item.country_name,
      }));

      setCountries(responsecountry);

      setIsCountriesLoaded(true);
    } catch (error) {
      console.error('Error fetching countries:', error);
    }
  };

  const getCountryId = (countryName) => {
    const country = countriesorg.find((country) => country.country_name === countryName);
    return country ? country.id : null;
  };



  

  const fetchStates = async (country) => {
    try {
      const countryId = getCountryId(country);
      console.error(country);
      if (countryId != null && country != null) {
        const response = await UserService.fetchStates(countryId);
        const responsestate = response.data.map((item) => ({
          value: item.name,
          label: item.name,
        }));
        setStates(responsestate);
      } else {
       
      }
    } catch (error) {
      console.log('Error fetching states: ' + error.message);
    }
  };
  // const fetchStates = async (country) => {
  //   try {
  //     const countryId = getCountryId(country);
  //     console.error(countryId);

  //     if (countryId) {
 
  //       const responsestate = response.data.map((item) => ({
  //         value: item.name,
  //         label: item.name,
  //       }));
  //       setStates(responsestate);


  //     } else {
  //       console.error('Country not found');
  //     }
  //   } catch (error) {
  //     console.error('Error fetching states:', error);
  //   }
  // };

  const toastrError = (message) => {
    alertify.set("notifier", "position", "top-center");
    alertify.error(message);
  };


  const toastrSuccess = (message) => {
    alertify.set("notifier", "position", "top-center");
    alertify.success(message);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setClient({ ...client, [name]: value });

    if (name === 'country') {
      fetchStates(value);
      setClient({ ...client, state: '', state_code: '', [name]: value });
    }
  };

  const [showStateAlert, setShowStateAlert] = useState(false);


  
  const handleChangeCustomCountry = (selectedOption, name) => {

    setClient((prevClient) => ({
      ...prevClient,
      [name]: selectedOption?.value || "", // Ensure value is updated correctly
      state: '',
      state_code: ''
    })); 


    console.log(selectedOption);
    CheckShowStateAlert(selectedOption.value);
    fetchStates(selectedOption.value);
   
  };

  const handleChangeCustomState = (selectedOption, name) => {
   
   

    setClient((prevClient) => ({ ...prevClient, [name]: selectedOption.value }));
   
  };



  const CheckShowStateAlert = (value) => {
    if(value === 'India' || value === 'United States')
      {
        setShowStateAlert(true);
      }
      else{
        setShowStateAlert(false);
      }
  };



  const toggleMobileMenu = () => {
    setMenu(!menu);
  };

  const handleProductChange = (selectedOption) => {
    setSelectedCurrency(selectedOption);
  };


  const handleSaveAction = async (data) => {
    const action = client.id ? ClientService.updateClient : ClientService.createClient;
    try {
      await action(client.id, client);
      if(client.id)
        {
          toastrSuccess(staticmessages.CUSTOMER_USUCCESS)
        }
        else
        {
          toastrSuccess(staticmessages.CUSTOMER_CSUCCESS)
        }

      
      history.push("customers");
    } catch (error) {
      toastrError(error.message);
    }
  };

  const schema = yup.object({
    client_name: yup.string().required("Client Name is required"),
    city: yup.string().required("City is required"),
   
    country: yup.string().required("Country is required"),

    state: yup.string().test(
      'state-required',
      'State is required',
      function(value) {
        const { country } = this.parent;
        return (country === 'India' || country === 'United States') ? !!value : true;
      }
    ),
    
   // state: yup.string().required("State is required"),
    email: yup.string().email("Invalid email").notRequired(),

    registration_number: yup.string()
    .matches(/^[a-zA-Z0-9]*$/, 'Registration No. can only contain alphanumeric characters')
    .max(20, 'Registration No. cannot be more than 20 characters')
    .notRequired(),

    contact_number: yup.string()
    .matches(/^[0-9+\-_]*$/, 'Contact number can only contain numbers, +, -, and _')
    .notRequired(),
  });

  const {
    handleSubmit,
    control,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: client,
  });


  const handleBackAction =  () => {
   
      history.push("customers");
   
  };


  const forceMenuClose = () => {  
    setMenu(false);
  };

  
  return (
    <>
      <div className={`main-wrapper ${menu ? "slide-nav" : ""}`}>
        <Header onMenuClick={() => toggleMobileMenu()} forceMenuClose={forceMenuClose} />
        <Sidebar />
        <div className="page-wrapper">
          <div className="content container-fluid">
            <div className="card mb-0">
              <div className="card-body">
                <div className="page-header">
                  <div className="content-page-header">
                    <h5>{client.id ? "Edit " : "Add "}{staticmessages.CUSTOMER_LABEL_CLIENT}</h5>
                    {/* {JSON.stringify(client, null, 2)} */}
                  
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <form onSubmit={handleSubmit(handleSaveAction)}>
                      <div className="">
                        <div className="form-group-item">
                          <div className="row">
                            <div className="col-lg-4 col-md-6 col-sm-12">
                              <div className="input-block mb-3">
                                <label>Client Name<span className="alertstrik">*</span></label>
                                <Controller
                                  name="client_name"
                                  control={control}
                                  render={({ field }) => (
                                    <input
                                      {...field}
                                      className="form-control"
                                      placeholder="Enter Client Name"
                                      value={client.client_name}
                                      onChange={(e) => {
                                        handleChange(e);
                                        field.onChange(e);
                                      }}
                                    />
                                  )}
                                />
                                <small className="error">{errors?.client_name?.message}</small>
                              </div>
                            </div>

                            <div className="col-lg-4 col-md-6 col-sm-12">
                              <div className="input-block mb-3">
                                <label>Email</label>
                                <Controller
                                  name="email"
                                  control={control}
                                  render={({ field }) => (
                                    <input
                                      {...field}
                                      className="form-control"
                                      placeholder="Enter Email"
                                      value={client.email}
                                      onChange={(e) => {
                                        handleChange(e);
                                        field.onChange(e);
                                      }}
                                    />
                                  )}
                                />
                                <small className="error">{errors?.email?.message}</small>
                              </div>
                            </div>

                            <div className="col-lg-4 col-md-6 col-sm-12">
                              <div className="input-block mb-3">
                                <label>City<span className="alertstrik">*</span></label>
                                <Controller
                                  name="city"
                                  control={control}
                                  render={({ field }) => (
                                    <input
                                      {...field}
                                      className="form-control"
                                      placeholder="Enter City"
                                      value={client.city}
                                      onChange={(e) => {
                                        handleChange(e);
                                        field.onChange(e);
                                      }}
                                    />
                                  )}
                                />
                                <small className="error">{errors?.city?.message}</small>
                              </div>
                            </div>


                            <div className="col-lg-12 col-md-12 col-sm-12">
                              <div className="input-block mb-3">
                                <label>Address</label>
                                <Controller
                                  name="address"
                                  control={control}
                                  render={({ field }) => (
                                    <textarea
                                      {...field}
                                      className="form-control"
                                      placeholder="Enter Address"
                                         rows="2"
                                      value={client.address}
                                      onChange={(e) => {
                                        handleChange(e);
                                        field.onChange(e);
                                      }}
                                    />
                                  )}
                                />
                                <small className="error">{errors?.address?.message}</small>
                              </div>
                            </div>

                            <div className="col-lg-4 col-md-6 col-sm-12">
                              <div className="input-block mb-3">
                                <label>Zip Code</label>
                                <Controller
                                  name="postcode"
                                  control={control}
                                  render={({ field }) => (
                                    <input
                                      {...field}
                                      className="form-control"
                                       maxLength="10"
                                      placeholder="Enter Zip Code"
                                      value={client.postcode}
                                      onChange={(e) => {
                                        handleChange(e);
                                        field.onChange(e);
                                      }}
                                    />
                                  )}
                                />
                              </div>
                            </div>

                            <div className="col-lg-4 col-md-6 col-sm-12">
                              <div className="input-block mb-3">
                                <label>Country<span className="alertstrik">*</span></label>

                                {isCountriesLoaded && (
                       <Controller
                       name="country"
                       control={control}
                       render={({ field }) => (
                         <Select
                           {...field}
                           value={client.country ? { value: client.country, label: client.country } : null}
                           onChange={(selectedOption) => {
                             handleChangeCustomCountry(selectedOption, 'country');
                             field.onChange(selectedOption.value);  // Properly update react-hook-form's field value
                           }}
                           options={countries}
                           placeholder="Select Country"
                           className="fixzindex"
                         />
                       )}
                     />

                        )}
                                {/* <Controller
                                  name="country"
                                  control={control}
                                  render={({ field }) => (
                                    <select
                                      {...field}
                                      className="form-select"
                                      value={client.country}
                                      onChange={(e) => {
                                        handleChangeCountry(e);
                                        field.onChange(e);
                                      }}
                                    >
                                      <option value="">Select country</option>
                                      {countries.map((country) => (
                                        <option key={country.id} value={country.country_name}>{country.country_name}</option>
                                      ))}
                                    </select>
                                  )}
                                /> */}
                                <small className="error">{errors?.country?.message}</small>
                              </div>
                            </div>

                            <div className="col-lg-4 col-md-6 col-sm-12">
                              <div className="input-block mb-3">
                              <label>
                                State{showStateAlert ? <span className="alertstrik">*</span> : null}
                              </label>

                              {isCountriesLoaded && (

                                
<Controller
name="state"
control={control}
render={({ field }) => (
  <Select
    {...field}
    value={client.state ? { value: client.state, label: client.state } : null}
    onChange={(selectedOption) => {
      handleChangeCustomState(selectedOption, 'state');
      field.onChange(selectedOption.value); // Properly update react-hook-form's field value
    }}
    options={states}
    placeholder="Select State"
    className="fixzindex2"
  />
)}
/>
                        )}

                               
                                <small className="error">{errors?.state?.message}</small>
                              </div>
                            </div>

                            <div className="col-lg-4 col-md-6 col-sm-12">
                              <div className="input-block mb-3">
                              <label className="pe-2">{profile.company_business_label ? profile.company_business_label : staticmessages.SETTING_TAX_REG_LABEL}  </label>
                                <Controller
                                  name="registration_number"
                                  control={control}
                                  render={({ field }) => (
                                    <input
                                    {...field}
                                    className="form-control"
                                    maxLength="20"
                                    placeholder={`Enter ${profile.company_business_label ? profile.company_business_label : staticmessages.SETTING_TAX_REG_LABEL}`}
                                    value={client.registration_number}
                                    onChange={(e) => {
                                      handleChange(e);
                                      field.onChange(e);
                                    }}
                                  />
                                  )}
                                />
                                <small className="error">{errors?.registration_number?.message}</small>
                              </div>
                            </div>

                        

                            <div className="col-lg-4 col-md-6 col-sm-12">
                              <div className="input-block mb-3">
                                <label>Contact Number</label>
                                <Controller
                                  name="contact_number"
                                  control={control}
                                  render={({ field }) => (
                                    <input
                                      {...field}
                                      className="form-control"
                                      placeholder="Enter Contact Number"
                                      value={client.contact_number}
                                      maxLength="14"
                                      onChange={(e) => {
                                        handleChange(e);
                                        field.onChange(e);
                                      }}
                                    />
                                   
                                  )}
                                /> <small className="error">{errors?.contact_number?.message}</small>
                              </div>
                            </div>

                            <div className="col-lg-4 col-md-6 col-sm-12">
                              <div className="input-block mb-3">
                                <label>Contact Person</label>
                                <Controller
                                  name="contact_person"
                                  control={control}
                                  render={({ field }) => (
                                    <input
                                      {...field}
                                      className="form-control"
                                      placeholder="Enter Contact Person"
                                      value={client.contact_person}
                                      onChange={(e) => {
                                        handleChange(e);
                                        field.onChange(e);
                                      }}
                                    />
                                  )}
                                />
                              </div>
                            </div>


                            <div className="col-lg-4 col-md-6 col-sm-12">
                              <div className="input-block mb-3">
                                <label>Closing Balance</label>
                                <Controller
                                  name="closing_balance"
                                  control={control}
                                  render={({ field }) => (
                                    <input
                                      {...field}
                                      className="form-control"
                                      placeholder="Enter Closing Balance"
                                      value={client.closing_balance}
                                      onChange={(e) => {
                                        handleChange(e);
                                        field.onChange(e);
                                      }}
                                    />
                                  )}
                                />
                              </div>
                            </div>


                          </div>
                        </div>

                        <div className="add-customer-btns text-end">
                          <button onClick={handleBackAction} className="btn  btn-primary  me-2">
                            Cancel
                          </button>
                          <button type="submit" className="btn  btn-primary">
                            {client.id ? 'Update Changes' : 'Save Changes'}
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddCustomer;
