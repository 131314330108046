import React, { useState ,useEffect } from "react";
import Header from "../layouts/Header";

// eslint-disable-next-line no-unused-vars
import Sidebar from "../layouts/Sidebar";
  // eslint-disable-next-line no-unused-vars
import ReportModal from "../common/modal/reportModal";
// eslint-disable-next-line no-unused-vars
import BreadcrumbsReport from "../common/breadcrumb/breadCrumbsReport";
import { Table } from "antd";

  // eslint-disable-next-line no-unused-vars
import { Link , useHistory} from "react-router-dom";

// eslint-disable-next-line no-unused-vars
import {formatDateISO, getCurrentDateISO, getExtendDateISO} from '../services/Helper';



  // eslint-disable-next-line no-unused-vars
import report from '../services/reports.service';
  // eslint-disable-next-line no-unused-vars
import staticmessages from '../services/staticmessages';

  // eslint-disable-next-line no-unused-vars
  import Spinners from '../components/spinners';

const ClientLedeger = () => {

  // eslint-disable-next-line no-unused-vars
const [loading, setLoading] = useState(true); // Loading state

  const history = useHistory();
  const [menu, setMenu] = useState(false);
  const [show, setShow] = useState(false);

  const [page, setPage] = useState(1);

const paginationSize = 10;

    // eslint-disable-next-line no-unused-vars
  const [datasource, setInvoices] = useState([]);
    // eslint-disable-next-line no-unused-vars
  const [filteredDataSource, setFilteredDataSource] = useState([]);

    // eslint-disable-next-line no-unused-vars
  const [listType, setListType] = useState("invoicelist");

  //       <ReportModal setShow={setShow} show={show} customtitle={customtitle} listType = {listType} fdate = {fdate} tdate = {tdate} />

    // eslint-disable-next-line no-unused-vars

    let currentdate =   new Date();
    let currentdateex =  new Date(currentdate.getTime() - (30 * 24 * 60 * 60 * 1000));

  const [filteroption, setFilterOptions] = useState({searchterm: "",fdate: currentdateex,tdate:currentdate});


  const toggleMobileMenu = () => {
    setMenu(!menu);
  };



  useEffect(() => {
    
      getClientLedeger();
   
  }, [filteroption]);

  const getClientLedeger = async () => {

   

    report.getClientLedegerReport(listType, filteroption)
      .then((response) => {
        // setInvoices(response.data);
        // setFilteredDataSource(response.data);

        console.log(response);

        setInvoices(response.data);
        setFilteredDataSource(response.data);
        setLoading(false);

      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  const handleApplyFilter = (searchTerm) => {
    setShow(false);
    setSearchTerm(searchTerm);
  
    setFilterOptions((prev) => ({
      ...prev,
      searchterm: searchTerm
    }));

    getClientLedeger(); // Reload parent data
  };

  const handleApplyResetApply = () => {

    setFilterOptions((prev) => ({
      ...prev,
      searchterm: "",fdate: currentdate,tdate:currentdateex
    }));

   
    setShow(false);
    getClientLedeger(); // Reload parent data
  };

    const handleViewLedger = (customer) => {
    console.log("handleParentViewLedger 2222");
    console.log(customer);

    history.push(`/report-ledger`, { customer });
  };


  const handleTableChange = (pagination) => {
    setPage(pagination.current);
  };

  const columns = [
    {
      title: "#",
      dataIndex: "id",
      render: (text, record, index) => (page - 1) * paginationSize + index + 1,
    },
    {
      title: "Client",
      dataIndex: "client_name",
      render: (text, record) => (
        <h2 className="table-avatar">
          <Link to="#">
            {record.client_name} <br /> <span>{record.email}</span>
          </Link>
        </h2>
      ),
      sorter: (a, b) => a.client_name.length - b.client_name.length,
    },
   
  
    {
      title: "Action",
      dataIndex: "datetimestamp",
      render: (text, record) => (
        <>
         <button  className="btn btn-greys btn-blue me-2"  onClick={() => handleViewLedger(record)}>
            <i className="fa-regular fa-eye me-1" /> Ledger
          </button>
            
        </> ),
  
    },
  ];
  // eslint-disable-next-line no-unused-vars
  const [searchTerm, setSearchTerm] = useState(""); 


  const forceMenuClose = () => {  
    setMenu(false);
  };
  

  if (loading) {
    return <Spinners />; // Display the spinner while loading
  }

  return (
    <>
      <div className={`main-wrapper ${menu ? "slide-nav" : ""}`}>
      <Header onMenuClick={() => toggleMobileMenu()} forceMenuClose={forceMenuClose}  onApplyFilter={handleApplyFilter} searchTerm = {searchTerm} />
        <Sidebar />
        <div className="page-wrapper">
          <div className="content container-fluid">
        
            <BreadcrumbsReport
              title={staticmessages.REPORT_CLIENTLEDEGER}
              show={show}
              setShow={setShow}
              isShowD = {false}
            />
            <ReportModal setShow={setShow} show={show}   filteroption = {filteroption} setFilterOptions ={setFilterOptions} 
            handleApplyFilter = {handleApplyFilter} handleApplyResetApply = {handleApplyResetApply}  />
            <div className="row">
              <div className="col-sm-12">
                <div className="card invoiceList">
                  <div className="card-body invoiceList">
                    <div className="table-responsive table-hover">
                    <Table
                      className="table"
                      pagination={{
                        total: filteredDataSource.length,
                        showTotal: (total, range) =>
                          `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                        showSizeChanger: false,
                      
                      }}
                      columns={columns}
                      dataSource={filteredDataSource}
                      rowKey={(record) => record.id}
                      onChange={handleTableChange}
                    />
                     
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ClientLedeger;
