import React from "react";

import { InvoiceLogo1 } from "../_components/Imagepath";

import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { emailrgx } from "../assets/constant";
import PropTypes from "prop-types";
import { useForm, Controller } from "react-hook-form";


import AuthService from "../services/auth.service";

import { useHistory} from "react-router-dom";

const schema = yup
  .object({

    email: yup
      .string()
      .matches(emailrgx, "Email is required")
      .required("Email is required")
      .trim(),
   
  })
  .required();

            // eslint-disable-next-line no-unused-vars
const OTPAuth = (props) => {

                // eslint-disable-next-line no-unused-vars
  const history = useHistory();


  OTPAuth.propTypes = {
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
    }).isRequired,
  };


  const inputValues = {
    email: "",
  };


  const {
    handleSubmit,
    control,
          // eslint-disable-next-line no-unused-vars
    setError,
              // eslint-disable-next-line no-unused-vars
    clearErrors,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: inputValues,
  });




  const onSubmit = async (data) => {
    console.log("data", data);
    try {
      let res =  await AuthService.register(data.email, data.email, data.email); // Assuming AuthService.login expects email and password as parameters

      if (res.data.errorMessage) {
        setError("email", { message: res.data.errorMessage }); // Set error for the 'email' field
        return; // Exit early if there's an error
      }

      if (res.data.id) {
        console.log("data rrrrr");
        history.push("/index");
        window.location.reload();
      }
     

      console.log("data rrrrr");
      console.log(res.data.errorMessage)
     // history.push("/index");
     
      //props.history.push("index");
     // window.location.reload(); // Refresh the page (optional)
    } catch (error) {
      console.error('Login failed:', error);
      setError("sss");
      // Handle login failure (e.g., display error message)
      // Example: setMessage(error.message);
    }
  };

  return (
    <div className="main-wrapper  login-body">
      <div className="login-wrapper">
        <div className="container">
          <img
            className="img-fluid logo-dark mb-2"
            src={InvoiceLogo1}
            alt="Logo"
          />

          <div className="loginbox boardingwizard">
            <div className="login-right">
              <div className="login-right-wrap">
                <h1>OTP</h1>
                <p className="account-subtitle">Access to our dashboard</p>

                {/* Form */}
                <form onSubmit={handleSubmit(onSubmit)}>
               
                  <div className="input-block mb-3">
                    <label className="form-control-label">Email Address</label>
                    <Controller
                          name="email"
                          control={control}
                          render={({ field: { value, onChange } }) => (
                            <input
                              className={`form-control  ${
                                errors?.email ? "error-input" : ""
                              }`}
                              type="text"
                              value={value}
                              onChange={onChange}
                              autoComplete="false"
                            />
                          )}
                        />

                        <small className="error">{errors?.email?.message}</small>
                  </div>
                 
                  <div className="input-block mb-0">
                    <button className="btn btn-lg btn-block btn-primary w-100"  type="submit">
                      Submit OTP
                    </button>
                  </div>
                </form>
                {/* /Form */}

               
              
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default OTPAuth;
