import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Header from "../../layouts/Header";
import Sidebar from "../../layouts/Sidebar";


import "../../_components/antd.css";

import ItemService from '../../services/item.service';
import staticmessages from '../../services/staticmessages';


import { Table, Tooltip } from "antd";

import AddVendor from "../../customers/vendors/addVendor";
import {  filterIcon } from "../../_components/Imagepath";
   // eslint-disable-next-line no-unused-vars
import alertify from "alertifyjs";
import "../../../node_modules/alertifyjs/build/css/alertify.css";
import "../../../node_modules/alertifyjs/build/css/themes/semantic.css";

const Category = () => {
  const [menu, setMenu] = useState(false);
  const [show, setShow] = useState(false);

    // eslint-disable-next-line no-unused-vars
  const [page, setPage] = useState(1);
    // eslint-disable-next-line no-unused-vars
  const paginationSize = 10;

  const toggleMobileMenu = () => {
    setMenu(!menu);
  };


  // eslint-disable-next-line no-unused-vars
  const [categories, setCategories] = useState([]);

   // eslint-disable-next-line no-unused-vars
  const [selectCategory, setSelectCategory] = useState({
    id:0,
    name: "",
  });

  // eslint-disable-next-line no-unused-vars
  const [filteredDataSource, setFilteredDataSource] = useState([]);

  // eslint-disable-next-line no-unused-vars
  const [searchTerm, setSearchTerm] = useState("");
  const handleApplyFilter = (searchTerm) => {
    console.log(searchTerm);
    setSearchTerm(searchTerm);
    filterData(searchTerm);
  };
  const filterData = (searchTerm) => {
    
    const filteredData = categories.filter((record) =>
      record.name.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredDataSource(filteredData);
  };

  useEffect(() => {

    fetchCategoriesMian();
  }, []);
  // eslint-disable-next-line no-unused-vars
  const fetchCategoriesMian = () => {
    ItemService.fetchCategories()
    .then((response) => {
      console.log("ItemService response:", response.data);
      setCategories(response.data);
      setFilteredDataSource(response.data);
     
    })
    .catch((error) => {
      console.error("UserService error:", error);
    });
  }

    // eslint-disable-next-line no-unused-vars
  const editCategory = (category) => {
    console.error(category);
    setSelectCategory(category);
  }
  const addCategory = () => {
    setSelectCategory({
      id:"",
      name: "",
    });
  }

  const toastrError = (message) => {
    alertify.set("notifier", "position", "top-center");
    alertify.error(message);
  };


  const toastrSuccess = (message) => {
    alertify.set("notifier", "position", "top-center");
    alertify.success(message);
  };

  const handleSaveAction = async (item) => {

    console.error(item);
    console.error(selectCategory);

    const action = ItemService.addeditCategory;
    try {
      await action(selectCategory);
      if(item.id)
        {
          toastrSuccess(staticmessages.CUSTOMER_USUCCESS)
        }
        else
        {
          toastrSuccess(staticmessages.CUSTOMER_CSUCCESS)
        }

        fetchCategoriesMian();

    } catch (error) {
      toastrError(error.message);
    }
  };

     // eslint-disable-next-line no-unused-vars
  const handleDelete = (id) => {

    console.log(id);

    if (id) {
      ItemService.deleteCategory(id)
        .then(() => {
         // fetchCategoriesMian();
        })
        .catch((error) => {
          console.error('Login failed:', error);
        });
    }
  };
 

  const handleChange = (e) => {
    const { name, value } = e.target;
    setSelectCategory({ ...selectCategory, [name]: value });

  };


  const handleTableChange = (pagination) => {
    setPage(pagination.current);
  };

  const columns = [
    {
      title: "#",
      dataIndex: "id",
     //render: (text, record, index) => index + 1,
    render: (text, record, index) => (page - 1) * paginationSize + index + 1,
      sorter: (a, b) => a.id.length - b.id.length,
    },



    // {
    //   title: "#",
    //   dataIndex: "id",
    //   render: (img, record) => (
    //     <>
        
    //         <span>{record.id}</span>
         
    //     </>
    //   ),
    //   sorter: (a, b) => a.id.length - b.id.length,
    // },


    {
      title: "Category Name",
      dataIndex: "Category",
      render: (img, record) => (
        <>
        
            <span>{record.name}</span>
         
        </>
      ),
      sorter: (a, b) => a.name.length - b.name.length,
    },
   
    // {
    //   title: "Action",
    //   dataIndex: "Action",
    //   render: (img, record) => (
    //     <div className="d-flex align-items-center">
    //       <button
    //        onClick={() => editCategory(record)}
        
    //         className=" btn-action-icon me-2"
    //         to="#"
    //         data-bs-toggle="modal"
    //         data-bs-target="#edit_category"
    //       >
    //         <i className="fe fe-edit"></i>
    //       </button>
    //       <button
    //        onClick={() => handleDelete(record.id)}
    //         className=" btn-action-icon"
    //         to="#"
    //         data-bs-toggle="modal"
    //         data-bs-target="#delete_modal"
    //       >
    //         <i className="fe fe-trash-2"></i>
    //       </button>
    //     </div>
    //   ),
    //   sorter: (a, b) => a.Action.length - b.Action.length,
    // },
  ];


  const forceMenuClose = () => {  
    setMenu(false);
  };

  return (
    <>
      <div className={`main-wrapper ${menu ? "slide-nav" : ""}`}>
      <Header onMenuClick={() => toggleMobileMenu()} forceMenuClose={forceMenuClose}    onApplyFilter={handleApplyFilter} searchTerm = {searchTerm} />
        <Sidebar />

        <div className="page-wrapper">
          <div className="content container-fluid">
            {/* Page Header */}
            <div className="page-header">
              <div className="content-page-header ">
                <h5>{staticmessages.CATEGORY_LABEL_TITLE} </h5>
                <div className="list-btn">
                  <ul className="filter-list">
                    <li>
                      <Link
                        to="#"
                        className="btn btn-filters w-auto popup-toggle me-2"
                        onClick={() => setShow(!show)}
                      >
                        <span className="me-2">
                           <Tooltip title="Filter">
                            <img src={filterIcon} alt="filter" />
                          </Tooltip>
                        </span>
                        Filter
                      </Link>
                    </li>

                   
                    <li>
                    
                      <button onClick={addCategory}
                        className="btn btn-primary"
                        to="#"
                        data-bs-toggle="modal"
                        data-bs-target="#add_category"
                      >
                        <i
                          className="fa fa-plus-circle me-2"
                          aria-hidden="true"
                        />
                        Add Category
                      </button>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            {/* /Page Header */}
            {/* Search Filter */}
            <div id="filter_inputs" className="card filter-card">
              <div className="card-body pb-0">
                <div className="row">
                  <div className="col-sm-6 col-md-3">
                    <div className="input-block mb-3">
                      <label>Name</label>
                      <input type="text" className="form-control" />
                    </div>
                  </div>
                  <div className="col-sm-6 col-md-3">
                    <div className="input-block mb-3">
                      <label>Email</label>
                      <input type="text" className="form-control" />
                    </div>
                  </div>
                  <div className="col-sm-6 col-md-3">
                    <div className="input-block mb-3">
                      <label>Phone</label>
                      <input type="text" className="form-control" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* /Search Filter */}
            {/* All Invoice */}
            <div className="card invoices-tabs-card">
              <div className="invoices-main-tabs">
                <div className="row align-items-center">
                  <div className="col-lg-12">
                    <div className="invoices-tabs">
                      <ul>
                        <li>
                          <Link to="/product-list">Product</Link>
                        </li>
                        <li>
                          <Link to="/category" className="active">
                            Category
                          </Link>
                        </li>
                        <li>
                          <Link to="/units">Units</Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* /All Invoice */}
            {/* Table */}
            <div className="row">
              <div className="col-sm-12">
                <div className=" card-table">
                  <div className="card-body category">
                    <div className="table-responsive table-hover">
                      
                      <Table
                       
                        pagination={{
                          total: filteredDataSource.length,
                          showTotal: (total, range) =>
                            `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                          showSizeChanger: true,
                         
                          
                        }}
                        columns={columns}
                        dataSource={filteredDataSource}
                        rowKey={(record) => record.Id}
                        onChange={handleTableChange}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* /Table */}
          </div>
        </div>

       
        <AddVendor setShow={setShow} show={show} onApplyFilter={handleApplyFilter}  title="Category"  />

        <div
          className="modal custom-modal fade"
          id="add_category"
          role="dialog"
           >
          <div className="modal-dialog modal-dialog-centered modal-md">
            <div className="modal-content">
              <div className="modal-header border-0 pb-0">
                <div className="form-header modal-header-title text-start mb-0">
                  <h4 className="mb-0">Add Category</h4>
                </div>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body">
                <div className="row">
                  <div className="col-md-12">
                    <div className="card-body">
                      <div className="form-group-item border-0 pb-0 mb-0">
                        <div className="row">
                          <div className="col-lg-12 col-sm-12">
                            <div className="input-block mb-3">
                              <label>
                                Name<span className="alertstrik">*</span>
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Enter Title"
                                name="name"
                                value={selectCategory.name}

                                onChange={(e) => {
                                  handleChange(e);
                                 
                                }}
                               
                              />
                            </div>
                          </div>
                    
                         
                    
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <Link
                  to="#"
                  data-bs-dismiss="modal"
                  className="btn btn-primary  me-2"
                >
                  Cancel
                </Link>
                <button onClick={handleSaveAction}
                  to="#"
                  data-bs-dismiss="modal"
                  className="btn btn-primary paid-continue-btn"
                  
                >
                  Add Category
                </button>
              </div>
            </div>
          </div>
        </div>

        <div
          className="modal custom-modal fade"
          id="edit_category"
          role="dialog"
        >
          <div className="modal-dialog modal-dialog-centered modal-md">
            <div className="modal-content">
              <div className="modal-header border-0 pb-0">
                <div className="form-header modal-header-title text-start mb-0">
                  <h4 className="mb-0">Edit Category</h4>
                </div>
                <button


                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body">
                <div className="row">
                  <div className="col-md-12">
                    <div className="card-body">
                      <div className="form-group-item border-0 pb-0 mb-0">
                        <div className="row">
                          <div className="col-lg-12 col-sm-12">
                            <div className="input-block mb-3">
                              <label>
                                Name<span className="alertstrik">*</span>
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Enter Title"
                                value={selectCategory.name}
                              />
                            </div>
                          </div>
                         
                     
                          
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <Link
                  to="#"
                  data-bs-dismiss="modal"
                  className="btn btn-primary  me-2"
                >
                  Cancel
                </Link>
                <Link
                  to="#"
                  data-bs-dismiss="modal"
                  className="btn btn-primary paid-continue-btn"
                >
                  Update
                </Link>
              </div>
            </div>
          </div>
        </div>

        <div
          className="modal custom-modal fade"
          id="delete_modal"
          role="dialog"
        >
          <div className="modal-dialog modal-dialog-centered modal-md">
            <div className="modal-content">
              <div className="modal-body">
                <div className="form-header">
                  <h3>Delete Category</h3>
                  <p>Are you sure you want to delete?</p>
                </div>
                <div className="modal-btn delete-action">
                  <div className="row">
                    <div className="col-6">
                      <button
                        type="reset"
                        data-bs-dismiss="modal"
                        className="w-100 btn btn-primary paid-continue-btn"
                      >
                        Delete
                      </button>
                    </div>
                    <div className="col-6">
                      <button
                        type="submit"
                        data-bs-dismiss="modal"
                        className="w-100 btn btn-primary "
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Category;
