const staticMessage = {
  APP_NAME : "BillWizer",


ITEMUSERSELECT_DATALOST : "Are you Sure to Exit? Your changes will be lost.",
ITEMUSERSELECT_ITEMADDEDCART : "Your item has been added in Invoice",

  SETTING_step1Data : "Business Info",
  SETTING_step2Data : "Tax Setting",
  SETTING_step3Data : "Upload Logo",
  SETTING_step4Data : "Business Info",
  SETTING_step5Data : "Sample Client",
  SETTING_step6Data : "Sample Item",

  INVOICE_CLIENTLEDGER : "Client Ledger",
  INVOICE_PROFORM_CSUCCESS: "Proforma Invoice created successfully.",

  REPORT_INVSUMMARY : "Invoice Summary",
  REPORT_INVDETAIL : "Invoice Detail",
  REPORT_BALANCES : "Clients' Payment Balances",
  REPORT_PAYMENTRECIVED : "Manage Payment",
  REPORT_TSIXAS : "26 AS",
  REPORT_CLIENTLEDEGER : "Client Ledger",
  REPORT_TAXREGISTER : "Tax Register Summary",
  REPORT_TAXREGISTERDETAIL : "Tax Register Detail",
  REPORT_EXPIRINGSERVICES : "Expiring Services",



     DEFINDIANDECIMALPLACE : "India",


    CUSTOMER_LABEL_TITLE: "Manage Client",
    CUSTOMER_LABEL_CLIENT: "Client",
    
    INVOICE_LABEL_TITLE: "Manage Invoice",
    ESTIMATE_LABEL_TITLE: "Manage Estimate",

    ITEMS_LABEL_TITLE: "Manage Item",
    ITEMS_LABEL_ITEM: "Items",

    ITEMS_LABEL_ADDITEM: "Add Item",
    ITEMS_LABEL_SINGLEITEM: "Item",

    UNIT_LABEL_TITLE: "Units",
    CATEGORY_LABEL_TITLE: "Category",

    PAYMENT_LIST_TITLE: "Payment",
    INVOICE_LIST_TITLE: "Invoice",
    ESTIMATE_LIST_TITLE: "Estimate",

    DEF_USUCCESS: " updated successfully.",
    DEF_CSUCCESS: " created successfully.",



    PAYMENT_USUCCESS: " updated successfully.",
    PAYMENT_CSUCCESS: " added successfully.",


    INVOICE_DELSUCCESS: "deleted successfully.",


    CUSTOMER_USUCCESS: "Client updated successfully.",
    CUSTOMER_CSUCCESS: "Client created successfully.",
    CUSTOMER_DELSUCCESS: "Item deleted successfully.",

    ITEM_USUCCESS: "Item updated successfully.",
    ITEM_CSUCCESS: "Item created successfully.",
    ITEM_DELSUCCESS: "Item deleted successfully.",

    TAXL_USUCCESS: "Tax Label updated successfully.",

    SETTING_PROFILE: "Business Profile",
    SETTING_INVESTSER: "Invoice & Estimate Series",
    SETTING_TAX: "Tax Setting",
    SETTING_TEMPLATE: "Print Template",
    SETTING_PAYMENT: "Payment Setting",
    SETTING_BILLING: "Plan & Billing",
    SETTING_UPLOADLOGO: "Upload Logo",
    SETTING_UPLOADLOGOD: "Uploaded Logo",
    SETTING_UPLOADSIGN: "Upload Signature",
    SETTING_UPLOADSIGND: "Uploaded Signature",

    SETTING_TAX_REG_LABEL: "Tax Registration",

    SETTING_TERMCOND: "Terms & Conditions",
    SETTING_PAYMENTSUCCESS: "Payment Setting updated successfully.",

    SETTING_BPSUCCESS: "Business Profile updated successfully.",
    SETTING_ESSUCCESS: "Invoice/Estimate Series updated successfully.",
    SETTING_ERROR: "Unable to update.",

    SETTING_TAXSUCCESS: "Tax Setting updated successfully.",

    
    SETTING_PRINTTEMPSUCCESS: "Print Template updated successfully.",

    SETTING_NOTESSUCCESS: "Terms & Conditions updated successfully.",

    TAX_SETTING : [
      { label: 'No Tax', value: 'none' },
      { label: 'On Total', value: 'ontotal' },
      { label: 'Per Item', value: 'peritem' }
    ],


    // eslint-disable-next-line no-unused-vars
   PAYMENT_SETTING : [
    { value: "", label: "Select Payment Method" },
    { value: "Cash", label: "Cash" },
    { value: "Bank", label: "Bank" },
    { value: "TDS", label: "TDS" },
  ],


   // eslint-disable-next-line no-unused-vars
   PAYMENT_SETTINGTDS : [
 
    { value: "TDS", label: "TDS" },
  ],

  TEMPLATE_INV : [
    { label: 'Template 1', value: 'Template1' },
    { label: 'Template 2', value: 'Template2' },
    { label: 'Template 3', value: 'Template3' },
    { label: 'Template 4', value: 'Template4' },
   
  
  ],
  TEMPLATE_EST : [
    { label: 'Template 1', value: 'ETemplate1' },
    { label: 'Template 2', value: 'ETemplate2' },
    { label: 'Template 3', value: 'ETemplate3' },
    { label: 'Template 4', value: 'ETemplate4' },
   
   
  
  ],


  };
  
  export default staticMessage;