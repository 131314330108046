import React, { useState, useEffect } from 'react';
import Header from '../../layouts/Header';
import Sidebar from '../../layouts/Sidebar';
import Select from 'react-select';
import * as yup from 'yup'; // Import Yup

import staticmessages from '../../services/staticmessages';
import ItemService from '../../services/item.service';
import { useHistory, useLocation } from 'react-router-dom';

import alertify from "alertifyjs";
import "../../../node_modules/alertifyjs/build/css/alertify.css";
import "../../../node_modules/alertifyjs/build/css/themes/semantic.css";

const AddProduct = () => {
  const location = useLocation();
  const history = useHistory();
   // eslint-disable-next-line no-unused-vars
  const [error, setError] = useState(null);
  const [categories, setCategories] = useState([]);
  const [units, setUnits] = useState([]);
  const [errors, setErrors] = useState({}); // State to hold validation errors
  const inputValues = {
    id: null,
    item_name: '',
    hsn_sac: '',
    category: '',
    unit: '',
    taxrate: '',
    price: '',
  };

  const [item, setItem] = useState(location.state?.item || inputValues);

  useEffect(() => {
    fetchCategories();
    fetchUnits();

    if (location.state?.item) {
      setItem(location.state.item);


      setItem(prevInvoice => ({
        ...prevInvoice,
        taxrate: item.taxrate ? item.taxrate : '',
       
      }));


    } else {
      // Handle case for adding new item if needed
    }
  }, [location.state]);

  const fetchCategories = () => {
    ItemService.fetchCategories()
      .then((response) => {
        const options2 = response.data.map((item) => ({
          value: item.name,
          label: item.name,
        }));
        console.error(options2);
        setCategories(options2);
      })
      .catch((error) => {
        console.error('Error fetching categories:', error);
      });
  };

  const fetchUnits = () => {
    ItemService.fetchUnits()
      .then((response) => {
        const tempunits = response.data.map((item) => ({
          value: item.name,
          label: item.name,
        }));

        console.error(tempunits);
        setUnits(tempunits);
      })
      .catch((error) => {
        console.error('Error fetching units:', error);
      });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setItem({ ...item, [name]: value });
  };

  const handleChangeDecimalVal = (e) => {
    const { name, value } = e.target;

    // Allow numbers and decimal points only
  const regex = /^\d*\.?\d*$/;

  if (value === "" || regex.test(value)) {
    // Update the item state only if the input is valid
    setItem((prevItem) => ({
      ...prevItem,
      [name]: value,
    }));
  }
    
   // setItem({ ...item, [name]: value });
  };


  const handleChangeCustom = (value, name) => {
    setItem({ ...item, [name]: value.value });
  };


  const toastrError = (message) => {
    alertify.set("notifier", "position", "top-center");
    alertify.error(message);
  };


  const toastrSuccess = (message) => {
    alertify.set("notifier", "position", "top-center");
    alertify.success(message);
  };

  const handleUpdate = () => {
    schema.validate(item, { abortEarly: false })
      .then(() => {
        ItemService.updateItem(item.id, item)
          .then(() => {

            if(item.id)
              {
                toastrSuccess(staticmessages.ITEM_USUCCESS)
              }
              else
              {
                toastrSuccess(staticmessages.ITEM_CSUCCESS)
              }

            history.push('product-list');
          })
          .catch((error) => {
            setError(error.message);
            toastrError(error.message);
          });
      })
      .catch((validationErrors) => {
        // Yup validation errors
        const errorsObj = {};
        validationErrors.inner.forEach((error) => {
          errorsObj[error.path] = error.message;
        });
        setErrors(errorsObj);
      });
  };

  const handleBackAction = () => {
    
    history.push('product-list');
  };

  const toggleMobileMenu = () => {
    setMenu(!menu);
  };

  const schema = yup.object().shape({
    category: yup.string().required('Category is required'),
    item_name: yup.string().required('Item name is required'),

    price: yup.string()
    .matches(/^\d+(\.\d+)?$/, 'Price can only contain numeric characters and an optional decimal point')
    .required('Price is required')
    .test('is-positive', 'Price must be greater than 0', value => {
      const numberValue = parseFloat(value);
      return !isNaN(numberValue) && numberValue > 0;
    }),
    
  

    taxrate: yup
    .string()
    .matches(/^(?:\d*\.?\d+)?$/, 'Tax Rate can only contain numeric characters and an optional decimal point')
    .nullable()
    .test('is-empty-or-valid-number', 'Tax Rate must be less than or equal to 100', function(value) {
      if (value === '' || value === undefined || value === null) {
        return true;
      }
      const numberValue = parseFloat(value);
      return !isNaN(numberValue) && numberValue <= 100;
    })
  
  
    
  });

  const [menu, setMenu] = useState(false);

  const forceMenuClose = () => {  
    setMenu(false);
  };


  return (
    <>
      <div className={`main-wrapper ${menu ? 'slide-nav' : ''}`}>
        <Header onMenuClick={() => toggleMobileMenu()} forceMenuClose={forceMenuClose} />
        <Sidebar />
        <div className="page-wrapper">
          <div className="content container-fluid">
            <div className="card mb-0">
              <div className="card-body">
                <div className="page-header">
                  <div className="content-page-header">
                    <h5>{staticmessages.ITEMS_LABEL_ADDITEM}</h5>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <div className="form-group-item">
                      <h5 className="form-title">Basic Details</h5>
                      <div className="row">
                        <div className="col-lg-4 col-md-6 col-sm-12">
                          <div className="input-block mb-3">
                            <label>Category<span className="alertstrik">*</span></label>
                            
                            <Select
                              defaultValue={item.category ? { value: item.category, label: item.category } : item.category}
                              id="category"
                              name="category"
                              onChange={(selectedOption) => handleChangeCustom(selectedOption, 'category')}
                              options={categories}
                              placeholder="Select Category"
                            />
                            <small className="error">{errors?.category}</small>
                          </div>
                        </div>

                        <div className="col-lg-4 col-md-6 col-sm-12">
                          <div className="input-block mb-3">
                            <label>Units</label>
                            <Select
                              id="unit"
                              name="unit"
                              defaultValue={item.unit ? { value: item.unit, label: item.unit } : item.unit}
                              onChange={(selectedOption) => handleChangeCustom(selectedOption, 'unit')}
                              options={units}
                              placeholder="Select Units"
                            />
                            <small className="error">{errors?.unit}</small>
                          </div>
                        </div>

                        <div className="col-lg-4 col-md-6 col-sm-12">
                          <div className="input-block mb-3">
                            <label>
                              {staticmessages.ITEMS_LABEL_SINGLEITEM} Name<span className="alertstrik">*</span>
                               (Description)
                            </label>
                            <input
                              onChange={handleChange}
                              id="item_name"
                              name="item_name"
                              value={item.item_name}
                              type="text"
                              className="form-control"
                              placeholder={`Enter ${staticmessages.ITEMS_LABEL_SINGLEITEM} Name`}
                            />
                            <small className="error">{errors?.item_name}</small>
                          </div>
                        </div>

                        <div className="col-lg-4 col-md-6 col-sm-12">
                          <div className="input-block mb-3">
                            <label>
                              Price{' '}
                              <span className="alertstrik">*</span>
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Enter Selling Price"
                              onChange={handleChangeDecimalVal}
                              id="price"
                              name="price"
                              maxLength={10}
                              value={item.price}
                              onClick={(e) => e.target.select()}
                            />
                            <small className="error">{errors?.price}</small>
                          </div>
                        </div>

                        <div className="col-lg-4 col-md-6 col-sm-12">
                          <div className="input-block mb-3">
                            <label>
                              Tax Rate{' '}
                            </label>
                            <input
                              type="number"
                              className="form-control"
                              placeholder="Enter Tax Rate"
                              onChange={handleChange}
                              id="taxrate"
                              name="taxrate"
                              maxLength={5}
                              value={item.taxrate}
                              onClick={(e) => e.target.select()}
                            />
                               <small className="error">{errors?.taxrate}</small>
                            {/* Optionally display validation errors for taxrate */}
                          </div>
                        </div>

                        <div className="col-lg-4 col-md-6 col-sm-12">
                          <div className="input-block mb-3 add-products">
                            <label>
                              HSN/SAC
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Enter HSN/SAC"
                              onChange={handleChange}
                              id="hsn_sac"
                              name="hsn_sac"
                              maxLength={8}
                              value={item.hsn_sac}
                            />
                            {/* Optionally display validation errors for hsn_sac */}
                          </div>
                        </div>
                      </div>
                    </div>

                    <form action="/product-list" className="text-end">
                      <button onClick={handleBackAction} className="btn  btn-primary  me-2">
                        Cancel
                      </button>

                      <button type="button" className="btn btn-primary" onClick={handleUpdate}>
                        {item.id ? 'Save' : 'Create'} Item
                      </button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddProduct;
