import axios from 'axios';
import AuthService from "../services/auth.service";
import config from './config';
const API_URL = config.API_URL;


const currentUser = AuthService.getCurrentUser();



const getClients = () => {
  return axios
  .post(API_URL + "getCustomers", {
    apptoken: currentUser.apptoken, // Ensure the payload is correct
  
  })
  .then((response) => {
    console.log("response.data.apptoken")
    console.log(response.data.data)

    if (response.data.errorMessage) {
      throw new Error(response.data.errorMessage);
    }

 


    return response.data;
  });

};



const addupdateClientWebWizard = (id,client) => {
  return axios
  .post(API_URL + "addupdateClientWebWizard", {
    apptoken: currentUser.apptoken, // Ensure the payload is correct  addCustomerData
    ...client
  })
  .then((response) => {
    console.log("response.data.addCustomerData")
    console.log(response.data.data)

    if (response.data.errorMessage) {
      throw new Error(response.data.errorMessage);
    }

    return response.data;
  });
};


const createClient = (id,client) => {
  return axios
  .post(API_URL + "addCustomerData", {
    apptoken: currentUser.apptoken, // Ensure the payload is correct  addCustomerData
    ...client
  })
  .then((response) => {
    console.log("response.data.addCustomerData")
    console.log(response.data.data)

    if (response.data.errorMessage) {
      throw new Error(response.data.errorMessage);
    }

    return response.data;
  });
};

// const updateItem = (id, item) => {
//   return axios.put(API_URL + `items/${id}`, item);
// };


const updateClient = (id, client) => {
  return axios
  .post(API_URL + "CustomerDataupdate", {
    apptoken: currentUser.apptoken, // Ensure the payload is correct  addCustomerData
    ...client
  })
  .then((response) => {
    console.log("response.data.CustomerDataupdate")
    console.log(response.data.data)

    if (response.data.errorMessage) {
      throw new Error(response.data.errorMessage);
    }

    return response.data;
  });

};

const deleteClient = (id) => {
  return axios
  .post(API_URL + "deleteVendorcustomer", {
    apptoken: currentUser.apptoken, // Ensure the payload is correct
    customer_id : id,
  })
  .then((response) => {
    console.log("response.data.apptoken")
    console.log(response.data.data)

    if (response.data.errorMessage) {
      throw new Error(response.data.errorMessage);
    }

   

    return response.data;
  });

};




const fetchCountries = () => {
  return axios
  .post(API_URL + "getCountries", {
    apptoken: currentUser.apptoken, // Ensure the payload is correct
  
  })
  .then((response) => {
    console.log("response.data.getCountries")
    console.log(response.data.data)

    if (response.data.errorMessage) {
      throw new Error(response.data.errorMessage);
    }


    return response.data;
  });

};

const fetchStates = (country_id) => {
  return axios
  .post(API_URL + "getStates", {
    apptoken: currentUser.apptoken, // Ensure the payload is correct
    country_id: country_id
  })
  .then((response) => {
    console.log("response.data.getClientUnitMasters")
    console.log(response.data.data)

    if (response.data.errorMessage) {
      throw new Error(response.data.errorMessage);
    }

   

    return response.data;
  });

};



export default {
  getClients,
  createClient,
  updateClient,
  deleteClient,
  fetchCountries,
  fetchStates,
  addupdateClientWebWizard,
};
