import React, { useState, useEffect } from "react";
import Header from "../layouts/Header";
import Sidebar from "../layouts/Sidebar";
import { Link } from "react-router-dom";
import Select from "react-select";
import UserService from "../services/user.service";

import alertify from "alertifyjs";
import "../../node_modules/alertifyjs/build/css/alertify.css";


import ETemplate1 from "../assets/img/printtemplate/ETemplate1.png";
import ETemplate2 from "../assets/img/printtemplate/ETemplate2.png";
import ETemplate3 from "../assets/img/printtemplate/ETemplate3.png";
import ETemplate4 from "../assets/img/printtemplate/ETemplate4.png";
import ETemplateSign1 from "../assets/img/printtemplate/ETemplateSign1.png";
import ETemplateSign2 from "../assets/img/printtemplate/ETemplateSign2.png";
import ETemplateSign3 from "../assets/img/printtemplate/ETemplateSign3.png";
import ETemplateSign4 from "../assets/img/printtemplate/ETemplateSign4.png";


import TemplateSign1 from "../assets/img/printtemplate/TemplateSign1.png";
import TemplateSign2 from "../assets/img/printtemplate/TemplateSign2.png";
import TemplateSign3 from "../assets/img/printtemplate/TemplateSign3.png";
import TemplateSign4 from "../assets/img/printtemplate/TemplateSign4.png";
import Template1 from "../assets/img/printtemplate/Template1.png";
import Template2 from "../assets/img/printtemplate/Template2.png";
import Template3 from "../assets/img/printtemplate/Template3.png";
import Template4 from "../assets/img/printtemplate/Template4.png";

import "lightbox.js-react/dist/index.css";
import staticmessages from '../services/staticmessages';

import * as yup from 'yup';
import "../../node_modules/alertifyjs/build/css/alertify.css";
import SettingsSidebar from '../layouts/SettingsSidebar';

const InvoiceTemplateSettings = () => {


  const [profile, setProfile] = useState({
    invoice_label: "",
    invoice_template: "",
    invoice_isdetails: false,
    invoice_issignature: false,
    invoice_isshowlogo:false,
    estimate_label: "",
    estimate_template: "",
    estimate_isdetails: false,
    estimate_issignature: false,
    estimate_isshowlogo: false,
  });
  // eslint-disable-next-line no-unused-vars
  const [errors, setErrors] = useState({});
  const [isProfileLoaded, setIsProfileLoaded] = useState(false);
  const [invlogo, setInvLogo] = useState(null);
  const [estlogo, setEstLogo] = useState(null);



  useEffect(() => {
    const fetchData = async () => {
      await fetchProfile();
    };
    fetchData();
  }, []);

  useEffect(() => {
    if (isProfileLoaded) {
      setInvLogo(profile.invoice_template);
      setEstLogo(profile.estimate_template);
    }
  }, [isProfileLoaded, profile]);

  const renderImageInv = () => {

    console.error(invlogo);

    if (invlogo === 'Template1') {
      if(profile.invoice_issignature){
        return <img className="img-fluid templteimgeov" src={TemplateSign1} alt="Post Image 111" />;
      }
      else{
        return <img className="img-fluid templteimgeov" src={Template1} alt="Post Image 111aaa" />;
      }
     
    } 
    else if (invlogo === 'Template2') {
      if(profile.invoice_issignature){
        return <img className="img-fluid templteimgeov" src={TemplateSign2} alt="Post Image 2222bbb" />;
      }
      else{
      return <img className="img-fluid templteimgeov" src={Template2} alt="Post Image 2222" />;
      }
    }
    else if (invlogo === 'Template3') {
      if(profile.invoice_issignature){
        return <img className="img-fluid templteimgeov" src={TemplateSign3} alt="Post Image 3333ccc" />;
      }
      else{
      return <img className="img-fluid templteimgeov" src={Template3} alt="Post Image 3333" />;
      }
    }
    else if (invlogo === 'Template4') {
      if(profile.invoice_issignature){
        return <img className="img-fluid templteimgeov" src={TemplateSign4} alt="Post Image 4444dddd" />;
      }
      else{
      return <img className="img-fluid templteimgeov" src={Template4} alt="Post Image 4444" />;
      }
    }
    else {
      return null; // Handle other cases if needed
    }
  };

  const renderImageEst = () => {

console.error(estlogo);

    if (estlogo === 'ETemplate1') {
      if(profile.estimate_issignature){
        return <img className="img-fluid templteimgeov" src={ETemplateSign1} alt="Post Image 3333ccc" />;
      }
      else{
        return <img className="img-fluid templteimgeov" src={ETemplate1} alt="Post Image 111" />;
      }
     
    } else if (estlogo === 'ETemplate2') {
      if(profile.estimate_issignature){
        return <img className="img-fluid templteimgeov" src={ETemplateSign2} alt="Post Image 3333ccc" />;
      }
      else{
        return <img className="img-fluid templteimgeov" src={ETemplate2} alt="Post Image 111" />;
      }
    } 
    else if (estlogo === 'ETemplate3') {
      if(profile.estimate_issignature){
        return <img className="img-fluid templteimgeov" src={ETemplateSign3} alt="Post Image 3333ccc" />;
      }
      else{
        return <img className="img-fluid templteimgeov" src={ETemplate3} alt="Post Image 111" />;
      }
    } else if (estlogo === 'ETemplate4') {
      if(profile.estimate_issignature){
        return <img className="img-fluid templteimgeov" src={ETemplateSign4} alt="Post Image 3333ccc" />;
      }
      else{
        return <img className="img-fluid templteimgeov" src={ETemplate4} alt="Post Image 111" />;
      }
    }
    else {
      return null; // Handle other cases if needed
    }
  };

  const ReloadTemplate = async (name, val) => {
    if (name === 'invoice_template') {
      setInvLogo(val);
    }
    if (name === 'estimate_template') {
      setEstLogo(val);
    }
  };

  const fetchProfile = async () => {
    try {
      const response = await UserService.getUserProfile();
      const { invoice_label, invoice_template, invoice_isdetails, invoice_issignature,invoice_isshowlogo, estimate_label, estimate_template, estimate_isdetails, estimate_issignature ,estimate_isshowlogo} = response.data;
      setProfile({
        invoice_label: invoice_label || '',
        invoice_template: invoice_template || '',
        invoice_isdetails: invoice_isdetails || false,
        invoice_issignature: invoice_issignature || false,
        invoice_isshowlogo: invoice_isshowlogo || false,

        estimate_label: estimate_label || '',
        estimate_template: estimate_template || '',
        estimate_isdetails: estimate_isdetails || false,
        estimate_issignature: estimate_issignature || false,
        estimate_isshowlogo: estimate_isshowlogo || false,

      });
      setIsProfileLoaded(true);
    } catch (error) {
      console.error("UserService error:", error);
    }
  };

  const formattedOptions = (options) =>
    options.map((option) => ({
      value: option.value,
      label: option.label,
    }));

  const handleSelectChange = (selectedOption, name) => {
    ReloadTemplate(name, selectedOption.value);
    setProfile({ ...profile, [name]: selectedOption.value });
    console.log(selectedOption);
  };

  const schema = yup.object().shape({
    invoice_label: yup.string().required('Invoice Label is required'),
    invoice_template: yup.string().required('Invoice Template is required'),
    estimate_label: yup.string().required('Estimate Label is required'),
    estimate_template: yup.string().required('Estimate Template is required'),
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setProfile({ ...profile, [name]: value });
  };

  const toastrSuccess = (message) => {
    alertify.set("notifier", "position", "top-center");
    alertify.success(message);
  };

  const handleSubmit = async (e) => {
    console.log("submit Call");
    e.preventDefault();
    console.log("submit Call 2"+invlogo);
    try {
      await schema.validate(profile, { abortEarly: false });
      const updatedProfile = {
        ...profile,
        estimate_template: estlogo,
        invoice_template: invlogo,
      };
      console.log(updatedProfile);
      await UserService.updateInvoiceEstimateTemplate(updatedProfile);
      setErrors({});
      toastrSuccess(staticmessages.SETTING_PRINTTEMPSUCCESS);
    } catch (validationError) {

    

      if (validationError instanceof yup.ValidationError) {
        const errorsObj = {};
        validationError.inner.forEach((error) => {
          console.log(error.message);
          errorsObj[error.path] = error.message;
        });
        setErrors(errorsObj);
      } else {
        console.error(validationError);
      }
    }
  };

  const handleCheckboxChange = (name) => (event) => {
    const updatedProfile = {
      ...profile,
      [name]: event.target.checked,  // Use computed property names to set the value dynamically
    };

    setProfile(updatedProfile);
  };

  const [menu, setMenu] = useState(false);
  const toggleMobileMenu = () => {
    setMenu(!menu);
  };

  const forceMenuClose = () => {  
    setMenu(false);
  };

  return (
    <>
     <div className={`main-wrapper ${menu ? "slide-nav" : ""}`}>
     <Header onMenuClick={() => toggleMobileMenu()} forceMenuClose={forceMenuClose} />
      <Sidebar />
      <div className="page-wrapper">
        <div className="content container-fluid">
          <div className="row">
            <div className="col-xl-3 col-md-4">
              <SettingsSidebar />
            </div>
            <div className="col-xl-9 col-md-8">
              <div className="card company-settings-new">
                <div className="card-body w-100">
                  <div className="content-page-header">
                    <h5>{staticmessages.SETTING_TEMPLATE}</h5>
                  </div>
                 
                  <div className="row">
                    <div className="card invoices-tabs-card">
                      <div className="invoice-template-tab invoices-main-tabs">
                        <div className="row align-items-center">
                          <div className="col-lg-12">
                            <div className="invoices-tabs">
                              <ul className="nav nav-tabs">
                                <li className="nav-item">
                                  <Link
                                    id="invoice-tab"
                                    data-bs-toggle="tab"
                                    data-bs-target="#invoice_tab"
                                    type="button"
                                    role="tab"
                                    aria-controls="invoice_tab"
                                    aria-selected="true"
                                    to="#"
                                    className="active"
                                  >
                                    Invoice
                                  </Link>
                                </li>
                                <li className="nav-item">
                                  <Link
                                    id="purchases-tab"
                                    data-bs-toggle="tab"
                                    data-bs-target="#purchases_tab"
                                    type="button"
                                    role="tab"
                                    aria-controls="purchases_tab"
                                    aria-selected="true"
                                    to="#"
                                  >
                                    Estimate
                                  </Link>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="tab-content">
                      <div
                        className="tab-pane active"
                        id="invoice_tab"
                        role="tabpanel"
                        aria-labelledby="invoice-tab"
                        tabIndex={0}
                      >
                        <div className="card template-invoice-card">
                          <div className="card-body pb-0">
                            <div className="invoice-card-title">
                              <h6>Invoice</h6>
                            </div>
                            <div className="row">
                              <div className="col-lg-6 col-12">
                                <div className="input-block mb-3">
                                  <label>Invoice Label<span className="alertstrik">*</span></label>
                                  <input
                                    type="text"
                                    name="invoice_label"
                                    className="form-control"
                                    value={profile.invoice_label}
                                    onChange={handleChange}
                                  />
                                  <small className="error">{errors?.invoice_label}</small>
                                </div>
                                <div className="input-block mb-3">
                                  <label>Select Invoice Template<span className="alertstrik">*</span></label>
                                  {isProfileLoaded && (
                                    <Select
                                      name="invoice_template"
                                      defaultValue={profile.invoice_template ? formattedOptions(staticmessages.TEMPLATE_INV).find(option => option.value === profile.invoice_template) : null}
                                      options={formattedOptions(staticmessages.TEMPLATE_INV)}
                                      placeholder="Select Invoice Template"
                                      onChange={(selectedOption) => handleSelectChange(selectedOption, 'invoice_template')}
                                    />
                                  )}
                                </div>

                                <div className="input-block mb-1  ">
                                  <input
                                    type="checkbox"
                                    id="showDetailsCheckbox"
                                    checked={profile.invoice_isdetails}
                                    onChange={handleCheckboxChange('invoice_isdetails')}
                                  />
                                  <label style={{ marginLeft: '7px' }} htmlFor="showDetailsCheckbox">Show  Tax Details in Itemwise </label>
                                </div>

                                <div className="input-block mb-1">
                                  <input
                                    type="checkbox"
                                    id="showSignatureCheckbox"
                                    checked={profile.invoice_issignature}
                                    onChange={handleCheckboxChange('invoice_issignature')}
                                  />
                                  <label style={{ marginLeft: '7px' }} htmlFor="showSignatureCheckbox">Show Signature </label>
                                </div>


                                <div className="input-block mb-1">
                                  <input
                                    type="checkbox"
                                    id="showLogoCheckbox"
                                    checked={profile.invoice_isshowlogo}
                                    onChange={handleCheckboxChange('invoice_isshowlogo')}
                                  />
                                  <label style={{ marginLeft: '7px' }} htmlFor="showLogoCheckbox">Show Logo </label>
                                </div>

                              </div>
                              <div className="col-md-6 col-xl-3 col-sm-12 d-md-flex d-sm-block">
                                <div className="blog grid-blog invoice-blog flex-fill d-flex flex-wrap align-content-betweens">
                                  <div className="blog-image">
                                    {renderImageInv()}
                                    {invlogo === "Template1" ? (
                                      <Link
                                        to={Template1}
                                        className="preview-invoice image-popup"
                                      >
                                        <i className="fa-regular fa-eye" />
                                      </Link>
                                    ) : (
                                      <Link
                                        to={Template2}
                                        className="preview-invoice image-popup"
                                      >
                                        <i className="fa-regular fa-eye" />
                                      </Link>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="tab-pane"
                        id="purchases_tab"
                        role="tabpanel"
                        aria-labelledby="purchases-tab"
                        tabIndex={0}
                      >
                        <div className="card template-invoice-card">
                          <div className="card-body pb-0">
                            <div className="invoice-card-title">
                              <h6>Estimate</h6>
                            </div>
                            <div className="row">
                              <div className="col-lg-6 col-12">
                                <div className="input-block mb-3">
                                  <label>Estimate Label<span className="alertstrik">*</span></label>
                                  <input
                                    type="text"
                                    name="estimate_label"
                                    className="form-control"
                                    value={profile.estimate_label}
                                    onChange={handleChange}
                                  />
                                  <small className="error">{errors?.estimate_label}</small>
                                </div>
                                <div className="input-block mb-3">
                                  <label>Select Estimate Template<span className="alertstrik">*</span></label>
                                  {isProfileLoaded && (
                                    <Select
                                      name="estimate_template"
                                      defaultValue={profile.estimate_template ? formattedOptions(staticmessages.TEMPLATE_EST).find(option => option.value === profile.estimate_template) : null}
                                      options={formattedOptions(staticmessages.TEMPLATE_EST)}
                                      placeholder="Select Estimate Template"
                                      onChange={(selectedOption) => handleSelectChange(selectedOption, 'estimate_template')}
                                    />
                                  )}
                                </div>

                                <div className="input-block mb-1">
                                  <input
                                    type="checkbox"
                                    id="showDetailsCheckbox2"
                                    checked={profile.estimate_isdetails}
                                    onChange={handleCheckboxChange('estimate_isdetails')}
                                  />
                                  <label style={{ marginLeft: '7px' }} htmlFor="showDetailsCheckbox2">Show Discount Details </label>
                                </div>

                                <div className="input-block mb-1">
                                  <input
                                    type="checkbox"
                                    id="showSignatureCheckbox2"
                                    checked={profile.estimate_issignature}
                                    onChange={handleCheckboxChange('estimate_issignature')}
                                  />
                                  <label style={{ marginLeft: '7px' }} htmlFor="showSignatureCheckbox2">Show Signature </label>
                                </div>


                                <div className="input-block mb-1">
                                  <input
                                    type="checkbox"
                                    id="showLogoEstCheckbox"
                                    checked={profile.estimate_isshowlogo}
                                    onChange={handleCheckboxChange('estimate_isshowlogo')}
                                  />
                                  <label style={{ marginLeft: '7px' }} htmlFor="showLogoEstCheckbox">Show Logo </label>
                                </div>


                              </div>
                              <div className="col-md-6 col-xl-3 col-sm-12 d-md-flex d-sm-block">
                                <div className="blog grid-blog invoice-blog flex-fill d-flex flex-wrap align-content-betweens">
                                  <div className="blog-image">
                                    {renderImageEst()}
                                    {estlogo === "ETemplate1" ? (
                                      <Link
                                        to={ETemplate1}
                                        className="preview-invoice image-popup"
                                      >
                                        <i className="fa-regular fa-eye" />
                                      </Link>
                                    ) : (
                                      <Link
                                        to={ETemplate2}
                                        className="preview-invoice image-popup"
                                      >
                                        <i className="fa-regular fa-eye" />
                                      </Link>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <div className="btn-path text-end">
                        <button
                          type="submit"
                          className="btn btn-primary"
                          onClick={handleSubmit}
                        >
                          Save Changes
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      </div>
    </>
  );
};

export default InvoiceTemplateSettings;