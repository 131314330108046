import React, { useState, useEffect } from "react";
import Header from '../layouts/Header';
import Sidebar from '../layouts/Sidebar';
import SettingsSidebar from '../layouts/SettingsSidebar';
import TextEditor from '../inventory/products/editor';

import staticmessages from '../services/staticmessages';
import alertify from "alertifyjs";
import * as yup from 'yup'; 
import "../../node_modules/alertifyjs/build/css/alertify.css";
import UserService from "../services/user.service";

const TermsConditionsSetting = () => {
  const [profile, setProfile] = useState({
    invoice_notes: "",
  });
  const [isProfileLoaded, setIsProfileLoaded] = useState(false);
   // eslint-disable-next-line no-unused-vars
  const [errors, setErrors] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      await fetchProfile();
    };
    fetchData();
  }, []);

  const fetchProfile = async () => {
    try {
      const response = await UserService.getUserProfile();
      const { invoice_notes } = response.data;
      setProfile({
        invoice_notes: invoice_notes || '',
      });
      setIsProfileLoaded(true);
    } catch (error) {
      console.error("UserService error:", error);
    }
  };

  const schema = yup.object().shape({
    invoice_notes: yup.string().required(`${staticmessages.SETTING_TERMCOND} is required`),
  });
  

  const toastrSuccess = (message) => {
    alertify.set("notifier", "position", "top-center");
    alertify.success(message);
  };

  const handleSubmit = async (e) => {

    console.log("handleSubmit")
    e.preventDefault();
    try {
      await schema.validate(profile, { abortEarly: false });
      await UserService.updateNotesSettingDetails(profile);
      setErrors({});
      toastrSuccess(staticmessages.SETTING_NOTESSUCCESS);
    } catch (validationError) {
      if (validationError instanceof yup.ValidationError) {
        const errorsObj = {};
        validationError.inner.forEach((error) => {
          errorsObj[error.path] = error.message;
        });
        console.log(errorsObj)

        setErrors(errorsObj);
      } else {
        console.error(validationError);
      }
    }
  };

  const handleEditorChange = (event, editor) => {
    const newData = editor.getData();
    setProfile((prevProfile) => ({
      ...prevProfile,
      invoice_notes: newData,
    }));
  };

  const [menu, setMenu] = useState(false);
  const toggleMobileMenu = () => {
    setMenu(!menu);
  };

  const forceMenuClose = () => {  
    setMenu(false);
  };
  return (
    <>
       <div className={`main-wrapper ${menu ? "slide-nav" : ""}`}>
       <Header onMenuClick={() => toggleMobileMenu()}   forceMenuClose={forceMenuClose}/>
      <Sidebar />
      <div className="page-wrapper">
        <div className="content container-fluid">
          <div className="row">
            <div className="col-xl-3 col-md-4">
              <SettingsSidebar />
            </div>
            <div className="col-xl-9 col-md-8">
              <div className="card">
                <div className="card-body w-100">
                  <div className="content-page-header">
                    <h5>{staticmessages.SETTING_TERMCOND}</h5>
                  </div>
                  <form onSubmit={handleSubmit}>
                    <div className="row">
                      <div className="col-md-12 description-box">
                        <div className="input-block mb-3">
                          <label className="form-control-label">Terms & Conditions</label>
                          {isProfileLoaded && (
                            <TextEditor 
                              data={profile.invoice_notes}
                              onChange={handleEditorChange}
                               height="500px"
                            />
                           
                          )}
                       <small className="error">{errors?.invoice_notes}</small>
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="modal-footer p-0">
                          <button 
                            type="submit"
                            className="btn btn-primary"
                          >
                            Save Changes
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      </div>
    </>
  );
};

export default TermsConditionsSetting;
