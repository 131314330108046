import axios from 'axios';
import AuthService from "../services/auth.service";
import config from './config';
const API_URL = config.API_URL;


const currentUser = AuthService.getCurrentUser();



const getItems = () => {
  return axios
  .post(API_URL + "getCustomerItems", {
    apptoken: currentUser.apptoken, // Ensure the payload is correct
  
  })
  .then((response) => {
    console.log("response.data.apptoken")
    console.log(response.data.data)

    if (response.data.errorMessage) {
      throw new Error(response.data.errorMessage);
    }

    if (response.data.data && response.data.data.username) {
      localStorage.setItem("user", JSON.stringify(response.data.data));
    } else {
      console.warn("No username found in response data.");
    }


    return response.data;
  });

};



const createItem = (item) => {
  return axios.post(API_URL + "items", item);
};

// const updateItem = (id, item) => {
//   return axios.put(API_URL + `items/${id}`, item);
// };


const updateItem = (id, item) => {
  return axios
  .post(API_URL + "addCustomerItem", {
    apptoken: currentUser.apptoken, // Ensure the payload is correct
    ...item
  })
  .then((response) => {
    console.log("response.data.apptoken")
    console.log(response.data.data)

    if (response.data.errorMessage) {
      throw new Error(response.data.errorMessage);
    }

    return response.data;
  });

};


const updateItemOnboard = (id, item) => {
  return axios
  .post(API_URL + "addCustomerItemOnboard", {
    apptoken: currentUser.apptoken, // Ensure the payload is correct
    ...item
  })
  .then((response) => {
    console.log("response.data.apptoken")
    console.log(response.data.data)

    if (response.data.errorMessage) {
      throw new Error(response.data.errorMessage);
    }

    return response.data;
  });

};

const deleteItem = (id) => {
  return axios
  .post(API_URL + "deleteItemcustomer", {
    apptoken: currentUser.apptoken, // Ensure the payload is correct
    item_id : id,
  })
  .then((response) => {
    console.log("response.data.apptoken")
    console.log(response.data.data)

    if (response.data.errorMessage) {
      throw new Error(response.data.errorMessage);
    }

   

    return response.data;
  });

};




const fetchCategories = () => {
  return axios
  .post(API_URL + "getCustomerItemCategoryMasters", {
    apptoken: currentUser.apptoken, // Ensure the payload is correct
  
  })
  .then((response) => {
    console.log("response.data.getCustomerItemCategoryMasters")
    console.log(response.data.data)

    if (response.data.errorMessage) {
      throw new Error(response.data.errorMessage);
    }


    return response.data;
  });

};


const addeditCategory = (category) => {
  return axios
  .post(API_URL + "addCustomerItemCategory", {
    apptoken: currentUser.apptoken, // Ensure the payload is correct
    ...category
  })
  .then((response) => {
    console.log("response.data.getCustomerItemCategoryMasters")
    console.log(response.data.data)

    if (response.data.errorMessage) {
      throw new Error(response.data.errorMessage);
    }


    return response.data;
  });

};


const deleteCategory = (id) => {
  return axios
  .post(API_URL + "deleteCategorycustomer", {
    apptoken: currentUser.apptoken, // Ensure the payload is correct
    item_id : id,
  })
  .then((response) => {
    console.log("response.data.apptoken")
    console.log(response.data.data)

    if (response.data.errorMessage) {
      throw new Error(response.data.errorMessage);
    }

   

    return response.data;
  });

};


const fetchUnits = () => {
  return axios
  .post(API_URL + "getItemUnitMasters", {
    apptoken: currentUser.apptoken, // Ensure the payload is correct
  
  })
  .then((response) => {
    console.log("response.data.getItemUnitMasters")
    console.log(response.data.data)

    if (response.data.errorMessage) {
      throw new Error(response.data.errorMessage);
    }

   

    return response.data;
  });

};



export default {
  getItems,
  createItem,
  updateItem,
  updateItemOnboard,
  deleteItem,
  fetchCategories,
  fetchUnits,
  addeditCategory,
  deleteCategory,
};
