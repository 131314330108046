import React, { useState, useEffect, useRef } from "react";
import { useHistory, useLocation , Link} from 'react-router-dom';
import Header from "../layouts/Header";
import Sidebar from "../layouts/Sidebar";
import "../_components/antd.css";
import { Table } from "antd";
import Select from "react-select";
import DatePicker from "react-datepicker";


// eslint-disable-next-line no-unused-vars
import ViewClientLedger from "../invoices/ledger/viewClientLedger";
import AddVendor from "../customers/vendors/addVendor";

import InvoiceService from '../services/invoice.service';
// eslint-disable-next-line no-unused-vars
import { formatDateISO, getCurrentDateISO, getExtendDateISO } from '../services/Helper';

import * as yup from "yup";
import alertify from "alertifyjs";
import "../../node_modules/alertifyjs/build/css/alertify.css";
import "../../node_modules/alertifyjs/build/css/themes/semantic.css";

import staticmessages from '../services/staticmessages';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
// eslint-disable-next-line no-unused-vars
import report from '../services/reports.service';
  // eslint-disable-next-line no-unused-vars
  import Spinners from '../components/spinners';

const ReportLedger = () => {

  const targetButtonRef = useRef(null);
  // eslint-disable-next-line no-unused-vars
  const [error, setError] = useState(null);
  // eslint-disable-next-line no-unused-vars
  const [errors, setErrors] = useState({}); // State to hold validation errors

  // eslint-disable-next-line no-unused-vars
  const [listType, setListType] = useState("invoicelist");

  // eslint-disable-next-line no-unused-vars
  const history = useHistory();
  // eslint-disable-next-line no-unused-vars
  const location = useLocation();
  const [selectedOption1, setSelectedOption1] = useState(null);
  // eslint-disable-next-line no-unused-vars
  const [startInvoiceDate, setStartInvoiceDate] = useState(new Date());

  // eslint-disable-next-line no-unused-vars
  const [show, setShow] = useState(false);

  // eslint-disable-next-line no-unused-vars
  const [showdelete, setShowDelete] = useState(false);

  const [page, setPage] = useState(1);
  const paginationSize = 10;
  const handleCloseSelect = () => setShowSelect(false);
  // eslint-disable-next-line no-unused-vars
  const [showSelect, setShowSelect] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const openPaymentForm = () => {
    setShowSelect(true);
  };

  // eslint-disable-next-line no-unused-vars
const [loading, setLoading] = useState(true); // Loading state

  const options1 = [
    { value: "", label: "Select Payment Method" },
    { value: "Cash", label: "Cash" },
    { value: "Bank", label: "Bank" },
    { value: "TDS", label: "TDS" },
  ];

  const [menu, setMenu] = useState(false);


  // eslint-disable-next-line no-unused-vars
  const [client, setClientDetail] = useState(false);


  // eslint-disable-next-line no-unused-vars
  const [paymentAct, setSelectedPayemntAct] = useState(null);


  let currentdate = new Date();
  let currentdateex = new Date(currentdate.getTime() - (30 * 24 * 60 * 60 * 1000));




  const [filteroption, setFilterOptions] = useState({ client_id: "", linkModeType: "invoicelist", fdate: currentdateex, tdate: currentdate, uniqueno: "", filterText: "" });
  const [selectedinvoice, setSelectedInvoice] = useState(null);

  // eslint-disable-next-line no-unused-vars
  const [datasource, setLoadData] = useState([]);

  const [dataclosebal, setDataCloseBal] = useState({ cr_sum: 0, dr_sum: 0, closingbalcr_sum: 0, closingbaldr_sum: 0 });


  const toggleMobileMenu = () => {
    setMenu(!menu);
  };





  // eslint-disable-next-line no-unused-vars
  const handleParentReportLedger = () => {
    console.log("handleParentReportLedger 2222");

  };
  // eslint-disable-next-line no-unused-vars
  const [customtitle, setCustomTitle] = useState(staticmessages.PAYMENT_LIST_TITLE);
  const [payment, setPaymentData] = useState({
    client_id: "", linkModeType: "", payment_date: currentdateex,
    invoice_id: "",
    uniqueno: "",
    dateformat: "",
    symbol: "",
    paymentModeType: "invoicepayment",
    amount: "",
    newamount: "",
    payment_mode: "",
    id: "",

  });

  const handleChangeAmount = (e) => {
    const { value } = e.target;

    setPaymentData((prev) => ({
      ...prev,
      amount: value,
      newamount: value,
    }));

  };

  const handlePamyentMethod = (value) => {
    setSelectedOption1(value);

    setPaymentData((prev) => ({
      ...prev,
      payment_mode: value.value
    }));

  };
  const HandaleDate = (value) => {
    setStartInvoiceDate(value);
    setPaymentData((prev) => ({
      ...prev,
      payment_date: value
    }));

  };


  const getUser = async () => {
    try {
      let res = await InvoiceService.getUserProfile();


      setSelectedInvoice((prev) => ({
        ...prev,
        symbol: res.data.symbol,
        linkModeType: "invoicelist",

        dateformat: res.data.dateformat,
      }));

      console.log("getUser=======");
      console.log(res.data.symbol);
    } catch (error) {
      console.error('Login failed:', error);
    }
  };

  useEffect(() => {
    getUser();
  }, []);


  useEffect(() => {
    console.log("ReportLedger Data");
    console.log(selectedinvoice);

    if (datasource && datasource.length > 0 && selectedinvoice) {


      // Calculate sum of cr_amount and dr_amount
      const sumCrAmount = datasource.reduce((total, item) => total + item.cr_amount, 0);
      const sumDrAmount = datasource.reduce((total, item) => total + item.dr_amount, 0);


      const closingbalcr_sum = (sumCrAmount - sumDrAmount).toFixed(2);
      const closingbaldr_sum = (sumDrAmount - sumCrAmount).toFixed(2);

      console.log(sumCrAmount + '---' + sumDrAmount + '---' + closingbalcr_sum + '::' + closingbaldr_sum);



      // Update state with calculated sums `Showing ${range[0]} to ${range[1]} of ${total} entries`,
      setDataCloseBal({
        cr_sum: `${selectedinvoice.symbol}${sumCrAmount.toFixed(2)} Cr`,
        dr_sum: `${selectedinvoice.symbol}${sumDrAmount.toFixed(2)} Dr`,
        closingbalcr_sum: closingbalcr_sum <= 0 ? "" : `${selectedinvoice.symbol}${closingbalcr_sum} Cr`,
        closingbaldr_sum: closingbaldr_sum <= 0 ? "" : `${selectedinvoice.symbol}${closingbaldr_sum} Dr`
      });
    }

  }, [datasource, selectedinvoice]);


  //   useEffect(() => {

  //     console.log("ReportLedger  77777---");


  //    if(location.state?.selectedinvoice.client_name)
  //     {
  //       console.log("ReportLedger 77777");
  //       console.log(location.state?.selectedinvoice);
  //    setSelectedInvoice((prev) => ({
  //     ...prev,
  //     client_id: location.state?.selectedinvoice.customer.id,
  //     uniqueno:location.state?.selectedinvoice.uniqueno,
  //     linkModeType:"invoicelist",
  //     symbol: location.state?.selectedinvoice.symbol,
  //     dateformat: location.state?.selectedinvoice.dateformat,
  //   }));





  //    setFilterOptions((prev) => ({
  //     ...prev,
  //     client_id: location.state?.selectedinvoice.customer.id,
  //     uniqueno:location.state?.selectedinvoice.uniqueno,
  //     linkModeType:"invoicelist",
  //   }));

  //   setSelectedInvoice(location.state?.selectedinvoice);
  //   setClientDetail(location.state?.selectedinvoice.customer)
  // }

  //   }, [location.state?.selectedinvoice]);


  useEffect(() => {
    if (location.state && location.state.customer) {
      console.log("ReportLedger Data zzzzzz");
      console.log(location.state.customer);
      getLedgerCurrency(location.state.customer.id);
  
      setClientDetail(location.state.customer);
  
      setFilterOptions((prev) => ({
        ...prev,
        client_id: location.state.customer.id,
        linkModeType: "invoicelist",
      }));
    }
  }, [location.state]);
  

  // useEffect(() => {
  //   console.log("ReportLedger Data");
  //   console.log(location.state.customer.id);
  //   if (location.state) {
  //     getLedgerCurrency(location.state.customer.id);

  //     setClientDetail(location.state.customer)

  //     setFilterOptions((prev) => ({
  //       ...prev,
  //       client_id: location.state.customer.id,

  //       linkModeType: "invoicelist",
  //     }));


  //   }


  // }, [location.state.customer]);



  useEffect(() => {
    console.log("ReportLedger Data");

    if (filteroption.client_id) {


      getLedgerData(filteroption);

    }


  }, [filteroption]);

  const getLedgerData = async (filteroption) => {
    try {
      let res = await InvoiceService.getcustomerledgermainreport(filteroption);

      setLoadData(res.data);
      console.log("getLedgerData", res.data);
      setLoading(false);
    } catch (error) {
      console.error('Login failed:', error);
      setLoading(false);
    }
  };

  const getLedgerCurrency = async (client_id) => {
    try {
      let res = await InvoiceService.getCustomerTransCurr(client_id);
      //  setLoadData(res.data);
      console.log("getLedgerData", res);
    } catch (error) {
      console.error('Login failed:', error);
    }
  };


  const schema = yup.object({
    amount: yup.string().required("Amount is required"),
    payment_mode: yup.string().required("Payment Method is required"),
    payment_date: yup.string().required("Payment Date is required"),
  });

  const toastrError = (message) => {
    alertify.set("notifier", "position", "top-center");
    alertify.error(message);
  };
  const toastrSuccess = (message) => {
    alertify.set("notifier", "position", "top-center");
    alertify.success(message);
  };


  // eslint-disable-next-line no-unused-vars
  const handaleSubmenu = (type, listType, record) => {

    console.log("------handaleSubmenu ===== ");
    console.log(record);


    const selectedinvoice = location.state?.selectedinvoice || {};

    setPaymentData(prevState => ({
      ...prevState,
      ...record, // Spread the properties of record into the state
      client_id: selectedinvoice.customer?.id || "",
      
    }));

    setSelectedOption1({ value: record.payment_mode, label: record.payment_mode })

    setStartInvoiceDate(record.payment_date);

    setShowSelect(true);
  };




    // eslint-disable-next-line no-unused-vars
    const handaleSavePayment = () => {


      if (startInvoiceDate instanceof Date) {
        console.log("------handaleSavePayment aaa ===== " + startInvoiceDate);
        // Check if the date is already in the desired format
  
  
        setPaymentData(prevState => ({
          ...prevState,
          ...payment, // Spread the properties of record into the state
          payment_date: startInvoiceDate,
          client_id: location.state.customer.id,
        }));
  
        setCallApi(new Date());
      } else if (typeof startInvoiceDate === 'number') {
        // Convert timestamp to Date and format
        const date = new Date(startInvoiceDate);
        const formattedDate = date.toISOString();
        setPaymentData(prevState => ({
          ...prevState,
          ...payment, // Spread the properties of record into the state
          payment_date: formattedDate,
          client_id: location.state.customer.id,
        }));
        setCallApi(new Date());
      }
    };
  
// eslint-disable-next-line no-unused-vars
const [callapi, setCallApi] = useState(null);

useEffect(() => {
  if (callapi) {
    console.log("------handaleSavePaymentFianlCall zzzz ===== " + startInvoiceDate + "--" + location.state.customer.id);
    console.log("ckkdev 111 calculateTotalDiscount 00000---" + JSON.stringify(payment, null, 2));
  handaleSavePaymentFianlCall()
  }

}, [callapi]); // 

   // eslint-disable-next-line no-unused-vars
   const handaleSavePaymentFianlCall = () => {

    schema.validate(payment, { abortEarly: false })
      .then(() => {
        const action = payment.id ? InvoiceService.updatePaymentcustomer : InvoiceService.addPaymentcustomer;


        console.log("------handaleSavePayment ===== " + action);

        console.log(payment);

        action(payment.id, payment, listType)
          .then(() => {

            if (payment.id) {
              let mess = `${customtitle} ${staticmessages.PAYMENT_USUCCESS}`;
              toastrSuccess(mess);
              getLedgerData(filteroption);
              window.location.reload();

            }
            else {
              let mess = `${customtitle} ${staticmessages.PAYMENT_CSUCCESS}`;
              toastrSuccess(mess)
              getLedgerData(filteroption);
              window.location.reload();
            }

            if (targetButtonRef.current) {
              targetButtonRef.current.click();
            }


          })
          .catch((error) => {
            setError(error.message);
            toastrError(error.message);
          });
      })
      .catch((validationErrors) => {
        // Yup validation errors
        const errorsObj = {};
        validationErrors.inner.forEach((error) => {
          errorsObj[error.path] = error.message;
        });
        setErrors(errorsObj);
      });
  };


  // eslint-disable-next-line no-unused-vars
  const [deleteact, setDeleteAct] = useState(null);

  // eslint-disable-next-line no-unused-vars
  const handleDeleteHelper = (data) => {

    setDeleteAct(data);
    setShowDelete(true);

  };

    // eslint-disable-next-line no-unused-vars
  const handaleEditPayment = () => {


    setShowSelect(false);
    setEditSelect(true);
  };

  // eslint-disable-next-line no-unused-vars
  const handleDelete = (objdata) => {

    console.log("------handleDelete ===== " + objdata);
    console.log(objdata);

    if (objdata) {
      InvoiceService.deletePaymentCustomer(objdata)
        .then(() => {
          let mess = `${customtitle} ${staticmessages.INVOICE_DELSUCCESS}`;
          toastrSuccess(mess);
          getLedgerData(filteroption);
          setShowDelete(false);
          setEditSelect(false);
         
        })
        .catch((error) => {
          console.error('Delete Action Failed:', error);
        });
    }
  };
  
  const handleTableChange = (pagination) => {
    setPage(pagination.current);
  };

  const columns = [
    {
      title: "#",
      dataIndex: "id",
      render: (text, record, index) => (page - 1) * paginationSize + index + 1,
    },


    {
      title: "Date",
      dataIndex: "payment_date_br",
      render: (text, record) => (
        <>

<span
  className={
    record.paymentModeType === "invoicelist" || record.brought_forward === "Brought Forward"
      ? "inherit-text"
      : "blue-text-link"
  }
  onClick={() => handleRowClick(record)}
>
          {formatDateISO(record.payment_date)} 
        </span>

         
        </>
      ),
      
    },

    {
      title: "",
      dataIndex: "payment_date",
      render: (text, record) => (
        <>
      <span
  className={
    record.paymentModeType === "invoicelist" || record.brought_forward === "Brought Forward"
      ? "inherit-text"
      : "blue-text-link"
  }
  onClick={() => handleRowClick(record)}
>{record.brought_forward}</span>
          

        </>
      ),
     
    },

    {
      title: "Debit",
      dataIndex: "dr_amount",
      render: (text, record) => (
        <>

<span
  className={
    record.paymentModeType === "invoicelist" || record.brought_forward === "Brought Forward"
      ? "inherit-text"
      : "blue-text-link"
  }
  onClick={() => handleRowClick(record)}
>{record.symbol}{record.dr_amount}</span>

        </>
      ),
     
    },

    {
      title: "Credit",
      dataIndex: "cr_amount",
      render: (text, record) => (
        <>

<span
  className={
    record.paymentModeType === "invoicelist" || record.brought_forward === "Brought Forward"
      ? "inherit-text"
      : "blue-text-link"
  }
  onClick={() => handleRowClick(record)}
> {record.symbol}{record.cr_amount}</span>

        </>
      ),
      
    },

    {
      title: "Invoice",
      dataIndex: "payment_mode",
      render: (text, record) => (
        <>

          {record.paymentModeType === "invoicelist" ? (
               <span
               onClick={() => handleRowClick(record)}
               className={
                 record.paymentModeType === "invoicelist" || record.brought_forward === "Brought Forward"
                   ? "inherit-text"
                   : "blue-text-link"
               }
             >{record.narration}</span>
          ) : (
            <span></span>
          )}

        </>
      ),

    },

    {
      title: "Payment Source",
      dataIndex: "payment_mode",
      render: (text, record) => (
        <>

          {record.paymentModeType === "invoicelist" ? (
            <span></span>
          ) : (
            <span
            onClick={() => handleRowClick(record)}
  className={
    record.paymentModeType === "invoicelist" || record.brought_forward === "Brought Forward"
      ? "inherit-text"
      : "blue-text-link"
  }
>{record.payment_mode}</span>
          )}

        </>
      ),

    },

    {
      title: "Action",
      dataIndex: "Action",
      render: (text, record) => (
        <>

        {record.paymentModeType === "invoicelist" || record.brought_forward === "Brought Forward" ? (
          <span></span>
        ) : (
        <div className="d-flex align-items-center">
          <div className="dropdown dropdown-action">
            <Link
              to="#"
              className=" btn-action-icon "
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <i className="fas fa-ellipsis-v" /> 
            </Link>
            <div className="dropdown-menu dropdown-menu-right">
              <ul>
                <li>
                <button className="dropdown-item" onClick={() => handaleEditPaymentnew(record)}>
                
                    <i className="far fa-edit me-2" />
                    Edit
                  </button>
                </li>
                <li>
          
                  <Link
                    className="dropdown-item"
                    to="#"
             
                    onClick={() => handleDeleteSelectActionConfirmnew(record)}>
                    <i className="far fa-trash-alt me-2" />
                    Delete
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>

)}
         </>
       
      ),
     
    },


    // {
    //   title: "Action",
    //   dataIndex: "Action",
    //   render: (text, record) => (
    //     <>
  
    //       {record.paymentModeType === "invoicelist" || record.brought_forward === "Brought Forward" ? (
    //         <span className="btn-action-icon btn-outline-light"> <i className="fas fa-ellipsis-v" /></span>
    //       ) : (
    //         <div className="text-end">
    //           <div className="dropdown dropdown-action">
    //             <Link
    //               to="#"
    //               className="btn-action-icon"
    //               data-bs-toggle="dropdown"
    //               aria-expanded="false"
    //             >
    //               <i className="fas fa-ellipsis-v" />
    //             </Link>
    //             <div className="dropdown-menu dropdown-menu-end">








    //               <button type="button" className="dropdown-item" onClick={() => handaleSubmenu("edit", listType, record)}>
    //                 <i className="far fa-edit me-2" />
    //                 Edit
    //               </button>

    //               <button type="button" className="dropdown-item" onClick={() => handleDeleteHelper(record)}>
    //                 <i className="far fa-trash me-2" />
    //                 Delete
    //               </button>





    //             </div>
    //           </div>
    //         </div>
    //       )}
    //     </>



    //   ),
    //   sorter: (a, b) => a.Action.length - b.Action.length,
    // },


  ];


  // const downloadCSV = (data, filename = 'LedgerReport.csv') => {
  //   //const csv = convertToCSV(data);
  //   const blob = new Blob([data], { type: 'text/csv;charset=utf-8;' });
  //   const link = document.createElement('a');
  //   const url = URL.createObjectURL(blob);
  //   link.setAttribute('href', url);
  //   link.setAttribute('download', filename);
  //   link.style.visibility = 'hidden';
  //   document.body.appendChild(link);
  //   link.click();
  //   document.body.removeChild(link);
  // };

  const clickDownload = () => {

    report.getledgerData(listType, filteroption, "export", client.id)
      .then((response) => {
        // setInvoices(response.data);
        // setFilteredDataSource(response.data);



        console.log(response);



      })
      .catch((error) => {
        console.log(error);
      });

  };

  const handleApplyFilter = (searchTerm) => {
    setShow(false);

    setSearchTerm(searchTerm);
  

  };

    // Handle row click
    const handleRowClick = (record) => {
      if (record.paymentModeType !== "invoicelist" && record.brought_forward !== "Brought Forward") {
        // Perform action here, e.g., edit
        handaleSubmenu("edit", listType, record);
      }
    };
   // eslint-disable-next-line no-unused-vars
    const handleCloseEditSelect = () => setEditSelect(false);
   // eslint-disable-next-line no-unused-vars
   const [searchTerm, setSearchTerm] = useState(""); 
 // eslint-disable-next-line no-unused-vars
   const [editSelect, setEditSelect] = useState(false);
   // eslint-disable-next-line no-unused-vars
   const handleDeleteSelectActionConfirm = () => {
    setDeleteAct(payment);
    console.log(payment);
    setShowDelete(true);
  };
     // eslint-disable-next-line no-unused-vars
  const handleDeleteSelectAction = () => {
   
    console.log("------handleDelete ===== " + payment);
    console.log(payment);

    if (payment) {
      InvoiceService.deletePaymentCustomer(payment)
        .then(() => {
          let mess = `${customtitle} ${staticmessages.INVOICE_DELSUCCESS}`;
          toastrSuccess(mess);
          getLedgerData(filteroption);
          setEditSelect(false);
          setShowDelete(false);
        })
        .catch((error) => {
          console.error('Delete Action Failed:', error);
        });
    }

  };

  const forceMenuClose = () => {  
    setMenu(false);
  };


  
  const handaleEditPaymentnew = (record) => {

    setPaymentData(record);

    setSelectedOption1({value:record.payment_mode, label:record.payment_mode});
    // setSelectedClient({
    //   value: record.id,
    //   label: record.client_name,
    // });


    setStartInvoiceDate(record.payment_date);

    handaleEditPayment();
   
  };

  const handleDeleteSelectActionConfirmnew = (record) => {
    setPaymentData(record);

    setSelectedOption1({value:record.payment_mode, label:record.payment_mode});
    // setSelectedClient({
    //   value: record.id,
    //   label: record.party_name,
    // });


    setStartInvoiceDate(record.payment_date);

    handleDeleteSelectActionConfirm();
   
  };

  if (loading) {
    return <Spinners />; // Display the spinner while loading
  }

  return (
    <>
      <div className={`main-wrapper ${menu ? "slide-nav" : ""}`}>
      <Header onMenuClick={() => toggleMobileMenu()} forceMenuClose={forceMenuClose} onApplyFilter={handleApplyFilter} searchTerm = {searchTerm} />
        <Sidebar />

        <div className="page-wrapper">
          <div className="content container-fluid">

         
            {/* Page Header */}

            <ViewClientLedger setShow={setShow} show={show} client={client} openPaymentForm={openPaymentForm} showaddpaymet={false} isShowD={true} clickDownload={clickDownload} />




            {/* Table */}
            <div className="row">
              <div className="col-sm-12">
                <div className="card-table">
                  <div className="card-body ReportLedger">
                    <div className="table-responsive table-hover">
                      <Table
                        pagination={{
                          total: datasource.length,
                          showTotal: (total, range) =>
                            `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                          // showSizeChanger: true,

                        }}
                      

                        // onRow={(record) => {
                        //   return {
                        //     onClick: () => handleRowClick(record),
                        //   };
                        // }}
                        columns={columns}
                        dataSource={datasource}
                        onChange={handleTableChange}

                      />


                      <div className="row">
                        <div className="col-lg-6">

                        </div>
                        <div className="col-lg-6">
                          <div className="card">
                            <div className="card-header">
                              <h5 className="card-title">Closing Balance</h5>
                            </div>
                            <div className="card-body">
                              <div className="table-responsive">
                                <table className="table table-striped mb-0">

                                  <tbody>
                                    <tr>
                                      <td>Total</td>
                                      <td>{dataclosebal.dr_sum}</td>
                                      <td>{dataclosebal.cr_sum}</td>
                                    </tr>
                                    <tr>
                                      <td>Closing Balance</td>
                                      <td>{dataclosebal.closingbaldr_sum}</td>
                                      <td>{dataclosebal.closingbalcr_sum}</td>
                                    </tr>

                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>


                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* /Table */}
          </div>
        </div>

        <AddVendor setShow={setShow} show={show} />



        {/* /Add Expenses Modal */}




      </div>







      {/* Add Expenses Modal */}


      <Modal show={editSelect} onHide={handleCloseSelect}>
        <Modal.Header >
          <Modal.Title>Receipt </Modal.Title>
        </Modal.Header>
        <Modal.Body  >

         

          <div className="row">
            <div className="col-md-12">
              <div className="card-body">
                <div className="border-0 pb-0">
                  <div className="row">


                    <div className="col-lg-12 col-md-12 col-sm-12">

                    <div className="col-lg-12 col-md-6 col-sm-12">
                      <div className="input-block mb-3">
                        <label>Payment Method</label>
                        <Select
                          defaultValue={selectedOption1}
                          onChange={handlePamyentMethod}
                          options={options1}

                          placeholder="Select Payment Method"

                        />
                        <small className="error">{errors?.payment_mode}</small>
                      </div>
                    </div>
                      <div className="input-block mb-3">
                        <label>Amount </label>
                        <input

                          className="form-control"
                          placeholder="Enter Amount"

                          onChange={handleChangeAmount}
                          id="amount"
                          name="amount"
                          maxLength={10}
                          value={payment.amount}
                          onClick={(e) => e.target.select()}
                        />
                        <small className="error">{errors?.amount}</small>
                      </div>
                    </div>
                    <div className="col-lg-12 col-md-12 col-sm-12">
                      <div className="input-block mb-3">
                        <label>Payment Date</label>
                        <DatePicker
                          className="datetimepicker form-control"
                          selected={startInvoiceDate}
                          onChange={(date) => HandaleDate(date)}
                          maxDate={new Date()}
                          dateFormat="dd/MM/yyyy"
                        ></DatePicker>
                        <small className="error">{errors?.payment_date}</small>
                      </div>
                    </div>


                 

                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>


        <Modal.Footer>
          <div className="d-flex w-100 justify-content-between">
          {(payment.id) && (
            <Button className="me-2" variant="danger" onClick={handleDeleteSelectActionConfirm}>
              <i className="far fa-trash-alt me-2" />
              Delete
            </Button>
  )}
            <div>
              <Button className="me-2" variant="primary" onClick={handleCloseEditSelect}>
                Close
              </Button>
              <Button variant="primary" onClick={handaleSavePayment}>
                Save
              </Button>
            </div>
          </div>
        </Modal.Footer>


      </Modal>

      <Modal show={showSelect} onHide={handleCloseSelect}>
        <Modal.Header >
          <Modal.Title>Receipt </Modal.Title>

          <div className="d-flex ms-auto align-items-center">
          <button
        type="button"
        className="btn-close ms-2"
        onClick={handleCloseSelect}
        aria-label="Close"
      /></div>
        </Modal.Header>
        <Modal.Body  >

          <div className="row">
            <div className="col-md-12">
              <div className="card-body">
                <div className="border-0 pb-0">
                  <div className="row">


                    
                  <div className="col-lg-12 col-md-12 col-sm-12">
                        <div className="input-block mb-3">
                      

                      <div><label className="h3" >Client</label></div>
                        <div><label>{payment.party_name}</label></div>


                      <div><label className="h3">Payment Method </label></div>
                      <div><label>{payment.payment_mode}</label></div>
                     

                        <div className="row">
                            <div className="col-lg-6 col-md-6 col-sm-12">
                            <div><label className="h3" >Payment Date </label></div>
                            <div><label>{formatDateISO(payment.payment_date)}</label></div>
                            </div>
                            <div className="col-lg-6 col-lg-6 col-sm-12    ">
                            <div className="pull-lg-right row">
                            <div className="">
                              <div className="h3 d-sm-block d-lg-inline" >Amount</div>
                              <div className=" badgefontbig d-sm-block d-md-inline "> 
                             <span className="badge badgefontbig badge-label  bgsuccess"><i className="mdi mdi-circle-medium"></i>{payment.symbol} {payment.amount}</span></div></div>
                            </div> </div>
                        </div>
                        
                    

                     
                      </div>
                    </div>

{/* 
                  <div className="col-lg-12 col-md-12 col-sm-12">
                      <div className="input-block mb-3">
                      <div><label>Payment Method </label></div>
                      <div><label>{payment.payment_mode}</label></div>

                        <div><label>Amount </label></div>
                        <div><label>{payment.symbol} {payment.amount}</label></div>

                        <div><label>Payment Date </label></div>
                        <div><label>{formatDateISO(payment.payment_date)}</label></div>

                    

                      </div>
                    </div> */}






                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        {/* <Modal.Footer>
          <Button className="me-2" variant="primary" onClick={handleCloseSelect}>
            Close
          </Button>
          <Button variant="primary" onClick={handaleEditPayment}>
            Edit
          </Button>
        </Modal.Footer> */}
      </Modal>




      <Modal show={showdelete} >
          <Modal.Header >
            <Modal.Title>Delete Receipt</Modal.Title>
          </Modal.Header>
          <Modal.Body  >

          <p>Are you sure you want to delete? </p>
          </Modal.Body>
          <Modal.Footer>
            <Button className="me-2" variant="primary"  onClick={() => setShowDelete(false)}>
              Cancel
            </Button>
            <Button variant="primary"  onClick={() => handleDelete(deleteact)}>
              Delete
            </Button>
          </Modal.Footer>
        </Modal>


      


      {/* /Add Expenses Modal */}

    </>
  );
};

export default ReportLedger;
