import React, { useState ,useEffect } from "react";
import Header from "../layouts/Header";

// eslint-disable-next-line no-unused-vars
import Sidebar from "../layouts/Sidebar";
  // eslint-disable-next-line no-unused-vars
import ReportModal from "../common/modal/reportModal";
// eslint-disable-next-line no-unused-vars
import BreadcrumbsReport from "../common/breadcrumb/breadCrumbsReport";
import { Table } from "antd";

  // eslint-disable-next-line no-unused-vars
import { Link } from "react-router-dom";

// eslint-disable-next-line no-unused-vars
import {formatDateISO, getCurrentDateISO, getExtendDateISO} from '../services/Helper';



  // eslint-disable-next-line no-unused-vars
import report from '../services/reports.service';
  // eslint-disable-next-line no-unused-vars
import staticmessages from '../services/staticmessages';
import InvoiceService from '../services/invoice.service';
  // eslint-disable-next-line no-unused-vars
  import Spinners from '../components/spinners';

const Balances = () => {
  const [menu, setMenu] = useState(false);
  const [show, setShow] = useState(false);

// eslint-disable-next-line no-unused-vars
const [loading, setLoading] = useState(true); // Loading state

    const [page, setPage] = useState(1);
    const paginationSize = 10;

    // eslint-disable-next-line no-unused-vars
  const [datasource, setInvoices] = useState([]);
    // eslint-disable-next-line no-unused-vars
  const [filteredDataSource, setFilteredDataSource] = useState([]);

    // eslint-disable-next-line no-unused-vars
  const [listType, setListType] = useState("invoicelist");

  //       <ReportModal setShow={setShow} show={show} customtitle={customtitle} listType = {listType} fdate = {fdate} tdate = {tdate} />

    // eslint-disable-next-line no-unused-vars

    let currentdate =   new Date();
    let currentdateex =  new Date(currentdate.getTime() - (30 * 24 * 60 * 60 * 1000));

  const [filteroption, setFilterOptions] = useState({searchterm: "",fdate: currentdateex,tdate:currentdate});

  const [totalgrossbal, setTotalGrossBalance] = useState({total: 0});
  const [currformat, setCurrFormat] = useState("");

  const toggleMobileMenu = () => {
    setMenu(!menu);
  };


  useEffect(() => {
    getUser();
   
  }, []);

  const getUser = async () => {
    try {
      let res = await InvoiceService.getUserProfile();
      setCurrFormat(res.data.symbol);


    
    } catch (error) {
      console.error('Login failed:', error);
    }
  };



  useEffect(() => {
    console.log("ViewLedger Data");
    console.log(datasource[0]);

    if(datasource) {
    
      const sumCrAmount = datasource.reduce((total, item) => total + item.balance_amount, 0);

      const symbol =  currformat;

      setTotalGrossBalance({
  total: `${symbol}${sumCrAmount.toFixed(2)}`,

});
    }
   

  }, [datasource]);

  useEffect(() => {
    
      getBalances();
   
  }, [filteroption]);



  const getBalances = async () => {

   

    report.getBalancesReport(listType, filteroption)
      .then((response) => {
        // setInvoices(response.data);
        // setFilteredDataSource(response.data);

        console.log(response);

        setInvoices(response.data);
        setFilteredDataSource(response.data);
        setLoading(false);

      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  const handleApplyFilter = (searchTerm) => {
    setShow(false);
    setSearchTerm(searchTerm);
   filterData(searchTerm);
   
   setFilterOptions((prev) => ({
    ...prev,
    searchterm: searchTerm
  }));

    getBalances(); // Reload parent data
   
  };

  const handleApplyResetApply = () => {

    setFilterOptions((prev) => ({
      ...prev,
      searchterm: "",fdate: currentdate,tdate:currentdateex
    }));
    setSearchTerm("");

   
    setShow(false);
    getBalances(); // Reload parent data
  };


  const handleTableChange = (pagination) => {
    setPage(pagination.current);
  };

  const columns = [
    {
      title: "#",
      dataIndex: "id",
      render: (text, record, index) => (page - 1) * paginationSize + index + 1,
    },
  
    {
      title: "Client",
      dataIndex: "client_name",
      render: (text, record) => (
        <>
          <span>{record.client_name}</span>
        </>
      ),
      sorter: (a, b) => {
        // Make sure both a and b have client_name defined
        if (!a.client_name) return 1;
        if (!b.client_name) return -1;
        
        // Handle sorting based on client_name, assuming it is a string
        return a.client_name.localeCompare(b.client_name);
      },
    },

    {
      title: "Country",
      dataIndex: "client_country",
      render: (text, record) => (
        <>
          <span>{record.client_country}</span>
        </>
      ),
      sorter: (a, b) => {
        // Make sure both a and b have client_name defined
        if (!a.client_country) return 1;
        if (!b.client_country) return -1;
        
        // Handle sorting based on client_name, assuming it is a string
        return a.client_country.localeCompare(b.client_country);
      },
    },
   
   
    {
      title: "Balance Amount",
      dataIndex: "balance_amount",
      render: (text, record) => (
        <>
         
              <span> {record.trans_currency}{record.balance_amount}</span>
            
        </>
         ),
      sorter: (a, b) => a.balance_amount - b.balance_amount,
    },

   
  ];
  // eslint-disable-next-line no-unused-vars
  const table = (props: any) => {
    return (
      <>
        <div style={{ background: '#bbb' }}>header table</div>
        <table className="table">zzz</table>
      </>
    );
  };



  const downloadCSV = (data, filename = 'BalancesReport.csv') => {
    //const csv = convertToCSV(data);
    const blob = new Blob([data], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    const url = URL.createObjectURL(blob);
    link.setAttribute('href', url);
    link.setAttribute('download', filename);
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const clickDownload = () => {

    report.getReportDownloadCombine(listType, filteroption, "export","browser","customerbalanceexport")
      .then((response) => {
        // setInvoices(response.data);
        // setFilteredDataSource(response.data);

       downloadCSV(response);

        console.log(response);

       

      })
      .catch((error) => {
        console.log(error);
      });
  
  };

   // eslint-disable-next-line no-unused-vars
   const [searchTerm, setSearchTerm] = useState(""); 

   const filterData = (searchTerm) => {

    if (searchTerm) {
     const filteredData = datasource.filter((record) => {
         const clientNameMatch = record.client_name.toLowerCase().includes(searchTerm.toLowerCase());
        
         return clientNameMatch ;
     });
     setFilteredDataSource(filteredData);
    }
    else{
      setFilteredDataSource(datasource);
    }


 };
 

 const forceMenuClose = () => {  
  setMenu(false);
};

if (loading) {
  return <Spinners />; // Display the spinner while loading
}


  return (
    <>
      <div className={`main-wrapper ${menu ? "slide-nav" : ""}`}>
      <Header onMenuClick={() => toggleMobileMenu()} forceMenuClose={forceMenuClose}  onApplyFilter={handleApplyFilter} searchTerm = {searchTerm} />
        <Sidebar />
        <div className="page-wrapper">
          <div className="content container-fluid">
        
            <BreadcrumbsReport
              title={staticmessages.REPORT_BALANCES}
              show={show}
              setShow={setShow}
              clickDownload = {clickDownload}
            />
            <ReportModal setShow={setShow} show={show}   filteroption = {filteroption} setFilterOptions ={setFilterOptions} 
            handleApplyFilter = {handleApplyFilter} handleApplyResetApply = {handleApplyResetApply}  />
            <div className="row">
              <div className="col-sm-12">
                <div className="card invoiceList">
                  <div className="card-body invoiceList">
                    <div className="table-responsive table-hover">
                      <Table
                      //  caption={
                      //   <div style={{ textAlign: 'center' }}>
                      //     Users who are <em>old</em>
                      //   </div>
                      // }

                      components={{ header: { table } }}
                        className="table"
                        pagination={{
                          total: datasource.length,
                          showTotal: (total, range) =>
                            `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                          showSizeChanger: false,
                        
                        }}
                        columns={columns}
                        dataSource={datasource}
                        rowKey={(record) => record.id}
                        onChange={handleTableChange}

                     
       
        summary = {() => (
          <>
            <tr>
            <th className=""  colSpan={columns.length} style={{ textAlign: 'center' }}>
            <span className="paymentfsizelabel">Total Gross Balance Amount :</span> <span className="paymentfsize message-error">{totalgrossbal.total}</span></th>
            </tr>
            
          </>
        )}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Balances;
