// eslint-disable-next-line no-unused-vars
import React, { useState , useEffect, useRef} from "react";
import { Link } from "react-router-dom";
import { search } from "../../_components/Imagepath";
import PropTypes from "prop-types";
import DatePicker from "react-datepicker";

// eslint-disable-next-line no-unused-vars
const ReportModal = ({ show, setShow , filteroption, setFilterOptions, handleApplyFilter, handleApplyResetApply}) => {
  ReportModal.propTypes = {
    show: PropTypes.any.isRequired, 
    setShow: PropTypes.func.isRequired,

    filteroption: PropTypes.object.isRequired,
    setFilterOptions: PropTypes.func.isRequired,
    handleApplyFilter: PropTypes.func.isRequired,
    handleApplyResetApply: PropTypes.func.isRequired,

  };


  useEffect(() => {
    console.log("filteroption----");
    console.log(filteroption);
    setFDate(filteroption.fdate);
    setTDate(filteroption.tdate);
 
}, [filteroption.searchterm, filteroption.fdate]);

const [startFDate, setFDate] = useState("");
const [startTDate, setTDate] = useState("");



const handleChangeTDate = (date) => {
  setTDate(date);
  setFilterOptions((prev) => ({
    ...prev,
    tdate: date,
  }));
};

const handleChangeFDate = (date) => {
  setFDate(date);
  setFilterOptions((prev) => ({
    ...prev,
    fdate: date,
  }));
};

  const handleChange = (e) => {
    const { name, value } = e.target;

    console.log(name+'----'+value);

    setFilterOptions((filteroption) => ({
      ...filteroption,
      [name]: value, // Use dynamic key
    }));
    //setDate(date);
  };

  const ResetApply = () => {
    handleApplyResetApply();
  };



  const handleApply = () => {
    handleApplyFilter();
  };

  const modalRef = useRef(null);
    // Close modal on outside click
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        setShow(false); // Close the modal
      }
    };

  useEffect(() => {
    if (show) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [show]);
  return (
    <div className={`toggle-sidebar ${show ? "open-filter" : ""}`}>
      <div className="sidebar-layout-filter" ref={modalRef}>
        <div className="sidebar-header">
          <h5>Filter</h5>
          <Link
            to="#"
            className="sidebar-closes"
            onClick={() => setShow(!show)}
          >
            <i className="fa-regular fa-circle-xmark" />
          </Link>
        </div>
        <div className="sidebar-body">
          <form action="#" autoComplete="off">
            {/* Customer */}
            <div className="accordion" id="accordionMain1">
              <div className="card-header-new" id="headingOne">
                <h6 className="filter-title">
                  <Link
                    to="#"
                    className="w-100"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseOne"
                    aria-expanded="true"
                    aria-controls="collapseOne"
                  >
                    Search
                    <span className="float-end">
                      <i className="fa-solid fa-chevron-down" />
                    </span>
                  </Link>
                </h6>
              </div>
              <div
                id="collapseOne"
                className="collapse show"
                aria-labelledby="headingOne"
                data-bs-parent="#accordionExample1"
              >
                <div className="card-body-chat">
                  <div className="row">
                    <div className="col-md-12">
                      <div id="checkBoxes1">
                        <div className="form-custom">
                          <input
                            type="text"
                            onChange={handleChange}
                            className="form-control"
                            id="searchterm"
                            placeholder="Search"
                            name="searchterm"
                            value={filteroption.searchterm}
                          />
                          <span>
                            <img src={search} alt="img" />
                          </span>
                        </div>
                        
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* /Customer */}

             {/* Select Date */}
             <div className="accordion accordion-last" id="accordionMain2">
              <div className="card-header-new" id="headingTwo">
                <h6 className="filter-title">
                  <a
                    href="#"
                    className="w-100 collapsed"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseTwo"
                    aria-expanded="true"
                    aria-controls="collapseTwo"
                  >
                    Select Date
                    <span className="float-end">
                      <i className="fa-solid fa-chevron-down" />
                    </span>
                  </a>
                </h6>
              </div>
              <div
                id="collapseTwo"
                className="collapse show"
                aria-labelledby="headingTwo "
                data-bs-parent="#accordionExample2"
              >
                <div className="card-body-chat">
                  <div className="input-block mb-3">
                    <label className="form-control-label">From</label>
                    <div className="cal-icon">
                      <DatePicker
                       
                        onChange={handleChangeFDate}
                        className="form-control datetimepicker"
                         dateFormat="dd/MM/yyyy"
                         id="fdate"
                         name="fdate"
                         selected={startFDate}
                      />
                    </div>
                  </div>
                  <div className="input-block mb-3">
                    <label className="form-control-label">To</label>
                    <div className="cal-icon">
                      <DatePicker
                    
                        onChange={handleChangeTDate}
                        className="form-control datetimepicker"
                         dateFormat="dd/MM/yyyy"
                          id="tdate"
                         name="tdate"
                         selected={startTDate}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* /Select Date */}

             
            <div className="filter-buttons">
              <button
                type="button"
                onClick={handleApply}
                className="d-inline-flex align-items-center justify-content-center btn w-100 btn-primary"
              >
                Apply
              </button>
              <button
                type="button"
                className="d-inline-flex align-items-center justify-content-center btn w-100 btn-primary"
                onClick={ResetApply}
              >
                Reset
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ReportModal;
