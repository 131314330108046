 
import React, { useState } from "react";
import SettingsSidebar from "../layouts/SettingsSidebar";
import Header from "../layouts/Header";
import Sidebar from "../layouts/Sidebar";
//import AuthService from "../services/auth.service";
import UserService from "../services/user.service";

import Modal from 'react-bootstrap/Modal';
//import Button from 'react-bootstrap/Button';

//import * as yup from "yup";
//import alertify from "alertifyjs";
import "../../node_modules/alertifyjs/build/css/alertify.css";
import "../../node_modules/alertifyjs/build/css/themes/semantic.css";

import { useHistory } from "react-router-dom";

const DeleteAccounts = () => {

  const history = useHistory();


  const [menu, setMenu] = useState(false);

  const toggleMobileMenu = () => {
    setMenu(!menu);
  };


  const [showDel, setShowDel] = useState(false);
  const [showOtp, setShowOtp] = useState(false);
  const [otp, setOtp] = useState("");

  const [message, setErrorMessage] = useState("");

  const handleDelete = () => {
   
    UserService.deleteAccount()
    .then((response) => {
     
      if(response.data.mess == "success")
        {
          handleConfirmDelete();
        }
      
      console.log(response.data.mess);
    })
    .catch(() => {
     
    });
     
  };

  const handleConfirmDelete = () => {
    console.log("handleConfirmDelete 11");
    // Trigger the OTP modal
   // setShowDel(false); // Close the delete confirmation modal
    setShowOtp(true);
  };



  const handleOTPMatchDelete = () => {
    setErrorMessage("");
    console.log(otp);
    UserService.deleteAccountSendConMail(otp)
    .then((response) => {
     
        if(response.data.mess == "success")
        {
          setShowOtp(false);
          setShowDel(false);
            
            localStorage.removeItem("token");
            localStorage.removeItem("user");
            // Redirect to login page
            history.push("/login");
        }
      
      
    })
    .catch((error) => {
      if (error.response && error.response.data && error.response.data.errorMessage) {
        setErrorMessage(error.response.data.errorMessage);
      } else if (error.message) {
        setErrorMessage(error.message);
      } else {
        setErrorMessage("An unexpected error occurred.");
      }
      console.error(error);
    });
     
  };

  const forceMenuClose = () => {  
    setMenu(false);
  };

  return (
    <>
    <div className={`main-wrapper ${menu ? "slide-nav" : ""}`}>
      <Header onMenuClick={() => toggleMobileMenu()}  forceMenuClose={forceMenuClose}/>
      <Sidebar />
  
      <div className="page-wrapper">
        <div className="content container-fluid">
          {/* Page Header */}
          <div className="page-header">
            <div className="content-page-header">
              <h5>Settings</h5>
            </div>
          </div>
          {/* /Page Header */}
          <div className="row">
            <div className="col-xl-3 col-md-4">
              <SettingsSidebar />
            </div>
            <div className="col-xl-9 col-md-8">
              <div className="card">
                <div className="card-header">
                  <h5 className="card-title">Delete your account</h5>
                </div>
                <div className="card-body">
                  {/* Form */}
                  <form>
                    <p className="card-text">
                      When you delete your account, you lose access to Bill Wizer
                      account services, and we permanently delete your personal
                      data.
                    </p>
                    <p className="card-text">
                      Are you sure you want to close your account?
                    </p>
                    <div className="input-block mb-3 p-3">
                      <div className="custom-control custom-checkbox ">
                        <input
                          type="checkbox"
                          className="custom-control-input"
                          id="delete_account"
                        />
                        <label
                          className="custom-control-label text-danger"
                          htmlFor="delete_account"
                        >
                            &nbsp;Confirm that I want to delete my account.
                        </label>
                      </div>
                    </div>
                    <div className="text-end">
                     
                    <button
                    className="btn btn-primary paid-continue-btn w-100"
                    onClick={handleDelete}
                    type="button"
                  >
                     <i className="far fa-trash-alt me-2" /> Delete
                  </button>
                     
                    </div>
                  </form>
                  {/* /Form */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>





                 {/* Delete Confirmation Modal */}
      <Modal show={showDel} onHide={() => setShowDel(false)}>
        <Modal.Body>
          <div className="modal-header border-0 justify-content-center pb-0">
            <div className="form-header modal-header-title text-center mb-0">
              <h4 className="mb-2">Delete Account</h4>
              <p>Are you sure you want to delete your account?</p>
            </div>
          </div>
          <div className="modal-body">
            <div className="modal-btn delete-action">
              <div className="row">
                <div className="col-6">
                  <button
                    className="btn btn-primary paid-continue-btn w-100"
                    onClick={handleConfirmDelete}
                  >
                    Delete
                  </button>
                </div>
                <div className="col-6">
                  <button
                    className="btn btn-primary w-100"
                    onClick={() => setShowDel(false)}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>


 {/* OTP Modal */}
 <Modal show={showOtp} onHide={() => setShowOtp(false)}>
        <Modal.Body>
          <div className="modal-header border-0 justify-content-center pb-0">
            <div className="form-header modal-header-title text-center mb-0">
              <h4 className="mb-2">Enter OTP</h4>
              <p>We&apos;ve sent an OTP to your registered email/phone.</p>
            </div>
          </div>
          <div className="modal-body">
            <div className="form-group">
              <input
                type="text"
                className="form-control"
                placeholder="Enter OTP"
                value={otp}
                onChange={(e) => setOtp(e.target.value)}
              />
            </div>

            <small className="error">{message}</small>
            <div className="modal-btn delete-action mt-3  ">
              <div className="row">
                <div className="col-6">
                  <button
                    className="btn btn-primary w-100"
                    onClick={handleOTPMatchDelete}
                  >
                    Confirm
                  </button>
                </div>
                <div className="col-6">
                  <button
                    className="btn btn-primary w-100"
                    onClick={() => setShowOtp(false)}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>


           

</>

  );
};

export default DeleteAccounts;
