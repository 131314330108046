import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import Header from "../layouts/Header";
import Sidebar from "../layouts/Sidebar";

import "../_components/antd.css";
import { Table, Tooltip } from "antd";

import AddVendor from "../customers/vendors/addVendor";
import { filterIcon } from "../_components/Imagepath";
import moment from "moment";
import staticmessages from '../services/staticmessages';
import ClientService from '../services/client.service';
import AuthService from "../services/auth.service";
import Spinners from '../components/spinners';
const Customers = () => {
  const history = useHistory();
 // eslint-disable-next-line no-unused-vars
  const [loading, setLoading] = useState(true); // Loading state
    // eslint-disable-next-line no-unused-vars
    const [page, setPage] = useState(1);
    // eslint-disable-next-line no-unused-vars
    const paginationSize = 10;
  
  const [menu, setMenu] = useState(false);
  const [show, setShow] = useState(false);
  const [deleteact, setDeleteAct] = useState(null);
  const [datasource, setClients] = useState([]);

  const [filteredDataSource, setFilteredDataSource] = useState([]);

  // eslint-disable-next-line no-unused-vars
  const [searchTerm, setSearchTerm] = useState("");
  useEffect(() => {
    getUser();
    getClients();
  }, []);

  const toggleMobileMenu = () => {
    setMenu(!menu);
  };


  const handleApplyFilter = (searchTerm) => {
    console.log(searchTerm);
    setSearchTerm(searchTerm);
    filterData(searchTerm);
  };

  const filterData = (searchTerm) => {
    const filteredData = datasource.filter((record) =>
      record.client_name.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredDataSource(filteredData);
  };





  const getClients = () => {
    ClientService.getClients()
      .then((response) => {
        setClients(response.data);
        setFilteredDataSource(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  const getUser = async () => {
    try {
      let res = await AuthService.getCurrentUser();
      console.log("getCurrentUser", res);
    } catch (error) {
      console.error('Login failed:', error);
    }
  };

  const setDelete = (id) => {
    if (id) {
      setDeleteAct(id);
    }
  };

  const handleDelete = (id) => {
    if (id) {
      ClientService.deleteClient(id)
        .then(() => {
          getClients();
        })
        .catch((error) => {
          console.error('Login failed:', error);
        });
    }
  };

  const handleEdit = (client) => {
    if (client && client.id) {
      history.push(`/edit-customer`, { client });
    } else {
      history.push(`/add-customer`, { client: {} });
    }
  };

  
  const handleViewLedger = (customer) => {
    console.log("handleParentViewLedger 2222");
    console.log(customer);

    history.push(`/report-ledger`, { customer });
  };


  const handleTableChange = (pagination) => {
    setPage(pagination.current);
  };

  const columns = [
    {
      title: "#",
      dataIndex: "id",
      render: (text, record, index) => (page - 1) * paginationSize + index + 1,
    },
    {
      title: "Name",
      dataIndex: "client_name",
      render: (text, record) => (
      
         <button className="btn btn-link text-center" onClick={() => handleEdit(record)}>
          {record.client_name}<br />
            
           <span>{record.email}</span>
          </button>
       
      ),
      sorter: (a, b) => a.client_name.length - b.client_name.length,
    },
    {
      title: "Phone",
      dataIndex: "contact_number",
     
    },

    {
      title: "City",
      dataIndex: "city",
      sorter: (a, b) => a.city.length - b.city.length,
    },
    {
      title: "State",
      dataIndex: "state",
      sorter: (a, b) => a.state.length - b.state.length,
    },
    {
      title: "Created",
      dataIndex: 'created', // Ensure the dataIndex matches the field name in your data
      key: 'date',
      render: (text) => moment(text).format("DD-MM-YYYY"),
      sorter: (a, b) => moment(a.created).unix() - moment(b.created).unix(),
    },

  
    {
      title: "Action",
      dataIndex: "Action",
      render: (text, record) => (
        <div className="table-invoice d-flex align-items-center">
         
         
      

          <button  className="btn btn-greys btn-blue me-2"  onClick={() => handleViewLedger(record)}>
            <i className="fa-regular fa-eye me-1" /> Ledger
          </button>



          <div className="dropdown dropdown-action">
            <Link to="#" className=" btn-action-icon " data-bs-toggle="dropdown" aria-expanded="false">
              <i className="fas fa-ellipsis-v" />
            </Link>
            <div className="dropdown-menu dropdown-menu-end">
              <ul>
                <li>
                  <button className="dropdown-item" onClick={() => handleEdit(record)}>
                    <i className="far fa-edit me-2" />
                    Edit
                  </button>
                </li>
                <li>
                  <Link className="dropdown-item" to="#" data-bs-toggle="modal" data-bs-target="#delete_modal" onClick={() => setDelete(record.id)}>
                    <i className="far fa-trash-alt me-2" />
                    Delete
                  </Link>
                </li>
                {/* <li>
                  <Link className="dropdown-item" to="/customer-details">
                    <i className="far fa-eye me-2" />
                    View
                  </Link>
                </li> */}
              </ul>
            </div>
          </div>
        </div>
      ),
    },
  ];

  const forceMenuClose = () => {  
    setMenu(false);
  };

  if (loading) {
    return <Spinners />; // Display the spinner while loading
  }

  return (
    <div className={`main-wrapper ${menu ? "slide-nav" : ""}`}>
       <Header onMenuClick={() => toggleMobileMenu()}   onApplyFilter={handleApplyFilter} searchTerm = {searchTerm} forceMenuClose={forceMenuClose}/>
      <Sidebar />
      <div className="page-wrapper customers">
        <div className="content container-fluid">
          <div className="page-header">
            <div className="content-page-header">
              <h5>{staticmessages.CUSTOMER_LABEL_TITLE}</h5>
              <div className="list-btn">
                <ul className="filter-list">
                  <li>
                    <Link
                      to="#"
                      className="btn btn-filters w-auto popup-toggle me-2"
                      onClick={() => setShow(!show)}
                    >
                      <span className="me-2">
                        <Tooltip title="Filter" placement="bottom">
                          <img src={filterIcon} alt="filtericon" />
                        </Tooltip>
                      </span>
                      Filter{" "}
                    </Link>
                  </li>
               <li>
                    <button className="btn btn-primary" onClick={() => handleEdit()}>
                      <i className="fa fa-plus-circle me-2" aria-hidden="true" />
                      Add {staticmessages.CUSTOMER_LABEL_CLIENT}
                    </button>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12">
              <div className="card customers">
                <div className="card-body">
                  <div className="table-responsive table-hover">
                    <Table
                      className="table"
                      pagination={{
                        total: filteredDataSource.length,
                        showTotal: (total, range) =>
                          `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                        showSizeChanger: false,
                      
                      }}
                      columns={columns}
                      dataSource={filteredDataSource}
                      rowKey={(record) => record.id}
                      onChange={handleTableChange}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <AddVendor setShow={setShow} show={show} onApplyFilter={handleApplyFilter}  title="Customer"  />
      <div className="modal custom-modal fade" id="delete_modal" role="dialog">
        <div className="modal-dialog modal-dialog-centered modal-md">
          <div className="modal-content">
            <div className="modal-body">
              <div className="form-header">
                <h3>Delete Customer</h3>
                <p>Are you sure you want to delete?</p>
              </div>
              <div className="modal-btn delete-action">
                <div className="row">
                  <div className="col-6">
                    <button
                      type="reset"
                      data-bs-dismiss="modal"
                      className="w-100 btn btn-primary paid-continue-btn"
                      onClick={() => handleDelete(deleteact)}
                    >
                      Delete
                    </button>
                  </div>
                  <div className="col-6">
                    <button
                      type="submit"
                      data-bs-dismiss="modal"
                      className="w-100 btn btn-primary "
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Customers;
