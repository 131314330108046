import React, { useState, useEffect } from "react";
import { Link, useLocation, useHistory } from "react-router-dom";
import { Scrollbars } from "react-custom-scrollbars-2";
import staticmessages from '../services/staticmessages';


const Sidebar = () => {
  const [selectedItems, setSelectedItems] = useState(Array().fill(false));
  const { pathname } = useLocation();
  // eslint-disable-next-line no-unused-vars
  const handleItemClick = (index) => {
    setSelectedItems((prevSelectedItems) => {
      const updatedSelectedItems = [...prevSelectedItems];
      console.log("selectedItems", selectedItems);
      // Close the previously opened item
      updatedSelectedItems.forEach((item, i) => {
        if (i !== index) {
          updatedSelectedItems[i] = false;
        }
      });

      // Toggle the state of the clicked item
      updatedSelectedItems[index] = !updatedSelectedItems[index];

      return updatedSelectedItems;
    });
  };


  useEffect(() => {
    function handleMouseOver(e) {
      e.stopPropagation();
      if (
        document.body.classList.contains("mini-sidebar") &&
        document.querySelector("#toggle_btn").offsetParent !== null
      ) {
        var targ = e.target.closest(".sidebar");
        if (targ) {
          document.body.classList.add("expand-menu");
          document
            .querySelectorAll(".subdrop + ul")
            .forEach((ul) => (ul.style.display = "block"));
        } else {
          document.body.classList.remove("expand-menu");
          document
            .querySelectorAll(".subdrop + ul")
            .forEach((ul) => (ul.style.display = "none"));
        }
        return false;
      }
    }

    document.addEventListener("mouseover", handleMouseOver);

    return () => {
      document.removeEventListener("mouseover", handleMouseOver);
    };
  }, []);


  const history = useHistory();
  const Logout = () => {
  
  
      // Clear token from localStorage
      localStorage.removeItem("token");
      localStorage.removeItem("user");
      // Redirect to login page
      history.push("/login");
   
  };

  
  return (
    <>
      <div className="sidebar" id="sidebar">
        <Scrollbars>
          <div className="sidebar-inner slimscroll">
            <div id="sidebar-menu" className="sidebar-menu one">
              <ul className="sidebar-vertical">
                {/* Main */}
                <li className="menu-title">
                  <span>Main</span>
                </li>
               

                <li>
                <Link
                        to="/index"
                        className={pathname === "/index" ? "active" : ""}
                      > <i className="fe fe-home" /> <span> Dashboard</span>{" "}
                    <span className="menu-arrow" />
                      </Link>
                </li>


   {/* /Clients */}
   <li className="menu-title">
                  <span>Sales</span>
                </li>
                <li>
                  <Link to="/invoice-list" className={pathname === "/invoice-list" ? "active" : ""}>
                    <i className="fe fe-file" /> <span>{staticmessages.INVOICE_LABEL_TITLE}</span>
                  </Link>
                </li>
                <li>
                  <Link to="/estimate-list" className={pathname === "/estimate-list" ? "active" : ""}>
                    <i className="fa fa-square" /> <span>{staticmessages.ESTIMATE_LABEL_TITLE}</span>
                  </Link>
                </li>
                
                
                  {/* /Clients */}
                <li className="menu-title">
                  <span>Clients</span>
                </li>
                <li>
                  <Link to="/customers" className={pathname === "/customers" ? "active" : ""}>
                    <i className="fe fe-users" /> <span>{staticmessages.CUSTOMER_LABEL_TITLE}</span>
                  </Link>
                </li>


                  {/* Inventory */}
                  <li className="menu-title">
                  <span>Items</span>
                </li>
               
                    <li>
                    <Link to="/product-list" className={pathname === "/product-list" ? "active" : ""}>
                    <i className="fe fe-package" /> <span>{staticmessages.ITEMS_LABEL_TITLE}</span>
                    </Link>
                    </li>
                    <li><Link to="/category" className={pathname === "/category" ? "active" : ""}>
                    <i className="fe fe-box" /> <span>Category</span>
                  </Link>
                    </li>
                    <li>
                    <Link to="/units" className={pathname === "/units" ? "active" : ""}>
                    <i className="fe fe-file-text" /> <span>{staticmessages.UNIT_LABEL_TITLE}</span></Link>
                    </li>
                 
               
             
                {/* /Inventory */}
             
              
            
        
       
               
                
              
                
                {/* Reports */}
                <li className="menu-title">
                  <span>Reports</span>
                </li>

              
                <li>
                  <Link to="/invoice-summary" className={pathname === "/invoice-summary" ? "active" : ""}>
                    <i className="fe fe-file" /> <span>{staticmessages.REPORT_INVSUMMARY}</span>
                  </Link>
                </li>
                <li>
                <Link to="/invoice-detail" className={pathname === "/invoice-detail" ? "active" : ""}>
                    <i className="fe fe-clipboard" /> <span>{staticmessages.REPORT_INVDETAIL}</span>
                  </Link>
                </li>
                <li>
                <Link to="/balances" className={pathname === "/balances" ? "active" : ""}>
                    <i className="fa fa-th-list" /> <span>{staticmessages.REPORT_BALANCES}</span>
                  </Link>
                </li>
                <li>
                <Link to="/payments-received" className={pathname === "/payments-received" ? "active" : ""}>
                    <i className="fa fa-credit-card" /> <span>{staticmessages.REPORT_PAYMENTRECIVED}</span>
                  </Link>
                </li>
                <li>
                <Link to="/twentySixAS" className={pathname === "/twentySixAS" ? "active" : ""}>
                    <i className="fe fe-file" /> <span>{staticmessages.REPORT_TSIXAS}</span>
                  </Link>
                </li>
                <li>
                <Link to="/client-ledeger" className={pathname === "/client-ledeger" ? "active" : ""}>
                    <i className="fa fa-th-list" /> <span>{staticmessages.REPORT_CLIENTLEDEGER}</span>
                  </Link>
                </li>
                <li>
                <Link to="/tax-register" className={pathname === "/tax-register" ? "active" : ""}>
                    <i className="fa fa-align-left" /> <span>{staticmessages.REPORT_TAXREGISTER}</span>
                  </Link>
                </li>

                <li>
                <Link to="/tax-register-detail" className={pathname === "/tax-register-detail" ? "active" : ""}>
                    <i className="fa fa-align-justify" /> <span>{staticmessages.REPORT_TAXREGISTERDETAIL}</span>
                  </Link>
                </li>

                <li>
                <Link to="/expiring-services" className={pathname === "/expiring-services" ? "active" : ""}>
                    <i className="fa fa-eraser" /> <span>{staticmessages.REPORT_EXPIRINGSERVICES}</span>
                  </Link>
                </li>



                
                {/* /Reports */}
        
              
                
                
                
               
               
                
                
                {/* Settings */}
                <li className="menu-title">
                  <span>Settings</span>
                </li>
                <li>
                  <Link className={pathname === "/settings" ? "active" : ""} to="/settings">
                    <i className="fe fe-settings" /> <span>Settings</span>
                  </Link>
                </li>

                <li>
                  <Link className={pathname === "/contactus" ? "active" : ""} to="/contactus">
                    <i className="fe fe-user" /> <span>Support</span>
                  </Link>
                </li>
                <li>
                  <Link className={pathname === "/login" ? "active" : ""} to="/#" onClick={Logout}> 
                    <i className="fe fe-power" /> <span>Logout</span>
                  </Link>
                </li>
              
               
               
                
              </ul>
            </div>
          </div>
        </Scrollbars>
      </div>
    </>
  );
};
export default Sidebar;