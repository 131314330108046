import React, { useState ,useEffect } from "react";
import Header from "../layouts/Header";

// eslint-disable-next-line no-unused-vars
import Sidebar from "../layouts/Sidebar";
  // eslint-disable-next-line no-unused-vars
import ReportModal from "../common/modal/reportModal";
// eslint-disable-next-line no-unused-vars
import BreadcrumbsReport from "../common/breadcrumb/breadCrumbsReport";
import { Table } from "antd";

  // eslint-disable-next-line no-unused-vars
import { Link } from "react-router-dom";

// eslint-disable-next-line no-unused-vars
import {formatDateISO, getCurrentDateISO, getExtendDateISO} from '../services/Helper';



  // eslint-disable-next-line no-unused-vars
import report from '../services/reports.service';
  // eslint-disable-next-line no-unused-vars
import staticmessages from '../services/staticmessages';

  // eslint-disable-next-line no-unused-vars
  import Spinners from '../components/spinners';

const InvoiceSummaryDetails = () => {
  const [menu, setMenu] = useState(false);
  const [show, setShow] = useState(false);
  const [page, setPage] = useState(1);
  // eslint-disable-next-line no-unused-vars
const paginationSize = 10;

    // eslint-disable-next-line no-unused-vars
  const [datasource, setInvoices] = useState([]);
    // eslint-disable-next-line no-unused-vars
  const [filteredDataSource, setFilteredDataSource] = useState([]);

    // eslint-disable-next-line no-unused-vars
  const [listType, setListType] = useState("invoicelist");

  //       <ReportModal setShow={setShow} show={show} customtitle={customtitle} listType = {listType} fdate = {fdate} tdate = {tdate} />

    // eslint-disable-next-line no-unused-vars

    let currentdate =   new Date();
    let currentdateex =  new Date(currentdate.getTime() - (30 * 24 * 60 * 60 * 1000));

  const [filteroption, setFilterOptions] = useState({searchterm: "",fdate: currentdateex,tdate:currentdate});

  // eslint-disable-next-line no-unused-vars
const [loading, setLoading] = useState(true); // Loading state


  const toggleMobileMenu = () => {
    setMenu(!menu);
  };



  useEffect(() => {
    
      getInvoiceSummaryDetails();
   
  }, [filteroption]);

  const getInvoiceSummaryDetails = async () => {

   

    report.getInvoiceSummaryDetailsReport(listType, filteroption)
      .then((response) => {
        setInvoices(response.data);
        setFilteredDataSource(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  const handleApplyFilter = (searchTerm) => {
    setShow(false);
    console.log(searchTerm);
    setSearchTerm(searchTerm);
    filterData(searchTerm);
  };


  
  const [forecereset, setForecereset] = useState(false);
  const handleApplyResetApply = () => {

    setFilterOptions((prev) => ({
      ...prev,
      searchterm: "",fdate: currentdateex,tdate:currentdate
    }));

   
    setShow(false);
   
    setForecereset(true);
  };

  useEffect(() => {
    if (forecereset) {
     
      getInvoiceSummaryDetails(); // Reload parent data
      setForecereset(false);
    }
  }, [forecereset]);




  const handleTableChange = (pagination) => {
    setPage(pagination.current);
  };
  const columns = [
    {
      title: "#",
      dataIndex: "id",
      render: (text, record, index) => (page - 1) * paginationSize + index + 1,
    },
   
    {
      title: "Invoice No.",
      dataIndex: "invoice_number",
      render: (text, record) => (
        <>
          <span>{record.invoice_number}</span>
        </>
      ),
      sorter: (a, b) => {
        // Make sure both a and b have client_name defined
        if (!a.invoice_number) return 1;
        if (!b.invoice_number) return -1;
        
        // Handle sorting based on client_name, assuming it is a string
        return a.invoice_number.localeCompare(b.invoice_number);
      },
    },
   
    {
      title: "Category",
      dataIndex: "item_name",
      sorter: (a, b) => a.item_name.length - b.item_name.length,
    },
    {
      title: "Price",
      dataIndex: "datetimestamp",
      render: (text, record) => (
        <>
         
         <span>{record.symbol}{record.price}</span><br />
         <span>{record.price} X {record.quantity}</span>

            
        </> ),
      sorter: (a, b) => a.datetimestamp.length - b.datetimestamp.length,
    },
    {
      title: "Date",
      dataIndex: "datetimestamp",
      render: (text, record) => (
        <>
         
         <span>{formatDateISO(record.datetimestamp)}</span>

            
        </> ),
      sorter: (a, b) => a.datetimestamp.length - b.datetimestamp.length,
    },
    {
      title: "Client",
      dataIndex: "client_name",
      render: (text, record) => (
        <>
          <span>{record.client_name}</span>
        </>
      ),
      sorter: (a, b) => {
        // Make sure both a and b have client_name defined
        if (!a.client_name) return 1;
        if (!b.client_name) return -1;
        
        // Handle sorting based on client_name, assuming it is a string
        return a.client_name.localeCompare(b.client_name);
      },
    },
    {
      title: "Amount",
      dataIndex: "gtotal",
      render: (text, record) => (
        <>
         
              <span> {record.symbol}{record.gtotal}</span>
            
        </>
         ),
      sorter: (a, b) => a.gtotal - b.gtotal,
    },

   
  ];


  const downloadCSV = (data, filename = 'InvoiceDetailReport.csv') => {
    //const csv = convertToCSV(data);
    const blob = new Blob([data], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    const url = URL.createObjectURL(blob);
    link.setAttribute('href', url);
    link.setAttribute('download', filename);
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const clickDownload = () => {

    report.getReportDownloadCombine(listType, filteroption, "export","browser","salesdetailexport")
      .then((response) => {
        // setInvoices(response.data);
        // setFilteredDataSource(response.data);

       downloadCSV(response);

        console.log(response);

       

      })
      .catch((error) => {
        console.log(error);
      });
  
  };
    // eslint-disable-next-line no-unused-vars
    const [searchTerm, setSearchTerm] = useState(""); 

    const filterData = (searchTerm) => {

      if (searchTerm) {
        const filteredData = datasource.filter((record) => {
          const clientNameMatch = record.client_name.toLowerCase().includes(searchTerm.toLowerCase());
          const invoiceNoMatch = record.invoice_number.toString().toLowerCase().includes(searchTerm.toLowerCase());
          return clientNameMatch || invoiceNoMatch;
        });
        setFilteredDataSource(filteredData);  // Set the filtered data
      } else {
        setFilteredDataSource(datasource);  // Reset to original data if no search term
      }
      
  };

  const forceMenuClose = () => {  
    setMenu(false);
  };
  
  if (loading) {
    return <Spinners />; // Display the spinner while loading
  }
 
  return (
    <>
      <div className={`main-wrapper ${menu ? "slide-nav" : ""}`}>
      <Header onMenuClick={() => toggleMobileMenu()} forceMenuClose={forceMenuClose} onApplyFilter={handleApplyFilter} searchTerm = {searchTerm} />
       
        <Sidebar />
        <div className="page-wrapper">
          <div className="content container-fluid">
        
            <BreadcrumbsReport
              title={staticmessages.REPORT_INVDETAIL}
              show={show}
              setShow={setShow}
              clickDownload = {clickDownload}
            />
            <ReportModal setShow={setShow} show={show}   filteroption = {filteroption} setFilterOptions ={setFilterOptions} 
            handleApplyFilter = {handleApplyFilter} handleApplyResetApply = {handleApplyResetApply}  />
            <div className="row">
              <div className="col-sm-12">
                <div className="card invoiceList">
                  <div className="card-body invoiceList">
                    <div className="table-responsive table-hover">
                    <Table
                      className="table"
                      pagination={{
                        total: filteredDataSource.length,
                        showTotal: (total, range) =>
                          `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                        showSizeChanger: false,
                      
                      }}
                      columns={columns}
                      dataSource={filteredDataSource}
                      rowKey={(record) => record.id}
                      onChange={handleTableChange}
                    />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default InvoiceSummaryDetails;
