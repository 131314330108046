import React from "react";
import FeatherIcon from "feather-icons-react";
import { Link } from "react-router-dom";

import { Tooltip } from "antd";
import PropTypes from "prop-types";
import staticmessages from '../../services/staticmessages';
import { useHistory, useLocation } from 'react-router-dom';
 // eslint-disable-next-line no-unused-vars
const CreditHead = ({ show, setShow , selectedinvoice, handleParentViewLedger}) => {
  CreditHead.propTypes = {
    show: PropTypes.any.isRequired,
    setShow: PropTypes.func.isRequired,
    selectedinvoice: PropTypes.object.isRequired,
    handleParentViewLedger: PropTypes.func.isRequired,
  };

    // eslint-disable-next-line no-unused-vars
    const history = useHistory();
    // eslint-disable-next-line no-unused-vars
    const location = useLocation();




  
  const viewLegerAct =  () => {
    console.log("ViewLedger 10101");
    console.log(selectedinvoice);
    handleParentViewLedger();
  };
 


  return (
    <>
      <div className="page-header">
        <div className="content-page-header">
        <h5>{staticmessages.INVOICE_CLIENTLEDGER} : {selectedinvoice && selectedinvoice.client_name}</h5>

     
          <div className="list-btn">
            <ul className="filter-list">
          
             
              <li>
                 <Link className="btn-filters" to="/settings">
                  <span>
                  <Tooltip title="Settings" placement="bottom">
                    <FeatherIcon icon="settings" />
                    </Tooltip>
                  </span>
                </Link>
              </li>

              <li>

              <button className="btn btn-primary"  onClick={viewLegerAct}>
                  <i className="fa fa-plus-circle me-2" aria-hidden="true" />
                  View Ledger
                </button>
             
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div id="filter_inputs" className="card filter-card">
        <div className="card-body pb-0">
          <div className="row">
            <div className="col-sm-6 col-md-3">
              <div className="input-block mb-3">
                <label>Name</label>
                <input type="text" className="form-control" />
              </div>
            </div>
            <div className="col-sm-6 col-md-3">
              <div className="input-block mb-3">
                <label>Email</label>
                <input type="text" className="form-control" />
              </div>
            </div>
            <div className="col-sm-6 col-md-3">
              <div className="input-block mb-3">
                <label>Phone</label>
                <input type="text" className="form-control" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CreditHead;
