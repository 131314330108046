// services/invoice.service.js
import axios from 'axios';
import AuthService from "../services/auth.service";

import config from './config';
import {formatDateISONEW} from "../services/Helper";
const API_URL = config.API_URL;

const currentUser = AuthService.getCurrentUser();

const getInvoices = async (listType, filteroption) => {
  try {
    const currentUser = await AuthService.getCurrentUser();
    if (!currentUser || !currentUser.apptoken) {
      AuthService.setCurrentUser(currentUser);
      throw new Error("User not authenticated or missing apptoken.");
    }

    return axios
      .post(API_URL + "customerInvoices", {
        apptoken: currentUser.apptoken, // Ensure the payload is correct
        linkModeType: listType,

        is_export: false,
        client_id: filteroption.client_id,
        filterText: filteroption.searchterm,
        fdate: formatDateISONEW(filteroption.fdate),
        tdate: formatDateISONEW(filteroption.tdate),

      })
      .then((response) => {
        if (response.data.errorMessage) {
          throw new Error(response.data.errorMessage);
        }

        if (response.data.data && response.data.data.username) {
          localStorage.setItem("user", JSON.stringify(response.data.data));
        } else {
          console.warn("No username found in response data.");
        }

        return response.data;
      });
  } catch (error) {
    console.error("Error fetching invoices:", error);
    throw error;
  }
};



const deleteinvoice = (id,uniqueno,linkModeType) => {
  return axios
  .post(API_URL + "deleteinvoice", {
    apptoken: currentUser.apptoken, // Ensure the payload is correct
    invoiceid : id,
    uniqueno : uniqueno,
    linkModeType : linkModeType,
  })
  .then((response) => {
    console.log("response.data.deleteinvoice")
    console.log(response.data.data)

    if (response.data.errorMessage) {
      throw new Error(response.data.errorMessage);
    }

   

    return response.data;
  });

};



const loadInvoiceNo = (invoiceid, invoice_edit, linkModeType) => {
  return axios
  .post(API_URL + "getcustomerinvoiceedit", {
    apptoken: currentUser.apptoken, // Ensure the payload is correct
    invoiceid: invoiceid,
    invoice_edit: invoice_edit,
     linkModeType: linkModeType,
  
  })
  .then((response) => {
    console.log("response.data.getcustomerinvoiceedit")
    console.log(response.data.data)

    if (response.data.errorMessage) {
      throw new Error(response.data.errorMessage);
    }


    return response.data;
  });

};
const getUserProfile = () => {
  return axios
  .post(API_URL + "getBusinessDetails", {
    apptoken: currentUser.apptoken, // Ensure the payload is correct
    
  })
  .then((response) => {
    console.log("response.data.getUserProfile")
    console.log(response.data.data)

    if (response.data.errorMessage) {
      throw new Error(response.data.errorMessage);
    }

    return response.data;
  });

};


const updateInvoice = (id, client, listType) => {
  return axios
  .post(API_URL + "updateInvoice", {
    apptoken: currentUser.apptoken, // Ensure the payload is correct  addCustomerData
    linkModeType: listType, // Ensure the payload is correct  addCustomerData
    ...client
  })
  .then((response) => {
    console.log("response.data.updateInvoice")
    console.log(response.data.data)

    if (response.data.errorMessage) {
      throw new Error(response.data.errorMessage);
    }

    return response.data;
  });

};

const addnewInvoice = (id, client, listType) => {
  return axios
  .post(API_URL + "addnewInvoice", {
    apptoken: currentUser.apptoken, // Ensure the payload is correct  addCustomerData
    linkModeType: listType, // Ensure the payload is correct  addCustomerData
    ...client
  })
  .then((response) => {
    console.log("response.data.addnewInvoice")
    console.log(response.data.data)

    if (response.data.errorMessage) {
      throw new Error(response.data.errorMessage);
    }

    return response.data;
  });

};

const addnewInvoiceWebFormWiz = (id, client, listType) => {
  return axios
  .post(API_URL + "addnewInvoiceWebFormWiz", {
    apptoken: currentUser.apptoken, // Ensure the payload is correct  addCustomerData
    linkModeType: listType, // Ensure the payload is correct  addCustomerData
    ...client
  })
  .then((response) => {
    console.log("response.data.addnewInvoice")
    console.log(response.data.data)

    if (response.data.errorMessage) {
      throw new Error(response.data.errorMessage);
    }

    return response.data;
  });

};



const getDefaultTax = () => {
  return axios
  .post(API_URL + "getCustomerTaxMasters", {
    apptoken: currentUser.apptoken, // Ensure the payload is correct
   
  })
  .then((response) => {
    console.log("response.data.getDefaultTax")
    console.log(response.data.data)

    if (response.data.errorMessage) {
      throw new Error(response.data.errorMessage);
    }


    return response.data;
  });

};



const handleOpenPdfInBrowser = async (url) => {
  try {
    const response = await axios.get(url, {
      responseType: 'blob', // Ensure the response is treated as a blob
    });

    const file = new Blob([response.data], { type: 'application/pdf' }); // Explicitly set the content type to PDF
    const fileUrl = window.URL.createObjectURL(file); // Create the object URL


     // Detect if the user is on Safari (especially on mobile)
     const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

     if (isSafari && /iPhone|iPad|iPod/i.test(navigator.userAgent)) {
       // For Safari on iOS: use window.location.href as a workaround
       window.location.href = fileUrl;
     } else {
       // For other browsers: open in a new tab
       window.open(fileUrl);
     }

    
    // Open the PDF in a new tab
   
  } catch (error) {
    console.error('Error opening the PDF', error);
  }
};


  // eslint-disable-next-line no-unused-vars
  const handleDownloadPdf = async (url) => {
    try {
      const response = await axios.get(url, {
        responseType: 'blob',
      });
  
      const downloadUrl = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = downloadUrl;
      link.setAttribute('download', 'downloaded.pdf');
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error('Error downloading the PDF', error);
    }
  };


   // eslint-disable-next-line no-unused-vars
   const handlePrintPdfInvoiceEST = async (type, listType, selectedinvoice) => {
    console.log(selectedinvoice);
  
    try {
      let res = await getUserProfile();
      console.log("getCurrentUser", res);
  
      const id = selectedinvoice.id; // Assuming you have an id in selectedinvoice
      const template = res.data.invoice_template; // Replace with the actual template
  
      let pdfgenurl = `${API_URL}generatepdf?apptoken=${currentUser.apptoken}&id=${id}&uniqueno=${selectedinvoice.uniqueno}&template=${template}&type=${type}`;

      
      console.log("getCurrentUser", pdfgenurl);
      handleOpenPdfInBrowser(pdfgenurl);
     // handleDownloadPdf(pdfgenurl);
      // handleDownloadPdf("https://bizmate.fylfotcloud.com/admin/webapi/generatepdf?p=1&a=1&user_id=840");
    } catch (error) {
      console.error('Login failed:', error);
    }
  };


    // eslint-disable-next-line no-unused-vars
    const handleDownloadPdfInvoiceEST = async (type, listType, selectedinvoice) => {
      console.log(selectedinvoice);
    
      try {
        let res = await getUserProfile();
        console.log("getCurrentUser", res);
    
        const id = selectedinvoice.id; // Assuming you have an id in selectedinvoice
        const template = res.data.invoice_template; // Replace with the actual template
    
        let pdfgenurl = `${API_URL}generatepdf?apptoken=${currentUser.apptoken}&id=${id}&uniqueno=${selectedinvoice.uniqueno}&template=${template}&type=${type}`;

        
        console.log("getCurrentUser", pdfgenurl);
       // handleOpenPdfInBrowser(pdfgenurl);
       handleDownloadPdf(pdfgenurl);
        // handleDownloadPdf("https://bizmate.fylfotcloud.com/admin/webapi/generatepdf?p=1&a=1&user_id=840");
      } catch (error) {
        console.error('Login failed:', error);
      }
    };
  



    const getCustomerTransCurr = (client_id) => {
      return axios
      .post(API_URL + "getCustomerTransCurr", {
        apptoken: currentUser.apptoken, // Ensure the payload is correct
        client_id: client_id,
        
      })
      .then((response) => {
        console.log("response.data.getCustomerTransCurr")
        console.log(response.data.data)
    
        if (response.data.errorMessage) {
          throw new Error(response.data.errorMessage);
        }
    
        return response.data;
      });
    
    };

    const getCustomerStandingBalance = (filteroption) => {
      return axios
      .post(API_URL + "getCustomerStandingBalance", {
        apptoken: currentUser.apptoken, // Ensure the payload is correct
        client_id: filteroption.client_id,
        linkModeType: filteroption.linkModeType,
        uniqueno: filteroption.uniqueno,
      
        fdate: filteroption.fdate,
        tdate: filteroption.tdate,
        filterText: filteroption.filterText,
        
      })
      .then((response) => {
        console.log("response.data.getCustomerStandingBalance")
        console.log(response.data.data)
    
        if (response.data.errorMessage) {
          throw new Error(response.data.errorMessage);
        }
    
        return response.data;
      });
    
    };

    const getcustomerledgermainreport = (filteroption) => {

      console.log("getCurrentUser", filteroption);

      return axios
      .post(API_URL + "getcustomerledgermainreport", {
        apptoken: currentUser.apptoken, // Ensure the payload is correct
        client_id: filteroption.client_id,
        linkModeType: filteroption.linkModeType,
        uniqueno: "",
        fdate:filteroption.fdate,
        tdate: filteroption.tdate,

        filterText: filteroption.filterText,
        
      })
      .then((response) => {
        console.log("response.data.getcustomerledgermainreport")
        console.log(response.data.data)
    
        if (response.data.errorMessage) {
          throw new Error(response.data.errorMessage);
        }
    
        return response.data;
      });
    
    };





const addPaymentcustomer = (id, client, listType) => {
  return axios
  .post(API_URL + "addPaymentcustomer", {
    apptoken: currentUser.apptoken, // Ensure the payload is correct  addCustomerData
    linkModeType: listType, // Ensure the payload is correct  addCustomerData
    paymentModeType: client.paymentModeType.label,
    newamount: client.amount,
    ...client
  })
  .then((response) => {
    console.log("response.data.addPaymentcustomer")
    console.log(response.data.data)

    if (response.data.errorMessage) {
      throw new Error(response.data.errorMessage);
    }

    return response.data;
  });

};

const updatePaymentcustomer = (id, client, listType) => {
  return axios
  .post(API_URL + "addPaymentcustomer", {
    apptoken: currentUser.apptoken, // Ensure the payload is correct  addCustomerData
    linkModeType: listType, // Ensure the payload is correct  addCustomerData
    ...client
  })
  .then((response) => {
    console.log("response.data.addPaymentcustomer")
    console.log(response.data.data)

    if (response.data.errorMessage) {
      throw new Error(response.data.errorMessage);
    }

    return response.data;
  });

};


const createProfomaInvoice = ( listType, objdata) => {
  return axios
  .post(API_URL + "createProfomaInvoice", {
    apptoken: currentUser.apptoken, // Ensure the payload is correct  addCustomerData
    linkModeType: "invoicelist", // Ensure the payload is correct  addCustomerData
    uniqueno:objdata.uniqueno,
    id:objdata.id,
  })
  .then((response) => {
    console.log("response.data.addPaymentcustomer")
    console.log(response.data.data)

    if (response.data.errorMessage) {
      throw new Error(response.data.errorMessage);
    }

    return response.data;
  });

};


const deletePaymentCustomer = (objdata) => {
  return axios
  .post(API_URL + "deletePaymentcustomer", {
    apptoken: currentUser.apptoken, // Ensure the payload is correct
    invoice_id : objdata.invoice_id,
    id : objdata.id,
   
  })
  .then((response) => {
    console.log("response.data.deleteinvoice")
    console.log(response.data.data)

    if (response.data.errorMessage) {
      throw new Error(response.data.errorMessage);
    }

   

    return response.data;
  });

};


    
const getInvoicesBlocks = async (listType) => {
  try {
    const currentUser = await AuthService.getCurrentUser();
    if (!currentUser || !currentUser.apptoken) {
      AuthService.setCurrentUser(currentUser);
      throw new Error("User not authenticated or missing apptoken.");
    }

    return axios
      .post(API_URL + "customerInvoicesBlocks", {
        apptoken: currentUser.apptoken, // Ensure the payload is correct
        linkModeType: listType
      })
      .then((response) => {
        if (response.data.errorMessage) {
          throw new Error(response.data.errorMessage);
        }

        if (response.data.data && response.data.data.username) {
          localStorage.setItem("user", JSON.stringify(response.data.data));
        } else {
          console.warn("No username found in response data.");
        }

        return response.data;
      });
  } catch (error) {
    console.error("Error fetching invoices:", error);
    throw error;
  }
};


const customerInvoicesGetOldItemOrder = ( isNewSelectItem, client_id) => {
  return axios
  .post(API_URL + "customerInvoicesGetOldItemOrder", {
    apptoken: currentUser.apptoken, // Ensure the payload is correct  addCustomerData
    linkModeType: "invoicelist", // Ensure the payload is correct  addCustomerData
    item_id:isNewSelectItem,
    client_id:client_id,

  })
  .then((response) => {
    console.log("response.data.addPaymentcustomer")
    console.log(response.data.data)

    if (response.data.errorMessage) {
      throw new Error(response.data.errorMessage);
    }

    return response.data;
  });

};


    // eslint-disable-next-line no-unused-vars
    const handleDownloadPayments = async (filteroption) => {
     
    
      try {
   
    
      
        let pdfgenurl = `${API_URL}generatepdfpayment?apptoken=${currentUser.apptoken}&client_id=${filteroption.client_id}&linkModeType=${filteroption.linkModeType}&fdate=${filteroption.fdate}&tdate=${filteroption.tdate}`;

        console.log("getCurrentUser", pdfgenurl);
    
        handleDownloadPdf(pdfgenurl);
        // handleDownloadPdf("https://bizmate.fylfotcloud.com/admin/webapi/generatepdf?p=1&a=1&user_id=840");
      } catch (error) {
        console.error('Login failed:', error);
      }
    };



const updateTaxLabel = (taxdata) => {
  return axios
  .post(API_URL + "updateTaxLabel", {
    apptoken: currentUser.apptoken, // Ensure the payload is correct  addCustomerData
    taxdata: taxdata, // Ensure the payload is correct  addCustomerData
   
  })
  .then((response) => {
    console.log("response.data.updateInvoice")
    console.log(response.data.data)

    if (response.data.errorMessage) {
      throw new Error(response.data.errorMessage);
    }

    return response.data;
  });

};

export default {
  getInvoices,
  deleteinvoice,
  loadInvoiceNo,
  getUserProfile,
  addnewInvoice,
  addnewInvoiceWebFormWiz,
  updateInvoice,
  getDefaultTax,
  handlePrintPdfInvoiceEST,
  handleDownloadPdfInvoiceEST,
  getCustomerTransCurr,
  getCustomerStandingBalance,
  getcustomerledgermainreport,
  addPaymentcustomer,
  updatePaymentcustomer,
  createProfomaInvoice,
  deletePaymentCustomer,
  getInvoicesBlocks,
  handleDownloadPayments,
  customerInvoicesGetOldItemOrder,

  updateTaxLabel,
  handleOpenPdfInBrowser,
  
};
