 
import React, { useState } from "react";
import SettingsSidebar from "../layouts/SettingsSidebar";
import Header from "../layouts/Header";
import Sidebar from "../layouts/Sidebar";
import AuthService from "../services/auth.service";
import UserService from "../services/user.service";

const DeleteAccountFianl = () => {
  const [menu, setMenu] = useState(false);

  const toggleMobileMenu = () => {
    setMenu(!menu);
  };



  const handleDelete = () => {
   
    UserService.deleteAccount();

    UserService.deleteAccount()
    .then((response) => {
      AuthService.logout();
      console.log(response);
    })
    .catch(() => {
     
    });
     
  };

  const forceMenuClose = () => {  
    setMenu(false);
  };

  return (
    <>
    <div className={`main-wrapper ${menu ? "slide-nav" : ""}`}>
      <Header onMenuClick={() => toggleMobileMenu()} forceMenuClose={forceMenuClose}/>
      <Sidebar />

      <div className="page-wrapper">
        <div className="content container-fluid">
          {/* Page Header */}
          <div className="page-header">
            <div className="content-page-header">
              <h5>Settings</h5>
            </div>
          </div>
          {/* /Page Header */}
          <div className="row">
            <div className="col-xl-3 col-md-4">
              <SettingsSidebar />
            </div>
            <div className="col-xl-9 col-md-8">
              <div className="card">
                <div className="card-header">
                  <h5 className="card-title">Delete your account</h5>
                </div>
                <div className="card-body">
                  {/* Form */}
                  <form>
                    <p className="card-text">
                      When you delete your account, you lose access to Bill Wizer
                      account services, and we permanently delete your personal
                      data.
                    </p>
                    <p className="card-text">
                      Are you sure you want to close your account?
                    </p>
                    <div className="input-block mb-3 p-3">
                      <div className="custom-control custom-checkbox ">
                        <input
                          type="checkbox"
                          className="custom-control-input"
                          id="delete_account"
                        />
                        <label
                          className="custom-control-label text-danger"
                          htmlFor="delete_account"
                        >
                            &nbsp;Confirm that I want to delete my account.
                        </label>
                      </div>
                    </div>
                    <div className="text-end">
                     

                      <button
                      type="button"
                    className="btn btn-primary"
                
                    data-bs-toggle="modal"
                    data-bs-target="#delete_modal"
                    >
                    <i className="far fa-trash-alt me-2" />
                    Delete
                  </button>
                    </div>
                  </form>
                  {/* /Form */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>



<div
className="modal custom-modal fade"
id="delete_modal"
role="dialog"
 >
<div className="modal-dialog modal-dialog-centered modal-md">
  <div className="modal-content">
    <div className="modal-body">
      <div className="form-header">
        <h3>Delete Account</h3>
        <p>Are you sure you want to delete Account?</p>
      </div>
      <div className="modal-btn delete-action">
        <div className="row">
          <div className="col-6">
            <button
              type="reset"
              data-bs-dismiss="modal"
              className="w-100 btn btn-primary paid-continue-btn"
              onClick={() => handleDelete()}
            >
              Delete
            </button>
          </div>
          <div className="col-6">
            <button
              type="submit"
              data-bs-dismiss="modal"
              className="w-100 btn btn-primary "
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</div>

</>

  );
};

export default DeleteAccountFianl;
