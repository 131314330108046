/* eslint-disable */
import React, { useState, useEffect } from "react";
import staticmessages from '../../../services/staticmessages';
import Select from 'react-select';
import UserService from "../../../services/user.service";
import alertify from "alertifyjs";
import * as yup from 'yup';
import { useHistory } from 'react-router-dom';

import "../../../../node_modules/alertifyjs/build/css/alertify.css";
import "../../../../node_modules/alertifyjs/build/css/themes/semantic.css";
import { BillwizerLogoLight } from "../../../_components/Imagepath";

const Step1Form = () => {

  const history = useHistory();
  const [errors, setErrors] = useState({});

      // eslint-disable-next-line no-unused-vars
      const inputInvoiceValues = {
        id: null,
        uniqueno: '',
        client_name: '',
        invoice_number: '',
        order_no: '',
        invoice_prefix: "",
       
        order_date: null,
        due_date: "",
        renewal_date: "",
        date: Date.now(),
        datetimestamp: Date.now(),
        datetimestampdue: Date.now(),
        datetimestamprenewal: null,
        subtotal: 0,
        gtotal: 0,
        partial_amount: 0,
        globaltax: 0,
        conversion_rate: '',
        discount: '',
        discount_amount: '',
        discounttype: '',
        taxable_amount: '',
        invoice_taxrate: '',
    
        user_id: 0,
        symbol: '',
    
        payment_terms: '',
        subscription_terms: '',
    
        dateformat: '',
        currency: '',
        selectedtax: '',
        status: false,
        country: '',
        state: '',
        customer: null,
        customeritem: [],
        deletecustomeritem: [],
        taxdata: [],
        defaulttaxdata: [],
        termcondition: '',
      };
  
    
  

  // Load invoice from localStorage
  const loadInvoiceFromStorage = () => {
    const storedInvoice = localStorage.getItem('invoicewizard');
    return storedInvoice ? JSON.parse(storedInvoice) : null;
  };

  const [invoice, setInvoice] = useState(() => {
    const storedInvoice = loadInvoiceFromStorage();
    return storedInvoice && Object.keys(storedInvoice).length > 0 ? storedInvoice : {};
  });


  

  const [step1Data, setStep1Data] = useState({
    company_name:  "",
    city:  "",
    country:  "",
    state: "",
    symbol:  "",
    currency:  "",
    dateformat:  "",
    work_email:  "",
  });

  const [countriesorg, setCountriesOrg] = useState([]);
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [isCountriesLoaded, setIsCountriesLoaded] = useState(false);
  const [dateformats, setDateFormats] = useState([]);

  const [currencylistOrg, setCurrencyListOrg] = useState([]);
  const [currencylist, setCurrencyList] = useState([]);

  const [selcurrency, setSelCcurrency] = useState({});

  const fetchData = async () => {
    await fetchDateFormat();
    await fetchCurrencyList();
    await fetchCountries();
   

    if (step1Data.country && isCountriesLoaded) {
      fetchStates(step1Data.country);
    }
  };

  useEffect(() => {
    fetchData();
  }, [isCountriesLoaded]);

  const handleChangeCustom = (selectedOption, name) => {
    handleInputChange({ target: { name, value: selectedOption ? selectedOption.value : '' } });
    if (name === 'country') {
      fetchStates(selectedOption.value);
    }
  };

  const handleChangeCustomCountry = (selectedOption, name) => {
    handleInputChange({ target: { name, value: selectedOption ? selectedOption.value : '' } });

    fetchStates(selectedOption.value);
    //autoSelectCurrency(selectedOption);
   
  };


  useEffect(() => {
    if (step1Data.country) {
      // Find the currency details based on country name
      const autocurr = currencylistOrg.find(
        (country) => country.country_name === step1Data.country
      );

     
      // Check if autocurr is defined before proceeding
      if (autocurr) {
        // Update the selected currency
        setSelCcurrency({
          value: `${autocurr.currency}#${autocurr.symbol}`,
          label: `${autocurr.name} (${autocurr.symbol})`,
        });
  
        // Update the step1Data with currency details
        setStep1Data((prevState) => ({
          ...prevState,
          currency: autocurr.currency,
          symbol: autocurr.symbol,
        }));
      } else {
        console.error(`No currency found for country: ${step1Data.country}`);
      }
    }
  }, [step1Data.country, currencylistOrg]);
  




  const handleChangeCustomCurrency = (value, name) => {
    // setProfile({ ...profile, [name]: value.value });

    const parts = value.value.split('#');

    console.error('Error fetching parts:', value);

    setStep1Data(prevState => ({
      ...prevState,
      currency: parts[0],
      symbol: parts[1]
    }));
   


  };


  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setStep1Data(prevState => ({
      ...prevState,
      [name]: value,
    }));
  };

  const getCountryId = (countryName) => {
    const country = countriesorg.find((country) => country.country_name === countryName);
    return country ? country.id : null;
  };


  useEffect(() => {
      fetchProfile();
  }, []);
  const fetchProfile = async () => {
    try {
      const response = await UserService.getUserProfile();
      console.log("Fetched Profile Data:", response.data); // Log the complete response
  
      setStep1Data({
        company_name: response.data.company_name || "",
        city: response.data.city || "",
        country: response.data.country || "",
        state: response.data.state || "",
        symbol: response.data.symbol || "",
        dateformat: response.data.dateformat || "",
        currency: response.data.currency || "",
        work_email: response.data.username || "",
      });

      setInvoice((prevInvoice) => ({
        ...prevInvoice,  // Spread previous invoice state
        customer: {},
      }));

  
      console.log("Updated step1Data:", step1Data); // Log the updated state immediately after setting it
    } catch (error) {
      console.error("UserService error:", error);
    }
  };
  


  const fetchStates = async (country) => {
    try {
      const countryId = getCountryId(country);
      if (countryId) {
        const response = await UserService.fetchStates(countryId);
        const responsestate = response.data.map((item) => ({
          value: item.name,
          label: item.name,
        }));
        setStates(responsestate);
      } else {
        alert('Country not found');
      }
    } catch (error) {
      console.log('Error fetching states: ' + error.message);
    }
  };

  const fetchCountries = async () => {
    try {
      const response = await UserService.fetchCountries();
      setCountriesOrg(response.data);

      const responsecountry = response.data.map((item) => ({
        value: item.country_name,
        label: item.country_name,
      }));

      setCountries(responsecountry);
      setIsCountriesLoaded(true);
    } catch (error) {
      console.log('Error fetching countries: ' + error.message);
    }
  };

  const fetchCurrencyList = async () => {
    try {
      const response = await UserService.getCurrencyList();
      setCurrencyListOrg(response.data);
      const responsecurrencylist = response.data.map((item) => ({
        value: `${item.currency}#${item.symbol}`,
        label: `${item.name} (${item.symbol})`,
      }));
      setCurrencyList(responsecurrencylist);
    } catch (error) {
      console.log('Error fetching currencies: ' + error.message);
    }
  };

  const fetchDateFormat = async () => {
    try {
      const response = await UserService.dateFormatList();
      const responsedateformat = response.data.map((item) => ({
        value: item.dateformat,
        label: item.dateformat,
      }));
      setDateFormats(responsedateformat);
    } catch (error) {
      console.log('Error fetching date formats: ' + error.message);
    }
  };

  // Validation schemas
  const step1Schema = yup.object().shape({
    company_name: yup.string().required("Company name is required"),
    city: yup.string().required("City is required"),
    country: yup.string().required("Country is required"),
    state: yup.string().test(
      'state-required',
      'State is required',
      function(value) {
        const { country } = this.parent;
        return (country === 'India' || country === 'United States') ? !!value : true;
      }
    ),
    dateformat: yup.string().required("Date Format is required"),
    symbol: yup.string().required("Currency is required"),
  });


  const validateStep = async () => {
    let schema;
    let data;

    schema = step1Schema;
    data = step1Data;

console.log(data);

    try {
      await schema.validate(data, { abortEarly: false });
      setErrors({});
      return data; // Return validated data
    } catch (err) {
      const newErrors = {};
      err.inner.forEach((error) => {
        newErrors[error.path] = error.message;
      });
      setErrors(newErrors);
      return null;
    }
  };




  useEffect(() => {

    console.log("setInvoice API Error2222")
    if (invoice) {
      localStorage.setItem('invoicewizard', JSON.stringify(invoice));
    }
  }, [invoice, invoice.customer]);
  

  // Inside your component
useEffect(() => {
 
  setInvoice((prevInvoice) => {
    const updatedInvoice = {
      ...prevInvoice,
      city: step1Data.city,
      state: step1Data.state,
      country: step1Data.country,
      customer: {
        ...prevInvoice.customer,
     
        city: step1Data.city,
        state: step1Data.state,
        country: step1Data.country,
      }
    };
    console.log("setInvoice API Error 333 :", updatedInvoice);
    return updatedInvoice;
  });
}, [step1Data.country,step1Data.state,step1Data.city]);

  const handleNext = async () => {
    const validatedData = await validateStep();

    if (validatedData) {
      try {
        let response;
        let tempresponse;

        tempresponse = await UserService.updateUserProfile(validatedData);
        response = tempresponse.data;



       

        if (response && response.id) {

    
          
          
          
          //let mess = `ss `;
         // toastrSuccess(mess);
         history.push('step2form');



        } else {
          throw new Error("Unexpected response from server.");
        }
      } catch (error) {
        alertify.set("notifier", "position", "top-center");
        alertify.error("Failed to save data. Please try again.");
        console.error("API Error:", error); // Log error details for debugging
      }
    } else {
      alertify.set("notifier", "position", "top-center");
      alertify.error("Please fill all the required fields to proceed.");
    }
  };

  return (

    
    <div className="main-wrapper container d-flex align-items-center justify-content-center boardingwizard" style={{ minHeight: "80vh" }}>
      <div className="content container-fluid">
      <img
            className="img-fluid logo-dark mb-2"
            src={BillwizerLogoLight}
            alt="Logo"
          />
      <div className="row justify-content-center">
          <div className="col-lg-6 col-md-6 col-sm-12">
            <div className="card">
              <div className="card-header">
              
              </div>
            
              <div className="card-body">
              

                <form autoComplete="off">
                  <div className="mb-4">
                    <h5>{staticmessages.SETTING_step1Data}</h5>
                  
                  </div>
                  <div className="row">
                    <div className="col-lg-6">
                      <div className="input-block mb-3">
                        <label htmlFor="company_name" className="form-label">
                          Company / Individual Name<span className="alertstrik">*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="company_name"
                          name="company_name"
                          placeholder="Company / Individual Name"
                          value={step1Data.company_name}
                          onChange={handleInputChange}
                        />
                        {errors.company_name && <small className="error">{errors.company_name}</small>}
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="input-block mb-3">
                        <label htmlFor="city" className="form-label">
                          City<span className="alertstrik">*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="city"
                          placeholder="Enter City"
                          name="city"
                          value={step1Data.city}
                          onChange={handleInputChange}
                         
                        />
                        {errors.city && <small className="error">{errors.city}</small>}
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-6">
                      <div className="input-block mb-3">
                        <label htmlFor="country" className="form-label">Country</label>
                        {isCountriesLoaded && (
                          <Select
                            id="country"
                            name="country"
                            defaultValue={step1Data.country ? { value: step1Data.country, label: step1Data.country } : null}
                            onChange={(selectedOption) => handleChangeCustomCountry(selectedOption, 'country')}
                            options={countries}
                            placeholder="Select Country"
                             className = "fixzindex"
                          />

                        )}
                        {errors.country && <small className="error">{errors.country}</small>}
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="input-block mb-3">
                        <label htmlFor="state" className="form-label">State</label>
                        {isCountriesLoaded && (
                          <Select
                            id="state"
                            name="state"
                            defaultValue={step1Data.state ? { value: step1Data.state, label: step1Data.state } : null}
                            onChange={(selectedOption) => handleChangeCustom(selectedOption, 'state')}
                            options={states}
                            placeholder="Select State"
                             className = "fixzindex2"
                          />
                        )}

                        {errors.state && <small className="error">{errors.state}</small>}
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-6">
                      <div className="input-block mb-3">
                        <label>Date Format<span className="alertstrik">*</span></label>
                        {isCountriesLoaded && (
                          <Select
                            id="dateformat"
                            name="dateformat"
                            defaultValue={step1Data.dateformat ? { value: step1Data.dateformat, label: step1Data.dateformat } : null}
                            onChange={(selectedOption) => handleChangeCustom(selectedOption, 'dateformat')}
                            options={dateformats}
                            placeholder="Select Date Format"
                             className = "fixzindex3"
                          />
                        )}

                        {errors.dateformat && <small className="error">{errors.dateformat}</small>}
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="input-block mb-3">
                        <label>Currency<span className="alertstrik">*</span></label>
                        {isCountriesLoaded && (
                          <Select
                            id="symbol"
                            name="symbol"
                            value={selcurrency}
                            onChange={(selectedOption) => handleChangeCustomCurrency(selectedOption, 'symbol')}
                            options={currencylist}
                            placeholder="Select Currency"
                             className = "fixzindex4"
                          />
                        )}

                        {errors.symbol && <small className="error">{errors.symbol}</small>}
                      </div>
                    </div>
                    <div className="d-flex justify-content-center">
                      <button className="btn btn-primary" type="button" onClick={handleNext}>
                        Next
                      </button>
                    </div>

                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Step1Form;
