// const config = {
//     API_URL: "https://bizmate.fylfotcloud.com/admin/webapi/",
//     API_LOGOURL: "https://bizmate.fylfotcloud.com/upload/logo/",
//     API_SIGNURL: "https://bizmate.fylfotcloud.com/upload/logo/",
//   };

  const config = {
    API_URL: "https://bizmate.fylfotcloud.com/admin/webapi/",
    API_LOGOURL: "",
    API_SIGNURL: "",
  };


  
  
  
  export default config;