import axios from "axios";
import config from './config';
import AuthService from "../services/auth.service";

const API_URL = config.API_URL;

const getPublicContent = () => {
  return axios.get(API_URL + "all");
};

const getUserBoard = () => {
  return axios.get(API_URL + "user");
};

const getModeratorBoard = () => {
  return axios.get(API_URL + "mod");
};

const getAdminBoard = () => {
  return axios.get(API_URL + "admin");
};


const currentUser = AuthService.getCurrentUser();

const getUserProfile = () => {
  return axios
  .post(API_URL + "getBusinessDetails", {
    apptoken: currentUser.apptoken, // Ensure the payload is correct
    
  })
  .then((response) => {
    console.log("response.data.getUserProfile")
    console.log(response.data.data)

    if (response.data.errorMessage) {
      throw new Error(response.data.errorMessage);
    }

    return response.data;
  });

};


const checkCurrencyChange = () => {
  return axios
  .post(API_URL + "checkCurrencyChange", {
    apptoken: currentUser.apptoken, // Ensure the payload is correct
    
  })
  .then((response) => {
     if (!response.data) {
      throw new Error("");
    }
    return response.data;
  });

};



const getUserProfileWithPlan = () => {
  return axios
  .post(API_URL + "getBusinessDetailsWithPlan", {
    apptoken: currentUser.apptoken, // Ensure the payload is correct
    
  })
  .then((response) => {
    console.log("response.data.getUserProfile")
    console.log(response.data.data)

    if (response.data.errorMessage) {
      throw new Error(response.data.errorMessage);
    }

    return response.data;
  });

};

const updateUserProfile = (profile) => {

  const payload = {
    apptoken: currentUser.apptoken,
    ...profile
  };

  return axios
  .post(API_URL + "updateBusinessDetails", payload)
  .then((response) => {
    console.log("response.data.updateBusinessDetails")
    console.log(response.data.data)

    if (response.data.errorMessage) {
      throw new Error(response.data.errorMessage);
    }
    return response.data;
  });
};



const saveSettingsInvEstSeries = (settings) => {

  const payload = {
    apptoken: currentUser.apptoken,
    ...settings
  };

  return axios
  .post(API_URL + "updateInvoiceEstimateSettingDetails", payload)
  .then((response) => {
    console.log("response.data.updateBusinessDetails")
    console.log(response.data.data)

    if (response.data.errorMessage) {
      throw new Error(response.data.errorMessage);
    }
    return response.data;
  });
};


const uploadLogo = async (selectedFile) => {
  try {
    const formData = new FormData();
    formData.append('logo_img', selectedFile);
    formData.append('apptoken', currentUser.apptoken);

    const response = await axios.post(API_URL + "updateLogo", formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
    return response.data;
   
  } catch (error) {
    console.error('Error uploading file:', error);
  }
};


const UploadSignature = async (selectedFile) => {
  try {
    const formData = new FormData();
    formData.append('logo_img', selectedFile);
    formData.append('apptoken', currentUser.apptoken);

    const response = await axios.post(API_URL + "updateSignature", formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
    return response.data;
    
  } catch (error) {
    console.error('Error uploading file:', error);
  }
};


const SavePrintTepmate = async (selectedFile) => {
  try {
    const formData = new FormData();
    formData.append('logo_img', selectedFile);
    formData.append('apptoken', currentUser.apptoken);

    const response = await axios.post(API_URL + "updateInvoiceEstimateTemplate", formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
    return response.data;
   
  } catch (error) {
    console.error('Error uploading file:', error);
  }
};




const fetchCountries = () => {
  return axios
  .post(API_URL + "getCountries", {
    apptoken: currentUser.apptoken, // Ensure the payload is correct
  
  })
  .then((response) => {
    console.log("response.data.getCountries")
    console.log(response.data.data)

    if (response.data.errorMessage) {
      throw new Error(response.data.errorMessage);
    }


    return response.data;
  });

};

const fetchStates = (country_id) => {
  return axios
  .post(API_URL + "getStates", {
    apptoken: currentUser.apptoken, // Ensure the payload is correct
    country_id: country_id
  })
  .then((response) => {
    console.log("response.data.getClientUnitMasters")
    console.log(response.data.data)

    if (response.data.errorMessage) {
      throw new Error(response.data.errorMessage);
    }

   

    return response.data;
  });

};


const dateFormatList = () => {
  return axios
  .post(API_URL + "dateFormatList", {
    apptoken: currentUser.apptoken, // Ensure the payload is correct
  })
  .then((response) => {
    console.log("response.data.dateFormatList")
    console.log(response.data.data)

    if (response.data.errorMessage) {
      throw new Error(response.data.errorMessage);
    }
    return response.data;
  });
};

const getCurrencyList = () => {
  return axios
  .post(API_URL + "getCurrencyList", {
    apptoken: currentUser.apptoken, // Ensure the payload is correct
  })
  .then((response) => {
    console.log("response.data.getCurrencyList")
    console.log(response.data.data)

    if (response.data.errorMessage) {
      throw new Error(response.data.errorMessage);
    }
    return response.data;
  });
};




const getTaxBusinessRegList = () => {
  return axios
  .post(API_URL + "getCompanyBusinessNoListMasters", {
    apptoken: currentUser.apptoken, // Ensure the payload is correct
  })
  .then((response) => {
    console.log("response.data.getCurrencyList")
    console.log(response.data.data)

    if (response.data.errorMessage) {
      throw new Error(response.data.errorMessage);
    }
    return response.data;
  });
};

const updateINVEstSer = (profile) => {

  const payload = {
    apptoken: currentUser.apptoken,
    ...profile
  };

  return axios
  .post(API_URL + "updateInvoiceEstimateSettingDetails", payload)
  .then((response) => {
    console.log("response.data.updateBusinessDetails")
    console.log(response.data.data)

    if (response.data.errorMessage) {
      throw new Error(response.data.errorMessage);
    }
    return response.data;
  });
};


const UpdateTaxMasterSetting = (profile) => {

  const payload = {
    apptoken: currentUser.apptoken,
    ...profile
  };

  return axios
  .post(API_URL + "newTaxMasterSetting", payload)
  .then((response) => {
    console.log("response.data.newTaxMasterSetting")
    console.log(response.data.data)

    if (response.data.errorMessage) {
      throw new Error(response.data.errorMessage);
    }
    return response.data;
  });
};



const getCustomerTaxMasters = () => {
  return axios
  .post(API_URL + "getCustomerTaxMasters", {
    apptoken: currentUser.apptoken, // Ensure the payload is correct
  })
  .then((response) => {
    console.log("response.data.getCustomerTaxMasters")
    console.log(response.data.data)

    if (response.data.errorMessage) {
      throw new Error(response.data.errorMessage);
    }
    return response.data;
  });
};


const DeleteLogo = (profile) => {

  const payload = {
    apptoken: currentUser.apptoken,
    ...profile
  };

  return axios
  .post(API_URL + "deleteuserlogo", payload)
  .then((response) => {
    console.log("response.data.newTaxMasterSetting")
    console.log(response.data.data)

    if (response.data.errorMessage) {
      throw new Error(response.data.errorMessage);
    }
    return response.data;
  });
};


const DeleteSign = (profile) => {

  const payload = {
    apptoken: currentUser.apptoken,
    ...profile
  };

  return axios
  .post(API_URL + "deleteusersign", payload)
  .then((response) => {
    console.log("response.data.newTaxMasterSetting")
    console.log(response.data.data)

    if (response.data.errorMessage) {
      throw new Error(response.data.errorMessage);
    }
    return response.data;
  });
};


const updateInvoiceEstimateTemplate = (profile) => {

  const payload = {
    apptoken: currentUser.apptoken,
    ...profile
  };

  return axios
  .post(API_URL + "updateInvoiceEstimateTemplate", payload)
  .then((response) => {
    console.log("response.data.updateInvoiceEstimateTemplate")
    console.log(response.data.data)

    if (response.data.errorMessage) {
      throw new Error(response.data.errorMessage);
    }
    return response.data;
  });
};


const updateNotesSettingDetails = (profile) => {

  const payload = {
    apptoken: currentUser.apptoken,
    ...profile
  };

  return axios
  .post(API_URL + "updateNotesSettingDetails", payload)
  .then((response) => {
    console.log("response.data.updateNotesSettingDetails")
    console.log(response.data.data)

    if (response.data.errorMessage) {
      throw new Error(response.data.errorMessage);
    }
    return response.data;
  });
};


const updatePaymentSetting = (profile) => {

  const payload = {
    apptoken: currentUser.apptoken,
    ...profile
  };

  return axios
  .post(API_URL + "updatePaymentSetting", payload)
  .then((response) => {
    console.log("response.data.updatePaymentSetting")
    console.log(response.data.data)

    if (response.data.errorMessage) {
      throw new Error(response.data.errorMessage);
    }
    return response.data;
  });
};


const deleteAccountSendConMail = (otp) => {

  const payload = {
    apptoken: currentUser.apptoken,
    delcode: otp
  
  };

  return axios
  .post(API_URL + "verifydeleteUserAccount", payload)
  .then((response) => {
    console.log("response.deleteAccountSendConMail")
    console.log(response.data.errorMessage)

    if (response.data.errorMessage) {
      throw new Error(response.data.errorMessage); 
    }
    return response.data;
  });
};
const deleteAccount = () => {

  const payload = {
    apptoken: currentUser.apptoken,
  
  };

  return axios
  .post(API_URL + "deleteUserAccountsendcode", payload)
  .then((response) => {
    console.log("response.data.updatePaymentSetting")
    console.log(response.data)

    if (response.data.errorMessage) {
      throw new Error(response.data.errorMessage); 
    }
    return response.data;
  });
};


const SendMessage = (data) => {

  const payload = {
    apptoken: currentUser.apptoken,
    ...data
  };

  return axios
  .post(API_URL + "sendmessage", payload)
  .then((response) => {
    console.log("response.data.newTaxMasterSetting")
    console.log(response.data.data)

    if (response.data.errorMessage) {
      throw new Error(response.data.errorMessage);
    }
    return response.data;
  });
};



const fetchWebSubscriptions = () => {
  return axios
  .post(API_URL + "fetchWebSubscriptions", {
    apptoken: currentUser.apptoken, // Ensure the payload is correct
    
  })
  .then((response) => {
    console.log("response.data.getUserProfile")
    console.log(response.data.data)

    if (response.data.errorMessage) {
      throw new Error(response.data.errorMessage);
    }

    return response.data;
  });

};

const UserService = {
  getPublicContent,
  getUserBoard,
  getModeratorBoard,
  getAdminBoard,
  getUserProfile,
  checkCurrencyChange,
  getUserProfileWithPlan,
  fetchWebSubscriptions,
  updateUserProfile,
  saveSettingsInvEstSeries,
  uploadLogo,
  UploadSignature,
  SavePrintTepmate,
    fetchCountries,
    dateFormatList,
    getCurrencyList,
  fetchStates,
  updateINVEstSer,
  getTaxBusinessRegList,
  UpdateTaxMasterSetting,
  getCustomerTaxMasters,
  DeleteLogo,
  DeleteSign,
  updateInvoiceEstimateTemplate,
  updateNotesSettingDetails,
  updatePaymentSetting,
  deleteAccountSendConMail,
  deleteAccount,
  SendMessage,
}

export default UserService;
