import React from 'react';
import PropTypes from 'prop-types'; // Import PropTypes for validation
import { Modal, Button } from 'react-bootstrap';

const ConfirmationModal = ({ show, setShow, message, onConfirm, onCancel }) => {
  
  const handleConfirm = () => {
    setShow(false);
    if (onConfirm) {
      onConfirm(); // Call the confirm action
    }
  };

  const handleCancel = () => {
    setShow(false);
    if (onCancel) {
      onCancel(); // Call the cancel action
    }
  };

  return (
    <Modal show={show} onHide={handleCancel}>
      <Modal.Header>
        <Modal.Title>Confirm Action</Modal.Title>
      </Modal.Header>
      <Modal.Body>{message}</Modal.Body>
      <Modal.Footer>
        <Button type='button' className="me-2" variant="primary" onClick={handleCancel}>
          Cancel
        </Button>
        <Button type='button' variant="danger" onClick={handleConfirm}>
          Confirm
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

// Define PropTypes for the component
ConfirmationModal.propTypes = {
  show: PropTypes.bool.isRequired,            // Ensure 'show' is a boolean and required
  setShow: PropTypes.func.isRequired,         // Ensure 'setShow' is a function and required
  message: PropTypes.string.isRequired,       // Ensure 'message' is a string and required
  onConfirm: PropTypes.func.isRequired,       // Ensure 'onConfirm' is a function and required
  onCancel: PropTypes.func.isRequired         // Ensure 'onCancel' is a function and required
};

export default ConfirmationModal;
