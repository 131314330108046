/* eslint-disable */
import React from "react";
import { Link, withRouter } from "react-router-dom";
import staticmessages from '../services/staticmessages';
const SettingsSidebar = (props) => {
  let pathName = props.location.pathname;
  return (
    <div className="card">
      <div className="card-body">
        <div className="widget settings-menu">
          <ul>
            <li className="nav-item">
              <Link
                to="/settings"
                className={`${
                  "/settings" === pathName
                    ? "active nav-link"
                    : "nav-link"
                }`}
              >
                <i className="far fa-user"></i> <span>{staticmessages.SETTING_PROFILE}</span>
              </Link>
            </li>
        
            <li className="nav-item">
              <Link
                to="/invoice-settings"
                className={`${
                  "/invoice-settings" === pathName
                    ? "active nav-link"
                    : "nav-link"
                }`}
              >
                <i className="far fa-check-square"></i>{" "}
                <span>{staticmessages.SETTING_INVESTSER}</span>
              </Link>
            </li>


            <li className="nav-item">
              <Link
                to="/tax-rates"
                className={`${
                  "/tax-rates" === pathName ? "active nav-link" : "nav-link"
                }`}
              >
                <i className="fe fe-file-text"></i> <span>{staticmessages.SETTING_TAX}</span>
              </Link>
            </li>


            <li className="nav-item">
              <Link
                to="/upload-logo"
                className={`${
                  "/upload-logo" === pathName ? "active nav-link" : "nav-link"
                }`}
              >
                <i className="fe fe-file-text"></i> <span>{staticmessages.SETTING_UPLOADLOGO}</span>
              </Link>
            </li>

            <li className="nav-item">
              <Link
                to="/upload-signature"
                className={`${
                  "/upload-signature" === pathName ? "active nav-link" : "nav-link"
                }`}
              >
                <i className="fe fe-file-text"></i> <span>{staticmessages.SETTING_UPLOADSIGN}</span>
              </Link>
            </li>


            

            <li className="nav-item">
              <Link
                to="/invoice-templatesettings"
                className={`${
                  "/invoice-templatesettings" === pathName
                    ? "active nav-link"
                    : "nav-link"
                }`}
              >
                <i className="far fa-list-alt"></i>{" "}
                <span>{staticmessages.SETTING_TEMPLATE}</span>
              </Link>
            </li>

            <li className="nav-item">
              <Link
                to="/termsconditions"
                className={`${
                  "/termsconditions" === pathName ? "active nav-link" : "nav-link"
                }`}
              >
                <i className="fe fe-send"></i> <span>{staticmessages.SETTING_TERMCOND}</span>
              </Link>
            </li>






            <li className="nav-item">
              <Link
                to="/paymentmethod"
                className={`${
                  "/paymentmethod" === pathName ? "active nav-link" : "nav-link"
                }`}
              >
                <i className="far fa-bell"></i> <span>{staticmessages.SETTING_PAYMENT}</span>
              </Link>
            </li>



            
         {/* <li className="nav-item">
              <Link
                to="/planbilling"
                className={`${
                  "/planbilling" === pathName ? "active nav-link" : "nav-link"
                }`}
              >
                <i className="fe fe-credit-card"></i> <span>{staticmessages.SETTING_BILLING}</span>
              </Link>
            </li>  */}


            <li className="nav-item error">
              <Link
                to="/delete-account"
                className={`${
                  "/delete-account" === pathName ? "active nav-link error" : "nav-link error"
                }`}
              >
                <i className="fas fa-unlock-alt"></i> <span>Delete Account</span>
              </Link>
            </li>
            
          
          </ul>
        </div>
      </div>
    </div>
  );
};
export default withRouter(SettingsSidebar);
