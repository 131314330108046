/* eslint-disable */
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Header from "../layouts/Header";
import Sidebar from "../layouts/Sidebar";
import PropTypes from 'prop-types';
import Select from 'react-select';
//import FeatherIcon from "feather-icons-react/build/FeatherIcon";
//import Select from "react-select";
import SettingsSidebar from "../layouts/SettingsSidebar";
import staticmessages from '../services/staticmessages';
import UserService from "../services/user.service";


import alertify from "alertifyjs";
import * as yup from 'yup'; // Import Yup
import "../../node_modules/alertifyjs/build/css/alertify.css";
import "../../node_modules/alertifyjs/build/css/themes/semantic.css";
import FeatherIcon from 'feather-icons-react/build/FeatherIcon';
const ProfileSetting = ({ onApplyAction }) => {



  const [countries, setCountries] = useState([]);
  const [countriesorg, setCountriesOrg] = useState([]);
  const [states, setStates] = useState([]);
  const [isCountriesLoaded, setIsCountriesLoaded] = useState(false);
  const [isProfileLoaded, setIsProfileLoaded] = useState(false);

  const [dateformats, setDateFormats] = useState([]);
  const [currencylist, setCurrencyList] = useState([]);
  const [errors, setErrors] = useState({});
  const [taxblist, setTaxBList] = useState([]);

  const [profile, setProfile] = useState({
    company_name: "",
    mobile: "",
    work_email: "",
    company_address_line_1: "",
    city: "",
    state: "",
    postcode: "",
    company_phone: "",
    company_business_number: "",
    company_pan: "",
    company_cin: "",
    country: "",
    symbol: "",
    currency: "",
    dateformat: "",
    company_website: "",
    review_link: "",
    company_business_label: ""
  });
  // eslint-disable-next-line no-unused-vars
  const [message, setMessage] = useState('');


  useEffect(() => {
    fetchProfile();
    fetchData();

  }, [location.state, isCountriesLoaded]);




  const fetchData = async () => {
    await fetchDateFormat();
    await fetchCurrencyList();
    await fetchCountries();

    await getTaxBusinessRegList();

    console.error('Error fetching 111:', "11");
    if (profile.country) {
      console.error('Error fetching 222:', "222");
      // setClient(location.state.client);
      if (isCountriesLoaded) {
        fetchStates(profile.country);
      }
    }
  };

  const getTaxBusinessRegList = async () => {
    try {
      const response = await UserService.getTaxBusinessRegList();

      const responsecurrencylist = response.data.map((item) => ({
        value: `${item.name}`,
        label: `${item.name}`,
      }));
      setTaxBList(responsecurrencylist);

    } catch (error) {
      console.error('Error fetching countries:', error);
    }
  };

  const fetchCurrencyList = async () => {
    try {
      const response = await UserService.getCurrencyList();

      const responsecurrencylist = response.data.map((item) => ({
        value: `${item.currency}#${item.symbol}`,
        label: `${item.name} (${item.symbol})`,
      }));
      setCurrencyList(responsecurrencylist);

    } catch (error) {
      console.error('Error fetching countries:', error);
    }
  };
  const fetchDateFormat = async () => {
    try {
      const response = await UserService.dateFormatList();

      const responsedateformat = response.data.map((item) => ({
        value: item.dateformat,
        label: item.dateformat,
      }));
      setDateFormats(responsedateformat);

    } catch (error) {
      console.error('Error fetching countries:', error);
    }
  };
  // const [dateformats, setDateFormats] = useState([]);
  // const [currencylist, setCurrencyList] = useState([]);


  const fetchProfile = async () => {
    UserService.getUserProfile()
      .then((response) => {




        const {
          company_name,
          mobile,
          work_email,
          company_address_line_1,
          city,
          state,
          postcode,
          company_phone,
          company_business_number,
          company_pan,
          company_cin,
          country,
          symbol,
          currency,
          dateformat,
          company_website,
          review_link,
          company_business_label,
        } = response.data;

        setProfile({
          company_name: company_name || '',
          mobile: mobile || '',
          work_email: work_email || '',
          company_address_line_1: company_address_line_1 || '',
          city: city || '',
          state: state || '',
          postcode: postcode || '',
          company_phone: company_phone || '',
          company_business_number: company_business_number || '',
          company_pan: company_pan || '',
          company_cin: company_cin || '',
          country: country || '',
          symbol: symbol || '',
          currency: currency || '',
          dateformat: dateformat || '',
          company_website: company_website || '',
          review_link: review_link || '',
          company_business_label: company_business_label || '',
        });
        setIsProfileLoaded(true);
      })
      .catch((error) => {
        console.error("UserService error:", error);
      });
  };


  const getDefaultValue = (options, value) => {
    return options ? options.find(option => option.value === value) : '';
  };


  const fetchCountries = async () => {
    try {
      const response = await UserService.fetchCountries();

      setCountriesOrg(response.data);

      const responsecountry = response.data.map((item) => ({
        value: item.country_name,
        label: item.country_name,
      }));


      setCountries(responsecountry);
      setIsCountriesLoaded(true);
    } catch (error) {
      console.error('Error fetching countries:', error);
    }
  };

  const getCountryId = (countryName) => {
    console.error('handleChangeCustom countryName:', countryName);

    console.error(countries);
    console.log(countryName);
    countries.forEach(country => console.log(country.country_name));

    const country = countriesorg.find((country) => country.country_name === countryName);
    return country ? country.id : null;
  };

  const handleChangeCustom = (value, name) => {
    setProfile({ ...profile, [name]: value.value });

    console.error('handleChangeCustom value:', value.value);
    console.error('handleChangeCustom name:', name);

    if (name === 'country') {
      fetchStates(value.value);
      setProfile({ ...profile, state: '', state_code: '', [name]: value.value });
    }

  };

  const handleChangeCustomCurrency = (value, name) => {
    // setProfile({ ...profile, [name]: value.value });

    const parts = value.value.split('#');

    console.error('Error fetching parts:', parts);
    setProfile({
      ...profile,
      currency: parts[0],
      symbol: parts[1]
    });


  };




  const fetchStates = async (country) => {
    console.error('fetchStates found' + country);
    try {
      const countryId = getCountryId(country);
      console.error('fetchStates countryId' + countryId);
      if (countryId) {
        const response = await UserService.fetchStates(countryId);

        const responsestate = response.data.map((item) => ({
          value: item.name,
          label: item.name,
        }));

        setStates(responsestate);
      } else {
        console.error('Country not found');
      }
    } catch (error) {
      console.error('Error fetching states:', error);
    }
  };


  const toastrError = (message) => {
    alertify.set("notifier", "position", "top-center");
    alertify.error(message);
  };


  const toastrSuccess = (message) => {
    alertify.set("notifier", "position", "top-center");
    alertify.success(message);
  };


  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setProfile({ ...profile, [name]: value });
  };

  const schema = yup.object().shape({
    company_name: yup.string().required('Company Name is required'),
    city: yup.string().required('City is required'),
    country: yup.string().required('Country is required'),

    state: yup.string().test(
      'state-required',
      'State is required',
      function(value) {
        const { country } = this.parent;
        return (country === 'India' || country === 'United States') ? !!value : true;
      }
    ),
    
    work_email: yup.string().email("Invalid email").notRequired(),
    
    dateformat: yup.string().required("Select Date Format"),
    symbol: yup.string().required("Select Currency"),

    company_phone: yup.string()
      .matches(/^[0-9+\-_]*$/, 'Contact number can only contain numbers, +, -, and _')
      .notRequired(),

    company_pan: yup.string()
      .matches(/^[a-zA-Z0-9]*$/, 'Pan No. can only contain alphanumeric characters')

      .notRequired(),



  });

  const handleSubmit = async (e) => {
    e.preventDefault();



    try {
      await schema.validate(profile, { abortEarly: false });

      console.error('fetchStates currency' + profile.currency);
      console.error('fetchStates symbol' + profile.symbol);

      UserService.updateUserProfile(profile)
        .then((response) => {
          setErrors(null);

         
          onApplyAction(); // Call parent's filter function
     

          toastrSuccess(staticmessages.SETTING_BPSUCCESS);
          setMessage('Profile updated successfully.');


        })
        .catch((error) => {
          setMessage('Failed to update profile. ' + error.message);
        });
    } catch (validationError) {


      if (validationError instanceof yup.ValidationError) {
        validationError.inner.forEach((err) => {
          const errorsObj = {};
          validationError.inner.forEach((error) => {
            errorsObj[error.path] = error.message;
          });
          setErrors(errorsObj);
        });
      } else {
        console.error(validationError);
      }
    }
  };


  return (
    <>
    
          <div className="row">
           
            <div className="col-xl-12 col-md-12">
              <div className="card">
                <div className="card-body w-100">
                  <div className="content-page-header">
                    <h5 className="setting-menu">{staticmessages.SETTING_PROFILE}</h5>
                  </div>
                  <div className="row">


                    <div className="col-lg-6 col-12">
                      <div className="input-block mb-3">
                        <label>Company / Individual Name<span className="alertstrik">*</span></label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter Company / Individual Name"
                          onChange={handleInputChange}
                          id="company_name"
                          name="company_name"
                          value={profile.company_name}
                        />
                        <small className="error">{errors?.company_name}</small>
                      </div>
                    </div>

                    <div className="col-lg-6 col-12">
                      <div className="input-block mb-3">
                        <label>Contact No.</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Company Phone"
                          onChange={handleInputChange}
                          id="company_phone"
                          name="company_phone"
                          maxLength="14"
                          value={profile.company_phone}
                        />
                        <small className="error">{errors?.company_phone}</small>
                      </div>
                    </div>





                    <div className="col-lg-6 col-12">
                      <div className="input-block mb-3">
                        <label>Work Email<span className="alertstrik">*</span></label>
                        <input
                          type="email"
                          className="form-control"
                          placeholder="Work Email"
                          onChange={handleInputChange}
                          id="work_email"
                          name="work_email"
                          value={profile.work_email}
                        />
                        <small className="error">{errors?.work_email}</small>
                      </div>
                    </div>
                    <div className="col-lg-6 col-12">
                      <div className="input-block mb-3">
                        <label>Company Address</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Company Address"
                          onChange={handleInputChange}
                          id="company_address_line_1"
                          name="company_address_line_1"
                          value={profile.company_address_line_1}
                        />
                      </div>
                    </div>
                    <div className="col-lg-6 col-12">
                      <div className="input-block mb-3">
                        <label>City<span className="alertstrik">*</span></label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="City"
                          onChange={handleInputChange}
                          id="city"
                          name="city"
                          value={profile.city}
                        />
                        <small className="error">{errors?.city}</small>
                      </div>
                    </div>
                    <div className="col-lg-6 col-12">
                      <div className="input-block mb-3">
                        <label>Country<span className="alertstrik">*</span></label>
                        {isCountriesLoaded && isProfileLoaded && (
                          <Select
                            id="country"
                            name="country"
                            defaultValue={profile.country ? { value: profile.country, label: profile.country } : profile.country}

                            onChange={(selectedOption) => handleChangeCustom(selectedOption, 'country')}
                            options={countries}
                            placeholder="Select Country"
                          />
                        )}
                        <small className="error">{errors?.country}</small>
                      </div>
                    </div>
                    <div className="col-lg-6 col-12">
                      <div className="input-block mb-3">
                        <label>State</label>
                        {isCountriesLoaded && isProfileLoaded && (
                          <Select
                            id="state"
                            name="state"
                            defaultValue={profile.state ? { value: profile.state, label: profile.state } : profile.state}
                            onChange={(selectedOption) => handleChangeCustom(selectedOption, 'state')}
                            options={states}
                            placeholder="Select State"
                          />
                        )}

                        <small className="error">{errors?.state}</small>

                      </div>
                    </div>


                    <div className="col-lg-6 col-12">
                      <div className="input-block mb-3">
                        <label>Postal Code</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Postal Code"
                          onChange={handleInputChange}
                          id="postcode"
                          name="postcode"
                          maxLength="10"
                          value={profile.postcode}
                        />
                      </div>
                    </div>

                    <div className="col-lg-6 col-12">
                      <div className="input-block mb-3">


                        <label className="pe-2">{profile.company_business_label ? profile.company_business_label : staticmessages.SETTING_TAX_REG_LABEL}  </label>

                        <Link
                          to="#"
                          data-bs-toggle="modal"
                          data-bs-target="#bank_details"
                        >
                          <i className="fe fe-settings">
                            <FeatherIcon icon="fe-settings" />
                          </i>
                        </Link>





                        <input
                          type="text"
                          className="form-control"
                          placeholder="Company Business Number"
                          maxLength="20"
                          onChange={handleInputChange}
                          id="company_business_number"
                          name="company_business_number"
                          value={profile.company_business_number}
                        />
                      </div>
                    </div>
                    <div className="col-lg-6 col-12">
                      <div className="input-block mb-3">
                        <label>Company PAN</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Company PAN"
                          maxLength="10"
                          onChange={handleInputChange}
                          id="company_pan"
                          name="company_pan"
                          value={profile.company_pan}
                        />
                      </div>
                    </div>
                    <div className="col-lg-6 col-12">
                      <div className="input-block mb-3">
                        <label>Company CIN</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Company CIN"
                          maxLength="25"
                          onChange={handleInputChange}
                          id="company_cin"
                          name="company_cin"
                          value={profile.company_cin}
                        />
                      </div>
                    </div>


                    <div className="col-lg-6 col-12">
                      <div className="input-block mb-3">
                        <label>Currency<span className="alertstrik">*</span></label>
                      

                        {isCountriesLoaded && isProfileLoaded && (
                          <Select
                            id="symbol"
                            name="symbol"
                            defaultValue={profile.symbol ? { value: profile.symbol, label: profile.symbol } : profile.symbol}

                            onChange={(selectedOption) => handleChangeCustomCurrency(selectedOption, 'symbol')}
                            options={currencylist}
                            placeholder="Select Currency"
                          />
                        )}
 <small className="error">{errors?.symbol}</small>

                      </div>
                    </div>
                    <div className="col-lg-6 col-12">
                      <div className="input-block mb-3">
                        <label>Date Format<span className="alertstrik">*</span></label>

                        {isCountriesLoaded && isProfileLoaded && (
                          <Select
                            id="dateformat"
                            name="dateformat"
                            defaultValue={profile.dateformat ? { value: profile.dateformat, label: profile.dateformat } : profile.dateformat}

                            onChange={(selectedOption) => handleChangeCustom(selectedOption, 'dateformat')}
                            options={dateformats}
                            placeholder="Select Date Format"
                          />
                        )}
 <small className="error">{errors?.dateformat}</small>

                      </div>
                    </div>
                    <div className="col-lg-6 col-12">
                      <div className="input-block mb-3">
                        <label>Company Website</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Company Website"
                          onChange={handleInputChange}
                          id="company_website"
                          name="company_website"
                          value={profile.company_website}
                        />
                      </div>
                    </div>
                    <div className="col-lg-6 col-12">
                      <div className="input-block mb-3">
                        <label>Review Link</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Review Link"
                          onChange={handleInputChange}
                          id="review_link"
                          name="review_link"
                          value={profile.review_link}
                        />
                      </div>
                    </div>




                    <div className="col-lg-12">
                      <form onSubmit={handleSubmit} className="text-end">
                        <div className="btn-path text-end">
                          


                          <button
                            type="submit"
                            to="#"
                            className="btn btn-primary"
                            onClick={handleSubmit}
                          >
                            Save Changes
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
       


      {/* PHP Mail Modal */}
      <div className="modal custom-modal fade" id="bank_details" role="dialog">
        <div className="modal-dialog modal-dialog-centered modal-md">
          <div className="modal-content">
            <div className="modal-header border-0 pb-0">
              <div className="form-header modal-header-title php-mail-modal text-start mb-0">
                <h4 className="mb-0">{staticmessages.SETTING_TAX_REG_LABEL}</h4>

              </div>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="col-lg-12 col-md-12">
                  <div className="input-block mb-3">
                    <label></label>
                    {isCountriesLoaded && isProfileLoaded && (
                      <Select
                        id="company_business_label"
                        name="company_business_label"
                        defaultValue={profile.company_business_label ? { value: profile.company_business_label, label: profile.company_business_label } : profile.company_business_label}

                        onChange={(selectedOption) => handleChangeCustom(selectedOption, 'company_business_label')}
                        options={taxblist}
                        placeholder="Tax Registration"
                      />
                    )}
                  </div>
                </div>

              </div>
            </div>
            <div className="modal-footer">
              <Link
                to="#"
                data-bs-dismiss="modal"
                className="btn btn-primary cancel-btn me-2"
              >
                Cancel
              </Link>
              <Link
                to="#"
                data-bs-dismiss="modal"
                className="btn btn-primary paid-continue-btn"
              >
                Save
              </Link>
            </div>
          </div>
        </div>
      </div>
      {/* /PHP Mail Modal */}



    </>
  );
};

ProfileSetting.propTypes = {

  onApplyAction: PropTypes.func.isRequired,
  
};


export default ProfileSetting;
