 /* eslint-disable */ 
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { FilterChart, search } from "../../_components/Imagepath";
import DatePicker from "react-datepicker";

const AddVendorPayment = ({ show, setShow , setFilterOptions,onApplyFilter,handleApplyFilterOptions, title}) => {
  const [isViewAllOpen, setIsViewAllOpen] = useState([]);
  const [startFDate, setFDate] = useState("");
  const [startTDate, setTDate] = useState("");




  const handleViewAllClick = (sectionIndex) => {
    setIsViewAllOpen((prev) => {
      const updatedState = [...prev];
      updatedState[sectionIndex] = !updatedState[sectionIndex];
      return updatedState;
    });
  };


  const handleChangeTDate = (date) => {
    setTDate(date);
    setFilterOptions((prev) => ({
      ...prev,
      tdate: date,
    }));
  };
  
  const handleChangeFDate = (date) => {
    setFDate(date);
    setFilterOptions((prev) => ({
      ...prev,
      fdate: date,
    }));
  };
  
    const handleChange = (e) => {
      const { name, value } = e.target;
  
      console.log(name+'----'+value);
  
      setFilterOptions((filteroption) => ({
        ...filteroption,
        [name]: value, // Use dynamic key
      }));
      //setDate(date);
    };

  const handleSearch = (e) => {
    const searchTerm = e.target.value.toLowerCase();
    onApplyFilter(searchTerm); // Call parent's filter function
  };
  const handleApply = () => {
    handleApplyFilterOptions();
  };


  return (
    <>
      <div className={`toggle-sidebar ${show ? "open-filter" : ""}`}>
        <div className="sidebar-layout-filter">
          <div className="sidebar-header">
            <h5>Filter</h5>
            <button
             
              className="sidebar-closes"
              onClick={() => setShow(!show)}
            >
              <i className="fa-regular fa-circle-xmark" />
            </button>
          </div>
          <div className="sidebar-body">
            <form action="#" autoComplete="off">
        
              
              {/* Select Date */}
              <div className="accordion accordion-last" id="accordionMain2">
              <div className="card-header-new" id="headingTwo">
                <h6 className="filter-title">
                  <a
                    href="#"
                    className="w-100 collapsed"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseTwo"
                    aria-expanded="true"
                    aria-controls="collapseTwo"
                  >
                    Select Date
                    <span className="float-end">
                      <i className="fa-solid fa-chevron-down" />
                    </span>
                  </a>
                </h6>
              </div>
              <div
                id="collapseTwo"
                className="collapse show"
                aria-labelledby="headingTwo "
                data-bs-parent="#accordionExample2"
              >
                <div className="card-body-chat">
                  <div className="input-block mb-3">
                    <label className="form-control-label">From</label>
                    <div className="cal-icon">
                      <DatePicker
                       
                        onChange={handleChangeFDate}
                        className="form-control datetimepicker"
                         dateFormat="dd/MM/yyyy"
                         id="fdate"
                         name="fdate"
                         selected={startFDate}
                      />
                    </div>
                  </div>
                  <div className="input-block mb-3">
                    <label className="form-control-label">To</label>
                    <div className="cal-icon">
                      <DatePicker
                    
                        onChange={handleChangeTDate}
                        className="form-control datetimepicker"
                         dateFormat="dd/MM/yyyy"
                          id="tdate"
                         name="tdate"
                         selected={startTDate}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* /Select Date */}
          
              
              <button
               type="button"
               onClick={handleApply}
                className="d-inline-flex align-items-center justify-content-center btn w-100 btn-primary"
              >
                <span>
                  <img
                    src={FilterChart}
                    className="me-2"
                    alt="Search"
                  />
                </span>
                Search
              </button>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddVendorPayment;
