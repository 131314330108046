/* eslint-disable */

import React, { useState, useEffect } from "react";
import staticmessages from '../../../services/staticmessages';
import { settingslogo1 } from '../../../_components/Imagepath';
import Select from 'react-select';
import UserService from "../../../services/user.service";
import ItemService from '../../../services/item.service';
import InvoiceService from '../../../services/invoice.service';

import alertify from "alertifyjs";
import * as yup from 'yup';
import { useHistory } from 'react-router-dom';

import "../../../../node_modules/alertifyjs/build/css/alertify.css";
import "../../../../node_modules/alertifyjs/build/css/themes/semantic.css";
import config from '../../../services/config';
import { BillwizerLogoLight } from "../../../_components/Imagepath";
// eslint-disable-next-line no-unused-vars
import { convertPercentageDiscount, calculateDiscountOnPercentage, formattedWithFractionLengthDouble, renderTaxData, returnDataValue, PaymentTerms, SubscriptionTerms , calculateTaxableAmountPerItem} from '../../../services/Helper';



const Step6Form = () => {

     // eslint-disable-next-line no-unused-vars
     const [listType, setListType] = useState("estimatelist");
  const history = useHistory();
  const [errors, setErrors] = useState({});
  // Load invoice from localStorage
  const loadInvoiceFromStorage = () => {
    const storedInvoice = localStorage.getItem('invoicewizard');
    return storedInvoice ? JSON.parse(storedInvoice) : null;
  };

  const [invoice, setInvoice] = useState(() => {
    const storedInvoice = loadInvoiceFromStorage();
    return storedInvoice && Object.keys(storedInvoice).length > 0 ? storedInvoice : {};
  });

  useEffect(() => {
    if (invoice) {
      localStorage.setItem('invoicewizard', JSON.stringify(invoice));
    }
  }, [invoice]);

  // const [step6Data, setStep6Data] = useState({
  //   id: invoice.customeritem?.item_id ? invoice.customeritem?.item_id :  "",
  //   item_name: invoice.customeritem?.item_name ? invoice.customeritem?.item_name :  "",
  //   hsn_sac: invoice.customeritem?.hsn_sac ? invoice.customeritem?.hsn_sac :  "",
  //   category: invoice.customeritem?.category ? invoice.customeritem?.category :  "",
  //   unit: invoice.customeritem?.unit ? invoice.customeritem?.unit :  "",
  //   taxrate: invoice.customeritem?.taxrate ? invoice.customeritem?.taxrate :  "",
  //   price: invoice.customeritem?.price ? invoice.customeritem?.price :  "",
  //   quantity:invoice.customeritem?.quantity ? invoice.customeritem?.quantity : 1,
  // });


  const [step6Data, setStep6Data] = useState({
    id:  "",
    item_name:  "",
    hsn_sac:   "",
    category:   "",
    unit:  "",
    taxrate:   "",
    price:  "",
    quantity: 1,
  });


  const step6Schema = yup.object().shape({
    category: yup.string().required('Category is required'),
    item_name: yup.string().required('Item name is required'),
    quantity: yup.string().required('Quantity is required'),
    price: yup.string()
    .matches(/^\d+(\.\d+)?$/, 'Price can only contain numeric characters and an optional decimal point')
    .required('Price is required')
    .test('is-positive', 'Price must be greater than 0', value => {
      const numberValue = parseFloat(value);
      return !isNaN(numberValue) && numberValue > 0;
    }),
    
  
    taxrate: yup
    .string()
    .matches(/^(?:\d*\.?\d+)?$/, 'Tax Rate can only contain numeric characters and an optional decimal point')
    .nullable()
    .test('is-empty-or-valid-number', 'Tax Rate must be less than or equal to 100', function(value) {
      if (value === '' || value === undefined || value === null) {
        return true;
      }
      const numberValue = parseFloat(value);
      return !isNaN(numberValue) && numberValue <= 100;
    })
  
  });

  const [item, setItem] = useState({});

  const [categories, setCategories] = useState([]);

  useEffect(() => {
    fetchCategories();
    fetchUnits();

    if (location.state?.item) {
      setItem(location.state.item);


      setItem(prevInvoice => ({
        ...prevInvoice,
        taxrate: item.taxrate ? item.taxrate : '',

      }));


    } else {
      // Handle case for adding new item if needed
    }
  }, []);

  const fetchCategories = () => {
    ItemService.fetchCategories()
      .then((response) => {
        const options2 = response.data.map((item) => ({
          value: item.name,
          label: item.name,
        }));
        console.error(options2);
        setCategories(options2);
      })
      .catch((error) => {
        console.error('Error fetching categories:', error);
      });
  };

  const fetchUnits = () => {
    ItemService.fetchUnits()
      .then((response) => {
        const tempunits = response.data.map((item) => ({
          value: item.name,
          label: item.name,
        }));

        console.error(tempunits);
        setUnits(tempunits);
      })
      .catch((error) => {
        console.error('Error fetching units:', error);
      });
  };


  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setStep6Data(prevState => ({
      ...prevState,
      [name]: value,
    }));
  };


  const handleChangeCustom = (selectedOption, name) => {
    setStep6Data(prevState => ({
      ...prevState,
      [name]: selectedOption ? selectedOption : '',
    }));
  };

  const handleChangeCustomSelect = (selectedOption, name) => {



    setStep6Data(prevState => ({
      ...prevState,
      [name]: selectedOption ? selectedOption.value : '',
    }));
  };

  const handlePrevious = () => {
    history.push('/step5form');
  };
  // const handleNext = () => {
  //   history.push('/step5form');
  // };

  const validateStep = async () => {
    let schema;
    let data;

    schema = step6Schema;
    data = step6Data;



    try {
      await schema.validate(data, { abortEarly: false });
      setErrors({});
      return data; // Return validated data
    } catch (err) {
      const newErrors = {};
      err.inner.forEach((error) => {
        newErrors[error.path] = error.message;
      });
      setErrors(newErrors);
      return null;
    }
  };


  const handleSaveAction = async (updatedInvoice) => {
    console.error("handleSaveAction API invoice:", updatedInvoice);
  
    const action = InvoiceService.addnewInvoiceWebFormWiz;
    action(updatedInvoice.id, updatedInvoice, listType)
      .then((response) => {
        console.error("Invoice API response:", response);
  
        if (response.data[0].id) {
          // let mess = `${customtitle} ${staticmessages.DEF_USUCCESS}`;
          // toastrSuccess(mess);
          setInvoice(null);
          history.push('estimate-list');
        }
      })
      .catch((invoiceError) => {
       // alertify.error("Failed to add invoice. Please try again.");
        console.error("Invoice API Error:", invoiceError);
      });
  };


  const [clicksave, setClickSave] = useState(false);
  const fixInvoiceItem = async (response) => {
    try {
      setInvoice((prevInvoice) => ({
        ...prevInvoice,
        customeritem: [response], // Ensure item is correctly handled
      }));
    } catch (error) {
      console.error("Error updating invoice:", error);
    }
  };
  
  // Trigger API call when invoice is updated
  useEffect(() => {
    if (clicksave && invoice.customeritem && invoice.customeritem.length > 0) {
      handleSaveAction(invoice);
    }
  }, [invoice]);

  useEffect(() => {
    
  }, [item.id]);  // Make sure item.id is passed as a dependency
  

  const handleNext = async () => {

    
    const validatedData = await validateStep();

    if (validatedData) {
      try {
        let response;
        let tempresponse;

        tempresponse = await  ItemService.updateItemOnboard(0, validatedData)
        response = tempresponse.data[0];


        console.log("API Error:", response);

        if (response && response.id) {
          
          fixInvoiceItem(response);
         
          setClickSave(true);
          
          


        } else {
          throw new Error("Unexpected response from server.");
        }
      } catch (error) {
        alertify.set("notifier", "position", "top-center");
        alertify.error("Failed to save data. Please try again.");
        console.error("API Error:", error); // Log error details for debugging
      }
    } else {
      alertify.set("notifier", "position", "top-center");
      alertify.error("Please fill all the required fields to proceed.");
    }
  };

  const [countriesorg, setCountriesOrg] = useState([]);
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [isCountriesLoaded, setIsCountriesLoaded] = useState(false);
  const [dateformats, setDateFormats] = useState([]);
  const [currencylist, setCurrencyList] = useState([]);

  const [getuser, SetUser] = useState([]);

  const fetchData = async () => {
    await fetchDateFormat();
    await fetchCurrencyList();
    await fetchCountries();
    await fetchProfile();
    await getDefaultTax();
    await loadInvoiceNo();


  
  };


  useEffect(() => {
    if (step6Data.country && isCountriesLoaded) {
      fetchStates(step6Data.country);
    }
  }, [step6Data.country, isCountriesLoaded]); // Only fetch states when the country is selected and countries are loaded


  const loadInvoiceNo = async (listType) => {
    try {
      const response = await InvoiceService.loadInvoiceNo(invoice.id, false, listType);


      setInvoice(prevInvoice => ({
        ...prevInvoice,
        invoice_number: response.invoice_number
      }));

    } catch (error) {
      console.error('Error fetching countries:', error);
    }
  };


    // eslint-disable-next-line no-unused-vars
    const getDefaultTax = async () => {
      try {
  
        let res = await InvoiceService.getDefaultTax();
       // setNewInitTax(res.data);
  
        console.log("TaxMAster debug 00000---getDefaultTax: " + JSON.stringify(res, null, 2));
        console.log("checkTaxLabel 00000---taxdata:" + JSON.stringify(invoice.taxdata, null, 2));
        console.log("TesthandleSaveAction 00000---taxarray:" + JSON.stringify(invoice.taxarray, null, 2));
        console.log("TesthandleSaveAction 00000---selectedinvoice: " + JSON.stringify(invoice, null, 2));
  
        console.log("CountFire 1111"+ JSON.stringify(invoice.taxdata, null, 2));
        console.log("CountFire 1111aa"+ JSON.stringify(invoice.defaulttaxdata, null, 2));


        setInvoice((prevInvoice) => {
          const updatedInvoice = {
            ...prevInvoice,
          
            taxdata: res.data,
            defaulttaxdata: res.data,
          };
          console.log("setInvoice API Error 333 :", updatedInvoice);
          return updatedInvoice;
        });

  
  
        console.log("CountFire 1111bb"+ JSON.stringify(invoice.defaulttaxdata, null, 2));
  
      } catch (error) {
        console.error('Login failed:', error);
      }
    };
  

  const fetchProfile = async () => {
    UserService.getUserProfile()
      .then((response) => {

        


        setInvoice((prevInvoice) => ({
          ...prevInvoice,
          invoice_taxrate : response.data.taxrate,
          selectedtax : response.data.selectedtax,
          payment_terms: 'DUE_ON_RECEIPT',
          symbol : response.data.symbol,
          currency : response.data.currency,
          dateformat : response.data.dateformat,

        }));

        SetUser(response.data);

        if(response.data.onboard)
        {
          history.push('index');
        }


      })
      .catch((error) => {
        console.error("UserService error:", error);
      });
  };


  useEffect(() => {
    fetchCountries();

    fetchData();
    // fetchChangeCurrCountry();


  }, [location.state, isCountriesLoaded]);

  const getCountryId = (countryName) => {
    console.error('handleChangeCustom countryName:', countryName);

    console.error(countries);
    console.log(countryName);
    countries.forEach(country => console.log(country.country_name));

    const country = countriesorg.find((country) => country.country_name === countryName);
    return country ? country.id : null;
  };

  const fetchStates = async (country) => {
    console.error('fetchStates found' + country);
    try {
      const countryId = getCountryId(country);
      console.error('fetchStates countryId' + countryId);
      if (countryId) {
        const response = await UserService.fetchStates(countryId);

        const responsestate = response.data.map((item) => ({
          value: item.name,
          label: item.name,
        }));

        setStates(responsestate);
      } else {
        console.error('Country not found');
      }
    } catch (error) {
      console.error('Error fetching states:', error);
    }
  };


  const fetchCountries = async () => {
    try {
      const response = await UserService.fetchCountries();

      setCountriesOrg(response.data);

      const responsecountry = response.data.map((item) => ({
        value: item.country_name,
        label: item.country_name,
      }));


      setCountries(responsecountry);
      setIsCountriesLoaded(true);
    } catch (error) {
      console.error('Error fetching countries:', error);
    }
  };


  const fetchCurrencyList = async () => {
    try {
      const response = await UserService.getCurrencyList();

      const responsecurrencylist = response.data.map((item) => ({
        value: `${item.currency}#${item.symbol}`,
        label: `${item.name} (${item.symbol})`,
      }));
      setCurrencyList(responsecurrencylist);

    } catch (error) {
      console.error('Error fetching countries:', error);
    }
  };
  const fetchDateFormat = async () => {
    try {
      const response = await UserService.dateFormatList();

      const responsedateformat = response.data.map((item) => ({
        value: item.dateformat,
        label: item.dateformat,
      }));
      setDateFormats(responsedateformat);

    } catch (error) {
      console.error('Error fetching countries:', error);
    }
  };





      // Inside your component
useEffect(() => {
  setInvoice((prevInvoice) => {
    const updatedInvoice = {
      ...prevInvoice,

      date: Date.now(),
      datetimestamp: Date.now(),
      datetimestampdue: Date.now(),
    
      customeritem: {
        ...prevInvoice.customeritem,

        quantity: step6Data.quantity,
        price: step6Data.price,
        item_name: step6Data.item_name,
        category: step6Data.category,
        taxrate: step6Data.taxrate,
     
      }
    };
    console.log("setInvoice API Error 333 :", updatedInvoice);
    return updatedInvoice;
  });
}, [step6Data.quantity,step6Data.price,step6Data.item_name,step6Data.category,step6Data.taxrate]);





  const [currformat, setCurrFormat] = useState(false);
    // eslint-disable-next-line no-unused-vars
    const renderTaxDatalocal = () => {
      // Your logic to update taxdata
  
     
  
      if ((invoice.customer && invoice.defaulttaxdata) || (invoice.customer && invoice.taxdata)) {
  
        let taxarray = renderTaxData(invoice.defaulttaxdata, currformat, invoice, staticmessages.DEFINDIANDECIMALPLACE);
  
        console.log("TesthandleSaveAction 00000 555 FFF ---" + JSON.stringify(taxarray, null, 2));
  
      
  
        if (JSON.stringify(invoice.taxdata) !== JSON.stringify(taxarray)) {
          setInvoice((prevEstimate) => ({
            ...prevEstimate,
            taxdata: taxarray,
          }));
        }
      }
  
    };
  
    useEffect(() => {
      console.log("Helper renderTaxDatalocalzzz--" + invoice.taxarray);
      console.log(invoice);
      renderTaxDatalocal();
    }, [invoice.gtotal, invoice.subtotal, invoice.taxdata, invoice.selectedtax, invoice.customer, invoice.customeritem]);
  
  


  
  return (
    <>
     
     <div className="main-wrapper container d-flex align-items-center justify-content-center boardingwizard" style={{ minHeight: "80vh" }}>
    <div className="content container-fluid">
    <img
          className="img-fluid logo-dark mb-2"
          src={BillwizerLogoLight}
          alt="Logo"
        />
    <div className="row justify-content-center">
        <div className="col-lg-6 col-md-6 col-sm-12">
          <div className="card">
            <div className="card-header">
            
            </div>
          
            <div className="card-body">
            

              <form autoComplete="off">
                  <div className="mb-4">
                
                    <h5>{staticmessages.SETTING_step6Data}</h5>
                  
                  </div>
                



                  <div className="row">
                    <div className="col-lg-6">
                      <div className="input-block mb-3">
                        <label>Category<span className="alertstrik">*</span></label>
          
                        <Select
                          defaultValue={item.category ? { value: item.category, label: item.category } : item.category}
                          id="category"
                          name="category"
                          onChange={(selectedOption) => handleChangeCustomSelect(selectedOption, 'category')}
                          options={categories}
                          placeholder="Select Category"
                          className="fixzindex"
                        />
          
                        {errors.category && <small className="error">{errors.category}</small>}
                      </div>
                    </div>
          
                    <div className="col-lg-6">
                      <div className="input-block mb-3">
                        <label htmlFor="quantity" className="form-label">
                          Quantity
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="quantity"
                          name="quantity"
                          placeholder="Enter Quantity"
                          value={step6Data.quantity}
                          onChange={handleInputChange}
                        />
                        {errors.quantity && <small className="error">{errors.quantity}</small>}
                      </div>
                    </div>
          
                    <div className="row">
                      <div className="col-lg-6">
                        <div className="input-block mb-3">
                          <label>
                            {staticmessages.ITEMS_LABEL_SINGLEITEM} Name<span className="alertstrik">*</span>
                            (Description)
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="item_name"
                            name="item_name"
                            placeholder={`Enter ${staticmessages.ITEMS_LABEL_SINGLEITEM} Name`}
                            value={step6Data.item_name}
                            onChange={handleInputChange}
                          />
                          {errors.item_name && <small className="error">{errors.item_name}</small>}
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="input-block mb-3">
                          <label>
                            Price<span className="alertstrik">*</span>
          
                          </label>
          
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter Selling Price"
                            onChange={handleInputChange}
                            id="price"
                            name="price"
                            maxLength={10}
                            value={item.price}
                            onClick={(e) => e.target.select()}
                          />
          
                          {errors.price && <small className="error">{errors.price}</small>}
                        </div>
                      </div>
                    </div>
          
          
                    {getuser.selectedtax === "peritem" && (
                      <div className="row">
                        <div className="col-lg-6">
                          <div className="input-block mb-3">
                            <label htmlFor="city" className="form-label">
                              Tax Rate{' '}
                            </label>
                            <input
                              type="number"
                              className="form-control"
                              placeholder="Enter Tax Rate"
                              onChange={handleInputChange}
                              id="taxrate"
                              name="taxrate"
                              maxLength={5}
                              value={item.taxrate}
                              onClick={(e) => e.target.select()}
                            />
                            {errors.taxrate && <small className="error">{errors.taxrate}</small>}
                          </div>
                        </div>
                      </div>
                    )}
          
          
          
          
          
          
                  </div>



                </form>


                <div className="d-flex justify-content-center">

                  <button className="btn btn-primary me-2" onClick={handlePrevious}>
                    Back
                  </button>

                  <button className="btn btn-primary" type="button" onClick={handleNext}>
                    Next
                  </button>
                </div>

              </div>


            </div>
          </div>
        </div>
      </div>
  
      </div>

    </>
  )
}




export default Step6Form;

