/* eslint-disable */

import React, { useState, useEffect } from "react";
import staticmessages from '../../../services/staticmessages';
import { settingslogo1 } from '../../../_components/Imagepath';
import Select from 'react-select';
import UserService from "../../../services/user.service";
import ClientService from '../../../services/client.service';
import alertify from "alertifyjs";
import * as yup from 'yup';
import { useHistory } from 'react-router-dom';

import "../../../../node_modules/alertifyjs/build/css/alertify.css";
import "../../../../node_modules/alertifyjs/build/css/themes/semantic.css";
import config from '../../../services/config';

import { BillwizerLogoLight } from "../../../_components/Imagepath";


const Step5Form = () => {


  const history = useHistory();
  const [errors, setErrors] = useState({});
  // Load invoice from localStorage
  const loadInvoiceFromStorage = () => {
    const storedInvoice = localStorage.getItem('invoicewizard');
    return storedInvoice ? JSON.parse(storedInvoice) : null;
  };

  const [invoice, setInvoice] = useState(() => {
    const storedInvoice = loadInvoiceFromStorage();
    return storedInvoice && Object.keys(storedInvoice).length > 0 ? storedInvoice : {};
  });

  useEffect(() => {
    if (invoice) {
      localStorage.setItem('invoicewizard', JSON.stringify(invoice));
    }
  }, [invoice]);


  const step5Schema = yup.object().shape({
    client_name: yup.string().required("Client Name is required"),
    city: yup.string().required("City is required"),
    country: yup.string().required("Country is required"),
  
    state: yup.string().test(
      'state-required',
      'State is required',
      function(value) {
        const { country } = this.parent;
        return (country === 'India' || country === 'United States') ? !!value : true;
      }
    ),
  
  });

  
  const [step5Data, setStep5Data] = useState({
    client_id: invoice.customer?.id ? invoice.customer?.id :  "",
    client_name: invoice.customer?.client_name ? invoice.customer?.client_name :  "",
    address: invoice.customer?.address ? invoice.customer?.address :  "",
    city: invoice.customer?.city ? invoice.customer?.city :  "",
    country: invoice.customer?.country ? invoice.customer?.country :  "",
    state: invoice.customer?.state ? invoice.customer?.state :  "",
  });

    // Inside your component
useEffect(() => {
  setInvoice((prevInvoice) => {
    const updatedInvoice = {
      ...prevInvoice,
    
      customer: {
        ...prevInvoice.customer,

        client_id: step5Data.client_id,
        client_name: step5Data.client_name,
        address: step5Data.address,
     
        city: step5Data.city,
        state: step5Data.state,
        country: step5Data.country,
      }
    };
    console.log("setInvoice API Error 333 :", updatedInvoice);
    return updatedInvoice;
  });
}, [step5Data.client_name,step5Data.address,step5Data.client_id,step5Data.city,step5Data.state,step5Data.country]);


  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setStep5Data(prevState => ({
      ...prevState,
      [name]: value,
    }));
  };


  const handleChangeCustom = (selectedOption, name) => {

    setStep5Data(prevState => ({
      ...prevState,
      [name]: selectedOption ? selectedOption.value : '',
    }));


   
    if (name === 'country') {
      fetchStates(selectedOption.value);

    }

  };

  const handlePrevious = () => {
    history.push('/step4form');
  };

  // const handleNext = () => {
  //   history.push('/step5form');
  // };


  const validateStep = async () => {
    let schema;
    let data;

    schema = step5Schema;
    data = step5Data;



    try {
      await schema.validate(data, { abortEarly: false });
      setErrors({});
      return data; // Return validated data
    } catch (err) {
      const newErrors = {};
      err.inner.forEach((error) => {
        newErrors[error.path] = error.message;
      });
      setErrors(newErrors);
      return null;
    }
  };

  const handleSaveAction = async (validatedData) => {
    const action = validatedData.id ? ClientService.addupdateClientWebWizard : ClientService.addupdateClientWebWizard;
    try {
      let response = await action(validatedData.id, validatedData);
      return response;

    } catch (error) {
      return error;
    }
  };
  
  useEffect(() => {
    console.log("Updated invoice state:", invoice);
  }, [invoice]);
  
  const handleNext = async () => {
    const validatedData = await validateStep();

    if (validatedData) {
      try {
        let response;
        let tempresponse;

        tempresponse = await  handleSaveAction(validatedData);
        response = tempresponse.data;

        console.log("API Error handleNext:", response);
       // Update invoice with new customer data
      setInvoice((prevInvoice) => ({
        ...prevInvoice,  // Spread previous invoice state
        customer: {      // Update customer field with the new data from response
          client_id: response.id || "",
          client_name: response.client_name || "",
          address: response.address || "",
          city: response.city || "",
          state: response.state || "",
          country: response.country || "",
        },
      }));



       

        if (response && response.id) {
          
          
         history.push('step6form');


        } else {
          throw new Error("Unexpected response from server.");
        }
      } catch (error) {
        alertify.set("notifier", "position", "top-center");
        alertify.error("Failed to save data. Please try again.");
        console.error("API Error:", error); // Log error details for debugging
      }
    } else {
      alertify.set("notifier", "position", "top-center");
      alertify.error("Please fill all the required fields to proceed.");
    }
  };


  const [countriesorg, setCountriesOrg] = useState([]);
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [isCountriesLoaded, setIsCountriesLoaded] = useState(false);
  const [dateformats, setDateFormats] = useState([]);
  const [currencylist, setCurrencyList] = useState([]);

  const fetchData = async () => {
    await fetchDateFormat();
    await fetchCurrencyList();
    await fetchCountries();


    if (step5Data.country) {
      console.error('Error fetching 222:', "222");
      // setClient(location.state.client);
      if (isCountriesLoaded) {
        fetchStates(step5Data.country);
      }
    }
  };
  useEffect(() => {
    fetchCountries();

    fetchData();
    // fetchChangeCurrCountry();

    


  }, [location.state, isCountriesLoaded]);

  const getCountryId = (countryName) => {
    console.error('handleChangeCustom countryName:', countryName);

    console.error(countries);
    console.log(countryName);
    countries.forEach(country => console.log(country.country_name));

    const country = countriesorg.find((country) => country.country_name === countryName);
    return country ? country.id : null;
  };

  const fetchStates = async (country) => {
    console.error('fetchStates found' + country);
    try {
      const countryId = getCountryId(country);
      console.error('fetchStates countryId' + countryId);
      if (countryId) {
        const response = await UserService.fetchStates(countryId);

        const responsestate = response.data.map((item) => ({
          value: item.name,
          label: item.name,
        }));

        setStates(responsestate);
      } else {
        console.error('Country not found');
      }
    } catch (error) {
      console.error('Error fetching states:', error);
    }
  };

  useEffect(() => {
     fetchProfile();
  }, []); 

  const fetchProfile = async () => {
    UserService.getUserProfile()
      .then((response) => {

  

        if(response.data.onboard)
        {
          history.push('index');
        }


      })
      .catch((error) => {
        console.error("UserService error:", error);
      });
  };
  const fetchCountries = async () => {
    try {
      const response = await UserService.fetchCountries();

      setCountriesOrg(response.data);

      const responsecountry = response.data.map((item) => ({
        value: item.country_name,
        label: item.country_name,
      }));


      setCountries(responsecountry);
      setIsCountriesLoaded(true);
    } catch (error) {
      console.error('Error fetching countries:', error);
    }
  };


  const fetchCurrencyList = async () => {
    try {
      const response = await UserService.getCurrencyList();

      const responsecurrencylist = response.data.map((item) => ({
        value: `${item.currency}#${item.symbol}`,
        label: `${item.name} (${item.symbol})`,
      }));
      setCurrencyList(responsecurrencylist);

    } catch (error) {
      console.error('Error fetching countries:', error);
    }
  };
  const fetchDateFormat = async () => {
    try {
      const response = await UserService.dateFormatList();

      const responsedateformat = response.data.map((item) => ({
        value: item.dateformat,
        label: item.dateformat,
      }));
      setDateFormats(responsedateformat);

    } catch (error) {
      console.error('Error fetching countries:', error);
    }
  };
  return (
    <>
    <div className="main-wrapper container d-flex align-items-center justify-content-center boardingwizard" style={{ minHeight: "80vh" }}>
    <div className="content container-fluid">
    <img
          className="img-fluid logo-dark mb-2"
          src={BillwizerLogoLight}
          alt="Logo"
        />
    <div className="row justify-content-center">
        <div className="col-lg-6 col-md-6 col-sm-12">
          <div className="card">
            <div className="card-header">
            
            </div>
          
            <div className="card-body">
            

              <form autoComplete="off">
                  <div className="mb-4">
                    <h5>{staticmessages.SETTING_step5Data}</h5>
                  </div>
                  <div className="row">
                    <div className="col-lg-6">
                      <div className="input-block mb-3">
                        <label htmlFor="client_name" className="form-label">
                          Client Name<span className="alertstrik">*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="client_name"
                          name="client_name"
                          placeholder="Enter Client Name"
                          value={step5Data.client_name}
                          onChange={handleInputChange}
                        />
                        {errors.client_name && <small className="error">{errors.client_name}</small>}
                      </div>
                    </div>

                  
                      <div className="col-lg-6">
                        <div className="input-block mb-3">
                          <label htmlFor="city" className="form-label">
                            City<span className="alertstrik">*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="city"
                            placeholder="Enter City"
                            name="city"
                            value={step5Data.city}
                            onChange={handleInputChange}
                          />
                          {errors.city && <small className="error">{errors.city}</small>}
                        </div>
                      </div>
                  

                    <div className="col-lg-12">
                      <div className="input-block mb-3">
                        <label htmlFor="address" className="form-label">
                          Address
                        </label>
                        <textarea
                          type="text"
                          className="form-control"
                          id="address"
                          name="address"
                          placeholder="Enter Address"
                          value={step5Data.address}
                          onChange={handleInputChange}
                            rows="2"
                        />
                        {errors.address && <small className="error">{errors.address}</small>}
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-lg-6">
                        <div className="input-block mb-3">
                          <label htmlFor="country" className="form-label">
                            Country<span className="alertstrik">*</span>
                          </label>

                          {isCountriesLoaded && (
                            <Select
                              id="country"
                              name="country"
                              defaultValue={step5Data.country ? { value: step5Data.country, label: step5Data.country } : step5Data.country}

                              onChange={(selectedOption) => handleChangeCustom(selectedOption, 'country')}
                              options={countries}
                              placeholder="Select Country"
                              className="fixzindex"
                            />



                          )}


                          {errors.country && <small className="error">{errors.country}</small>}
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="input-block mb-3">
                          <label htmlFor="state" className="form-label">
                            State
                          </label>
                          {isCountriesLoaded && (
                            <Select
                              id="state"
                              name="state"
                              defaultValue={step5Data.state ? { value: step5Data.state, label: step5Data.state } : step5Data.state}
                              onChange={(selectedOption) => handleChangeCustom(selectedOption, 'state')}
                              options={states}
                              placeholder="Select State"
                              className="fixzindex2"
                            />
                          )}
                          {errors.state && <small className="error">{errors.state}</small>}
                        </div>
                      </div>
                    </div>


             





                  </div>



                </form>


                <div className="d-flex justify-content-center">

                  <button className="btn btn-primary me-2" onClick={handlePrevious}>
                    Back
                  </button>

                  <button className="btn btn-primary" type="button" onClick={handleNext}>
                    Next
                  </button>
                </div>

              </div>


            </div>
          </div>
        </div>
      </div>
  
      </div>
      </>
    )
  }




export default Step5Form;

