import React, { useState ,useEffect } from "react";
import Header from "../layouts/Header";

// eslint-disable-next-line no-unused-vars
import Sidebar from "../layouts/Sidebar";
  // eslint-disable-next-line no-unused-vars
  import ReportModalTaxModel from "../common/modal/reportModalTaxRegister";
// eslint-disable-next-line no-unused-vars
import BreadcrumbsReport from "../common/breadcrumb/breadCrumbsReport";
import { Table } from "antd";

  // eslint-disable-next-line no-unused-vars
import { Link } from "react-router-dom";

// eslint-disable-next-line no-unused-vars
import {formatDateISO, getCurrentDateISO, getExtendDateISO,returnDataValue} from '../services/Helper';

import InvoiceService from '../services/invoice.service';

  // eslint-disable-next-line no-unused-vars
import report from '../services/reports.service';
  // eslint-disable-next-line no-unused-vars
import staticmessages from '../services/staticmessages';



  // eslint-disable-next-line no-unused-vars
  import Spinners from '../components/spinners';


const TaxRegister = () => {
  const [menu, setMenu] = useState(false);
  const [show, setShow] = useState(false);
      // eslint-disable-next-line no-unused-vars
      const [page, setPage] = useState(1);
      // eslint-disable-next-line no-unused-vars
      const paginationSize = 10;

    // eslint-disable-next-line no-unused-vars
  const [datasource, setInvoices] = useState([]);
    // eslint-disable-next-line no-unused-vars
  const [filteredDataSource, setFilteredDataSource] = useState([]);

    // eslint-disable-next-line no-unused-vars
  const [listType, setListType] = useState("invoicelist");

  const [isbh, setBH] = useState(false);

  const [taxlabel, setOtherCountryTaxLAbel] = useState("");

  //       <ReportModal setShow={setShow} show={show} customtitle={customtitle} listType = {listType} fdate = {fdate} tdate = {tdate} />

    // eslint-disable-next-line no-unused-vars

// eslint-disable-next-line no-unused-vars
const [loading, setLoading] = useState(true); // Loading state
  
  let currentdate =   new Date();
  let currentdateex =  new Date(currentdate.getTime() - (30 * 24 * 60 * 60 * 1000));




  const [filteroption, setFilterOptions] = useState({linkModeType: "invoicelist",fdate: currentdateex,tdate: currentdate,filterText:"",client_id:"", is_export:false});


  const toggleMobileMenu = () => {
    setMenu(!menu);
  };
  const [currformat, setCurrFormat] = useState("");

  useEffect(() => {
    getUser();
      getTaxRegister();
   
  }, []);


   
      
    const getUser = async () => {
      try {
        let res = await InvoiceService.getUserProfile();

        console.error('Login getUser:', res);
        setCurrFormat(res.data.symbol);
        if(res.data.country === 'India') 
        {
          setBH(true);
        }
        else{
          setOtherCountryTaxLAbel(res.data.custom_company_business_label ? res.data.custom_company_business_label : res.data.company_business_label);
        }
      
      
      } catch (error) {
        console.error('Login failed:', error);
      }
    };

  const getTaxRegister = async () => {

   

    report.getTaxRegisterReport(listType, filteroption)
      .then((response) => {
        // setInvoices(response.data);
        // setFilteredDataSource(response.data);

        console.log(response);

        setInvoices(response.data);
        setFilteredDataSource(response.data);
        setLoading(false);

      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  const handleApplyFilter = (searchTerm) => {
    setShow(false);

    setSearchTerm(searchTerm);
  
    // setFilterOptions((prev) => ({
    //   ...prev,
    //   searchterm: searchTerm
    // }));


    getTaxRegister(); // Reload parent data
  };

  
  const handleApplyFilterOptions = () => {
    setShow(false);


    getTaxRegister(); // Reload parent data
  };



  const [forecereset, setForecereset] = useState(false);
  const handleApplyResetApply = () => {

    setFilterOptions((prev) => ({
      ...prev,
      searchterm: "",fdate:currentdateex ,tdate:currentdate, client_id: null
    }));
    setSearchTerm("");
   
    setShow(false);
    
    setForecereset(true);
  };

  useEffect(() => {
    if (forecereset) {
     
      getTaxRegister(); // Reload parent data
      setForecereset(false);
    }
  }, [forecereset]);




  const downloadCSV = (data, filename = 'TaxRegister.csv') => {
    //const csv = convertToCSV(data);
    const blob = new Blob([data], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    const url = URL.createObjectURL(blob);
    link.setAttribute('href', url);
    link.setAttribute('download', filename);
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const clickDownload = () => {

    report.getTaxRegisterReportDownload(listType, filteroption)
      .then((response) => {
        // setInvoices(response.data);
        // setFilteredDataSource(response.data);

       downloadCSV(response);

        console.log(response);

       

      })
      .catch((error) => {
        console.log(error);
      });
  
  };

  const handleTableChange = (pagination) => {
    setPage(pagination.current);
  };



  const initialTotals = {
    totalIGST: 0,
    totalCGST: 0,
    totalSGST: 0,
    totalglobaltax: 0,
    totalTaxableAmount: 0
  };
   
 

  const totals = filteredDataSource.reduce((acc, item) => {
    const { igst, cgst, sgst, taxable_amount, globaltax } = item;
    acc.totalIGST += parseFloat(igst) || 0;
    acc.totalCGST += parseFloat(cgst) || 0;
    acc.totalSGST += parseFloat(sgst) || 0;
    acc.totalTaxableAmount += parseFloat(taxable_amount) || 0;
    acc.totalglobaltax += parseFloat(globaltax) || 0;
    return acc;
  }, initialTotals);

  // Define common columns
  const commonColumns = [
    {
      title: "#",
      dataIndex: "id",
      render: (text, record, index) => (page - 1) * paginationSize + index + 1,
    },
    {
      title: "Invoice No.",
      dataIndex: "invoice_number",
      render: (text, record) => (
        <>
          <span>{record.invoice_number}</span><br />
          <span> ({returnDataValue(staticmessages.TAX_SETTING, record.selectedtax, "label")})</span>
        </>
      ),
      sorter: (a, b) => a.invoice_number.localeCompare(b.invoice_number),
    },
    {
      title: "Category",
      dataIndex: "category",
    },
    {
      title: "HSN/SAC",
      dataIndex: "hsn_sac",
    },
    {
      title: "Tax %",
      dataIndex: "taxrate",
    },
   
  ];

  // Define specific columns based on `isbh`
  const taxColumns = isbh ? [
    {
      title: "SGST",
      dataIndex: "sgst",
      render: (text, record) => (
        <span>{record.symbol}{record.sgst}</span>
      ),
      sorter: (a, b) => a.sgst - b.sgst,
    },
    {
      title: "CGST",
      dataIndex: "cgst",
      render: (text, record) => (
        <span>{record.symbol}{record.cgst}</span>
      ),
      sorter: (a, b) => a.cgst - b.cgst,
    },
    {
      title: "IGST",
      dataIndex: "igst",
      render: (text, record) => (
        <span>{record.symbol}{record.igst}</span>
      ),
      sorter: (a, b) => a.igst - b.igst,
    },
    {
      title: "Taxable Amount",
      dataIndex: "taxable_amount",
      render: (text, record) => (
        <>
          <span>{record.symbol}{record.taxable_amount}</span>
        </>
      ),
      sorter: (a, b) => a.datetimestamp - b.datetimestamp,
    },
    {
      title: "Date",
      dataIndex: "datetimestamp",
      render: (text, record) => (
        <>
          <span>{formatDateISO(record.datetimestamp)}</span>
        </>
      ),
      sorter: (a, b) => a.datetimestamp - b.datetimestamp,
    },
  ] : [
    {
      title: taxlabel ? taxlabel : "TAX",
      dataIndex: "globaltax",
      render: (text, record) => (
        <span>{record.symbol}{record.globaltax}</span>
      ),
      sorter: (a, b) => a.globaltax - b.globaltax,
    },
    {
      title: "Taxable Amount",
      dataIndex: "taxable_amount",
      render: (text, record) => (
        <>
          <span>{record.symbol}{record.taxable_amount}</span>
        </>
      ),
      sorter: (a, b) => a.datetimestamp - b.datetimestamp,
    },
    {
      title: "Date",
      dataIndex: "datetimestamp",
      render: (text, record) => (
        <>
          <span>{formatDateISO(record.datetimestamp)}</span>
        </>
      ),
      sorter: (a, b) => a.datetimestamp - b.datetimestamp,
    },
  ];

  // Combine common columns and specific columns
  const columns = [...commonColumns, ...taxColumns];



  const [searchTerm, setSearchTerm] = useState(""); 


  const forceMenuClose = () => {  
    setMenu(false);
  };

if (loading) {
  return <Spinners />; // Display the spinner while loading
}

  return (
    <>
      <div className={`main-wrapper ${menu ? "slide-nav" : ""}`}>
      <Header onMenuClick={() => toggleMobileMenu()}  forceMenuClose={forceMenuClose}  onApplyFilter={handleApplyFilter} searchTerm = {searchTerm} />
        <Sidebar />
        <div className="page-wrapper">
          <div className="content container-fluid">
        
            <BreadcrumbsReport
              title={staticmessages.REPORT_TAXREGISTER}
              show={show}
              setShow={setShow}
              clickDownload = {clickDownload}
            />
            <ReportModalTaxModel setShow={setShow} show={show}   filteroption = {filteroption} setFilterOptions ={setFilterOptions} 
            handleApplyFilterOptions = {handleApplyFilterOptions} handleApplyResetApply = {handleApplyResetApply}  />
            <div className="row">
              <div className="col-sm-12">
                <div className="card invoiceList">
                  <div className="card-body invoiceList">
                    <div className="table-responsive table-hover">
                    <Table
                      className="table"
                      pagination={{
                        total: filteredDataSource.length,
                        showTotal: (total, range) =>
                          `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                        showSizeChanger: true,
                      
                      }}
                      columns={columns}
                      dataSource={filteredDataSource}
                      rowKey={(record) => record.id}
                      onChange={handleTableChange}

                      summary={() => (
                        <>
                          {isbh ? (
                            <Table.Summary.Row>
                              <Table.Summary.Cell colSpan={columns.length - 5}>Total</Table.Summary.Cell>
                              <Table.Summary.Cell>{currformat}{totals.totalCGST.toFixed(2)}</Table.Summary.Cell>
                              <Table.Summary.Cell>{currformat}{totals.totalSGST.toFixed(2)}</Table.Summary.Cell>
                              <Table.Summary.Cell>{currformat}{totals.totalIGST.toFixed(2)}</Table.Summary.Cell>
                              <Table.Summary.Cell>{currformat}{totals.totalTaxableAmount.toFixed(2)}</Table.Summary.Cell>
                            </Table.Summary.Row>
                          ) : (
                            <Table.Summary.Row>
                              <Table.Summary.Cell colSpan={columns.length - 3}>Total</Table.Summary.Cell>
                              <Table.Summary.Cell>{currformat}{totals.totalglobaltax.toFixed(2)}</Table.Summary.Cell>
                              <Table.Summary.Cell>{currformat}{totals.totalTaxableAmount.toFixed(2)}</Table.Summary.Cell>
                            </Table.Summary.Row>
                          )}
                        </>
                      )}
                      

                    />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TaxRegister;
