import axios from 'axios';
import AuthService from "../services/auth.service";

import config from './config';
const API_URL = config.API_URL;


const currentUser = AuthService.getCurrentUser();




const getDashboard = () => {
  return axios
  .post(API_URL + "dashboardList", {
    apptoken: currentUser.apptoken, // Ensure the payload is correct
  
  })
  .then((response) => {
    console.log("response.data.dashboardList")
    console.log(response.data.data)

    if (response.data.errorMessage) {
      throw new Error(response.data.errorMessage);
    }

   

    return response.data;
  });

};



export default {
  getDashboard,

};
