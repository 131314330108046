import React, { useState , useEffect} from 'react';
import Header from '../layouts/Header';
import Sidebar from '../layouts/Sidebar';
import { Link } from "react-router-dom";
import * as Yup from 'yup';
import alertify from "alertifyjs";
import "../../node_modules/alertifyjs/build/css/alertify.css";
import "../../node_modules/alertifyjs/build/css/themes/semantic.css";


import UserService from "../services/user.service";
const ContactUS = () => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone_no: '',
        message: '',
    });



    
  useEffect(() => {
    fetchProfile();
   
  }, []);

  const fetchProfile = async () => {
    UserService.getUserProfile()
    .then((response) => {

      


      const {
          company_name,
          mobile,
          work_email,
    
      } = response.data;

      setFormData({
        name: company_name || '',
        phone_no: mobile || '',
          email: work_email || '',
        
      });
      
      })
      .catch((error) => {
        console.error("UserService error:", error);
      });
  };


    const [errors, setErrors] = useState({});
    const [isSubmitting, setIsSubmitting] = useState(false);

    const validationSchema = Yup.object().shape({
        name: Yup.string().required('Name is required'),
        email: Yup.string().email('Invalid email').required('Email is required'),
       
        message: Yup.string().required('Message is required'),
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

  // eslint-disable-next-line no-unused-vars
    const toastrError = (message) => {
        alertify.set("notifier", "position", "top-center");
        alertify.error(message);
      };
        // eslint-disable-next-line no-unused-vars
      const toastrSuccess = (message) => {
        alertify.set("notifier", "position", "top-center");
        alertify.success(message);
        
        setFormData({
         
              message:  ''
            
          });
      };

    const handleSubmit = async (e) => {
        e.preventDefault();


   


        try {
            await validationSchema.validate(formData, { abortEarly: false });
            setErrors({});
            setIsSubmitting(true);
    

            UserService.SendMessage(formData)
            .then((response) => {
        
              console.error("UserService error:", response.data[0].message);

              toastrSuccess(response.data[0].message);
              fetchProfile();
              
              })
              .catch((error) => {
                console.error("UserService error:", error);
              });

            setIsSubmitting(false);
        } catch (error) {
            if (error.inner) {
                const formErrors = {};
                error.inner.forEach(err => {
                    formErrors[err.path] = err.message;
                });
                setErrors(formErrors);
            }
            setIsSubmitting(false);
        }
    };
    const [menu, setMenu] = useState(false);

    const toggleMobileMenu = () => {
      setMenu(!menu);
    };
  
    const forceMenuClose = () => {  
        setMenu(false);
      };

    return (
        <>
              <div className={`main-wrapper ${menu ? "slide-nav" : ""}`}>
      <Header onMenuClick={() => toggleMobileMenu()} forceMenuClose={forceMenuClose}/>
      <Sidebar />
            <div className="page-wrapper">
                <div className="content container-fluid">
                    <div className="row">
                        <div className="col-xl-12 col-md-12">
                            <div className="card">
                                <div className="card-body w-100">
                                    <div className="content-page-header">
                                        <h5>Contact us</h5>
                                    </div>
                                    <form onSubmit={handleSubmit} className="row">
                                        <div className="col-lg-4 col-12">
                                            <div className="input-block mb-3">
                                                <label>Name<span className="alertstrik">*</span></label>
                                                <input
                                                    type="text"
                                                    name="name"
                                                    className="form-control"
                                                    placeholder="Enter Your Name"
                                                    value={formData.name}
                                                    onChange={handleChange}
                                                />
                                                {errors.name && <div className="text-danger">{errors.name}</div>}
                                            </div>
                                        </div>
                                        <div className="col-lg-4 col-12">
                                            <div className="input-block mb-3">
                                                <label>Email<span className="alertstrik">*</span></label>
                                                <input
                                                    type="text"
                                                    name="email"
                                                    className="form-control"
                                                    placeholder="Enter Your Email"
                                                    value={formData.email}
                                                    onChange={handleChange}
                                                />
                                                {errors.email && <div className="text-danger">{errors.email}</div>}
                                            </div>
                                        </div>
                                        <div className="col-lg-4 col-12">
                                            <div className="input-block mb-3">
                                                <label>Contact No.</label>
                                                <input
                                                    type="text"
                                                    name="phone_no"
                                                    className="form-control"
                                                    placeholder="Enter Your Contact No."
                                                    value={formData.phone_no}
                                                    onChange={handleChange}
                                                />
                                                {errors.phone_no && <div className="text-danger">{errors.phone_no}</div>}
                                            </div>
                                        </div>
                                        <div className="col-lg-12 col-12">
                                            <div className="input-block mb-3">
                                                <label>Message<span className="alertstrik">*</span></label>
                                                <textarea
                                                    name="message"
                                                    className="form-control textarea"
                                                    placeholder="Enter Your Message"
                                                    value={formData.message}
                                                    onChange={handleChange}
                                                />
                                                {errors.message && <div className="text-danger">{errors.message}</div>}
                                            </div>
                                        </div>
                                        <div className="col-lg-12">
                                            <div className="btn-path text-end">
                                                <Link to="/index" className="btn btn-primary me-3">Cancel</Link>
                                                <button type="submit" className="btn btn-primary" disabled={isSubmitting}>
                                                    {isSubmitting ? 'Sending...' : 'Send'}
                                                </button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </div>
        </>
    );
};

export default ContactUS;
