import React from "react";
import { Link } from "react-router-dom";
import FeatherIcon from "feather-icons-react";
import {
  Recepit,
  TransactionMinus,
  ArchiveBook,

  filterIcon,
} from "../_components/Imagepath";
import { Tooltip } from "antd";
import PropTypes from "prop-types";

const InvoiceHead = ({ show, setShow ,customtitle, listType ,invoiceBlocks ,userdata}) => {
  InvoiceHead.propTypes = {
    show: PropTypes.any.isRequired,
    setShow: PropTypes.func.isRequired,
    customtitle: PropTypes.func.isRequired,
    listType: PropTypes.func.isRequired,
    invoiceBlocks:  PropTypes.any.isRequired,
    userdata:  PropTypes.any.isRequired,
  };
  return (
    <>
      <div className="page-header">
        <div className="content-page-header">
          <h5>{customtitle}s</h5>
          <div className="list-btn">
            <ul className="filter-list">
              <li>
                <Link
                  to="#"
                  className="btn btn-filters w-auto popup-toggle"
                  onClick={() => setShow(!show)}
                >
                  <span className="me-2">
                    <Tooltip title="Filter">
                      <img src={filterIcon} alt="filter" />
                    </Tooltip>
                  </span>
                  Filter
                </Link>
              </li>
              <li>
                <Link className="btn-filters" to="/settings">
                  <span>
                    {/* <i className="fe fe-settings" /> */}
                    <FeatherIcon icon="settings" />
                  </span>{" "}
                </Link>
              </li>

              <li>
              {listType === 'invoicelist' && (
  <Link className="btn btn-primary" to="/add-invoice">
    <i className="fa fa-plus-circle me-2" aria-hidden="true" />
    New Invoice 
  </Link>
)}

{listType === 'estimatelist' && (
  <Link className="btn btn-primary" to="/add-estimate">
    <i className="fa fa-plus-circle me-2" aria-hidden="true" />
    New Estimate 
  </Link>
)}

              </li>
            </ul>
          </div>
        </div>
      </div>
      {/* /Page Header */}
      {/* Search Filter */}
      <div id="filter_inputs" className="card filter-card">
        <div className="card-body pb-0">
          <div className="row">
            <div className="col-sm-6 col-md-3">
              <div className="input-block mb-3">
                <label>Name</label>
                <input type="text" className="form-control" />
              </div>
            </div>
            <div className="col-sm-6 col-md-3">
              <div className="input-block mb-3">
                <label>Email</label>
                <input type="text" className="form-control" />
              </div>
            </div>
            <div className="col-sm-6 col-md-3">
              <div className="input-block mb-3">
                <label>Phone</label>
                <input type="text" className="form-control" />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* /Search Filter */}
      {/* Inovices card */}
      <div className="row">
        <div className="col-xl-4 col-lg-6 col-sm-6 col-12 d-flex">
          <div className="card inovices-card w-100">
            <div className="card-body">
              <div className="dash-widget-header">
                <span className="inovices-widget-icon bg-info-light">
                  <img src={Recepit} alt="" />
                </span>
                <div className="dash-count">
                  <div className="dash-title">Total {customtitle} Amount</div>
                  <div className="dash-counts">
                    <p>  {userdata && userdata.symbol ? userdata.symbol : ''} {invoiceBlocks.gtotal ? invoiceBlocks.gtotal : 0.0}</p>
                  </div>
                </div>
              </div>
              <div className="d-flex justify-content-between align-items-center">
                <p className="inovices-all">
                  No of {customtitle}{" "}
                  <span className="rounded-circle bg-light-gray">{invoiceBlocks.countdata}</span>
                </p>
                <p className="inovice-trending text-success-light">
                {invoiceBlocks.countdata}{" "}
                  <span className="ms-2">
                    <FeatherIcon icon="trending-up" />
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>

        {customtitle === "Invoice" && (
        <div className="col-xl-4 col-lg-6 col-sm-6 col-12 d-flex">
          <div className="card inovices-card w-100">
            <div className="card-body">
              <div className="dash-widget-header">
                <span className="inovices-widget-icon bg-primary-light">
                  <img src={TransactionMinus} alt="" />
                </span>
                <div className="dash-count">
                  <div className="dash-title">Total Pending Amount</div>
                  <div className="dash-counts">
                  <p>  {userdata && userdata.symbol ? userdata.symbol : ''} {invoiceBlocks.outstand_bal}</p>
                  </div>
                </div>
              </div>
              <div className="d-flex justify-content-between align-items-center">
                <p className="inovices-all">
                  No of {customtitle}{" "}
                  <span className="rounded-circle bg-light-gray">{invoiceBlocks.countdata}</span>
                </p>
                <p className="inovice-trending text-success-light">
                {invoiceBlocks.countdata}{" "}
                  <span className="ms-2">
                    <FeatherIcon icon="trending-up" />
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>
 )}
        {customtitle === "Invoice" && (
        <div className="col-xl-4 col-lg-6 col-sm-6 col-12 d-flex">
          <div className="card inovices-card w-100">
            <div className="card-body">
              <div className="dash-widget-header">
                <span className="inovices-widget-icon bg-warning-light">
                  <img src={ArchiveBook} alt="" />
                </span>
                <div className="dash-count">
                  <div className="dash-title">Total Paid Amount</div>
                  <div className="dash-counts">
                  <p>  {userdata && userdata.symbol ? userdata.symbol : ''} {invoiceBlocks.amount_recived ? invoiceBlocks.amount_recived : 0.0}</p>
                  </div>
                </div>
              </div>
              <div className="d-flex justify-content-between align-items-center">
               
              </div>
            </div>
          </div>
        </div>
        )}
       
        
      </div>
      {/* /Inovices card */}
      {/* All Invoice */}
    </>
  );
};

export default InvoiceHead;
