 /* eslint-disable */ 
import React, { useState ,useRef, useEffect} from "react";
import { Link } from "react-router-dom";
import { FilterChart, search } from "../../_components/Imagepath";
import DatePicker from "react-datepicker";

const AddVendor = ({ show, setShow , onApplyFilter, title}) => {
  const [isViewAllOpen, setIsViewAllOpen] = useState([]);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());




  const handleViewAllClick = (sectionIndex) => {
    setIsViewAllOpen((prev) => {
      const updatedState = [...prev];
      updatedState[sectionIndex] = !updatedState[sectionIndex];
      return updatedState;
    });
  };


  const handleSearch = (e) => {
    const searchTerm = e.target.value.toLowerCase();
    onApplyFilter(searchTerm); // Call parent's filter function
  };

  const modalRef = useRef(null);
  // Close modal on outside click
  const handleClickOutside = (event) => {
    if (modalRef.current && !modalRef.current.contains(event.target)) {
      setShow(false); // Close the modal
    }
  };

useEffect(() => {
  if (show) {
    document.addEventListener("mousedown", handleClickOutside);
  } else {
    document.removeEventListener("mousedown", handleClickOutside);
  }
  return () => {
    document.removeEventListener("mousedown", handleClickOutside);
  };
}, [show]);


  return (
    <>
      <div className={`toggle-sidebar ${show ? "open-filter" : ""}`}>
        <div className="sidebar-layout-filter" ref={modalRef}>
          <div className="sidebar-header">
            <h5>Filter</h5>
            <Link
            to="#"
            className="sidebar-closes"
            onClick={() => setShow(!show)}
          >
            <i className="fa-regular fa-circle-xmark" />
          </Link>
          </div>
          <div className="sidebar-body">
            <form action="#" autoComplete="off">
              {/* Customer */}
              <div className="accordion" id="accordionMain1">
                <div className="card-header-new" id="headingOne">
                  <h6 className="filter-title">
                    <Link
                      to="#"
                      className="w-100"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseOne"
                      aria-expanded="true"
                      aria-controls="collapseOne"
                    >
                      {title}
                      <span className="float-end">
                        <i className="fa-solid fa-chevron-down" />
                      </span>
                    </Link>
                  </h6>
                </div>
                <div
                  id="collapseOne"
                  className="collapse show"
                  aria-labelledby="headingOne"
                  data-bs-parent="#accordionExample1"
                >
                  <div className="card-body-chat">
                    <div className="row">
                      <div className="col-md-12">
                        <div id="checkBoxes1">
                          <div className="form-custom">
                            <input
                              type="text"
                              className="form-control"
                              id="member_search1"
                              placeholder="Search here"
                              onChange={handleSearch}
                            />
                            <span>
                              <img src={search} alt="img" />
                            </span>
                          </div>
                          
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* /Customer */}
              
             
          
              
              <button
                type="button"
                className="d-inline-flex align-items-center justify-content-center btn w-100 btn-primary"
              >
                <span>
                  <img
                    src={FilterChart}
                    className="me-2"
                    alt="Search"
                  />
                </span>
                Search
              </button>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddVendor;
