 
import React, { useState , useEffect} from "react";
import { Link } from "react-router-dom";
import Header from "../../layouts/Header";
import Sidebar from "../../layouts/Sidebar";


import "../../_components/antd.css";
import { Table, Tooltip } from "antd";

import AddVendor from "../../customers/vendors/addVendor";
import { filterIcon } from "../../_components/Imagepath";

import ItemService from '../../services/item.service';
import staticmessages from '../../services/staticmessages';

const Units = () => {
  const [menu, setMenu] = useState(false);
  const [show, setShow] = useState(false);
  const [units, setUnits] = useState([]);

    // eslint-disable-next-line no-unused-vars
    const [page, setPage] = useState(1);
    // eslint-disable-next-line no-unused-vars
  const paginationSize = 10;


  const toggleMobileMenu = () => {
    setMenu(!menu);
  };

 

  useEffect(() => {

    ItemService.fetchUnits()
      .then((response) => {
        console.log("ItemService response:", response.data);
        setUnits(response.data);
        setFilteredDataSource(response.data);
       
      })
      .catch((error) => {
        console.error("UserService error:", error);
      });
  }, []);





  
  // eslint-disable-next-line no-unused-vars
  const [filteredDataSource, setFilteredDataSource] = useState([]);

  // eslint-disable-next-line no-unused-vars
  const [searchTerm, setSearchTerm] = useState("");
  const handleApplyFilter = (searchTerm) => {
    console.log(searchTerm);
    setSearchTerm(searchTerm);
    filterData(searchTerm);
  };
  const filterData = (searchTerm) => {
    
    const filteredData = units.filter((record) =>
      record.name.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredDataSource(filteredData);
  };

  const handleTableChange = (pagination) => {
    setPage(pagination.current);
  };

  const forceMenuClose = () => {  
    setMenu(false);
  };


  const columns = [
    {
      title: "#",
      dataIndex: "id",
      render: (text, record, index) => (page - 1) * paginationSize + index + 1,
      sorter: (a, b) => a.id.length - b.id.length,
    },
    {
      title: "Unit Name",
      dataIndex: "name",
      sorter: (a, b) => a.name.length - b.name.length,
    },
   
   
  ];

  return (
    <>
      <div className={`main-wrapper ${menu ? "slide-nav" : ""}`}>
      <Header onMenuClick={() => toggleMobileMenu()} forceMenuClose={forceMenuClose}    onApplyFilter={handleApplyFilter} searchTerm = {searchTerm} />
        <Sidebar />

        <div className="page-wrapper">
          <div className="content container-fluid">
            {/* Page Header */}
            <div className="page-header">
              <div className="content-page-header ">
                <h5>{staticmessages.UNIT_LABEL_TITLE} </h5>
                <div className="list-btn">
                  <ul className="filter-list">
                    <li>
                      <Link
                        to="#"
                        className="btn btn-filters w-auto popup-toggle me-2"
                        onClick={() => setShow(!show)}
                      >
                        <span className="me-2">
                           <Tooltip title="Filter">
                            <img src={filterIcon} alt="filter" />
                          </Tooltip>
                        </span>
                        Filter
                      </Link>
                    </li>

                   
                    
                  </ul>
                </div>
              </div>
            </div>
            {/* /Page Header */}
            {/* Search Filter */}
            <div id="filter_inputs" className="card filter-card">
              <div className="card-body pb-0">
                <div className="row">
                  <div className="col-sm-6 col-md-3">
                    <div className="input-block mb-3">
                      <label>Name</label>
                      <input type="text" className="form-control" />
                    </div>
                  </div>
                  <div className="col-sm-6 col-md-3">
                    <div className="input-block mb-3">
                      <label>Email</label>
                      <input type="text" className="form-control" />
                    </div>
                  </div>
                  <div className="col-sm-6 col-md-3">
                    <div className="input-block mb-3">
                      <label>Phone</label>
                      <input type="text" className="form-control" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* /Search Filter */}
            {/* All Invoice */}
            <div className="card invoices-tabs-card">
              <div className="invoices-main-tabs">
                <div className="row align-items-center">
                  <div className="col-lg-12">
                    <div className="invoices-tabs">
                      <ul>
                        <li>
                          <Link to="/product-list">Product</Link>
                        </li>
                        <li>
                          <Link to="/category">Category</Link>
                        </li>
                        <li>
                          <Link to="/units" className="active">
                            Units
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* /All Invoice */}
            {/* Table */}
            <div className="row">
              <div className="col-sm-12">
                <div className="card-table">
                  <div className="card-body units">
                    <div className="table-responsive table-hover">
                      <Table
                        pagination={{
                          total: filteredDataSource.length,
                          showTotal: (total, range) =>
                            `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                         
                          
                        }}
                        columns={columns}
                        dataSource={filteredDataSource}
                        onChange={handleTableChange}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* /Table */}
          </div>
        </div>

        <AddVendor setShow={setShow} show={show} onApplyFilter={handleApplyFilter}  title="Units"  />
       

        <div className="modal custom-modal fade" id="add_unit" role="dialog">
          <div className="modal-dialog modal-dialog-centered modal-md">
            <div className="modal-content">
              <div className="modal-header border-0 pb-0">
                <div className="form-header modal-header-title text-start mb-0">
                  <h4 className="mb-0">Add Units</h4>
                </div>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body">
                <div className="row">
                  <div className="col-lg-12 col-sm-12">
                    <div className="input-block mb-3">
                      <label>
                        Name <span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter Title"
                      />
                    </div>
                  </div>
                  <div className="col-lg-12 col-sm-12">
                    <div className="input-block mb-3">
                      <label>
                        Symbol <span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter Symbol"
                      />
                    </div>
                  </div>
                  <div className="col-lg-12 col-sm-12">
                    <div className="input-block mb-0">
                      <label>
                        Parent Unit <span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Parent Unit"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <Link
                  to="#"
                  data-bs-dismiss="modal"
                  className="btn btn-primary  me-2"
                >
                  Cancel
                </Link>
                <Link
                  to="#"
                  data-bs-dismiss="modal"
                  className="btn btn-primary paid-continue-btn"
                >
                  Add Unit
                </Link>
              </div>
            </div>
          </div>
        </div>

        <div className="modal custom-modal fade" id="edit_unit" role="dialog">
          <div className="modal-dialog modal-dialog-centered modal-md">
            <div className="modal-content">
              <div className="modal-header border-0 pb-0">
                <div className="form-header modal-header-title text-start mb-0">
                  <h4 className="mb-0">Edit Units</h4>
                </div>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body">
                <div className="row">
                  <div className="col-lg-12 col-sm-12">
                    <div className="input-block mb-3">
                      <label>Name </label>
                      <input
                        type="text"
                        className="form-control"
                        defaultValue="Kilogram"
                        placeholder="Enter Title"
                      />
                    </div>
                  </div>
                  <div className="col-lg-12 col-sm-12">
                    <div className="input-block mb-3">
                      <label>Symbol</label>
                      <input
                        type="text"
                        className="form-control"
                        defaultValue="Slug"
                        placeholder="Enter Slug"
                      />
                    </div>
                  </div>
                  <div className="col-lg-12 col-sm-12">
                    <div className="input-block mb-0">
                      <label>Parent Unit</label>
                      <input
                        type="text"
                        className="form-control"
                        defaultValue="kg"
                        placeholder="Parent Unit"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <Link
                  to="#"
                  data-bs-dismiss="modal"
                  className="btn btn-primary  me-2"
                >
                  Cancel
                </Link>
                <Link
                  to="#"
                  data-bs-dismiss="modal"
                  className="btn btn-primary paid-continue-btn"
                >
                  Update
                </Link>
              </div>
            </div>
          </div>
        </div>

        <div
          className="modal custom-modal fade"
          id="delete_modal"
          role="dialog"
        >
          <div className="modal-dialog modal-dialog-centered modal-md">
            <div className="modal-content">
              <div className="modal-body">
                <div className="form-header">
                  <h3>Delete Units</h3>
                  <p>Are you sure you want to delete?</p>
                </div>
                <div className="modal-btn delete-action">
                  <div className="row">
                    <div className="col-6">
                      <button
                        type="reset"
                        data-bs-dismiss="modal"
                        className="w-100 btn btn-primary paid-continue-btn"
                      >
                        Delete
                      </button>
                    </div>
                    <div className="col-6">
                      <button
                        type="submit"
                        data-bs-dismiss="modal"
                        className="w-100 btn btn-primary "
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Units;
