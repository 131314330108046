import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

const Captcha = ({ captchaText }) => {
  const canvasRef = useRef(null);

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    ctx.clearRect(0, 0, canvas.width, canvas.height);
    ctx.font = '20px Arial';
    ctx.fillStyle = '#000';
    ctx.fillText(captchaText, 10, 30);
  }, [captchaText]);

  return <canvas ref={canvasRef} width={200} height={35} />;
};

Captcha.propTypes = {
  captchaText: PropTypes.string.isRequired,
};

export default Captcha;
