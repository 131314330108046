import React, { useEffect, useState } from "react";
import { Route, BrowserRouter as Router, Switch, Redirect } from "react-router-dom";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";

import PropTypes from 'prop-types'; // Import PropTypes

// Import components
import Dashboard from "./main/dashboard/Index";
import Customers from "./customers/Index";
import AddCustomer from "./customers/add-customer";
import Login from "./authentication/Login";
import Register from "./authentication/Register";
import ForgotPassword from "./authentication/forgot-password";
import ProductList from "./inventory/products/productList";
import Units from "./inventory/products/units";
import Category from "./inventory/products/category";
import AddProduct from "./inventory/products/addProduct";
import AccountSettings from "./settings/AccountSettings";
import CompanySettings from "./settings/CompanySettings";
import InvoiceSetting from "./settings/InvoiveSetting";
import TaxRates from "./settings/tax-rates";
import UploadLogo from "./settings/UploadLogo";
import UploadSignature from "./settings/UploadSignature";
import InvoiceTemplateSettings from "./settings/InvoiceTemplateSettings";
import TermsConditionsSetting from "./settings/termssettings";
import PaymentMethod from "./settings/PaymentMethod";
import PlanBilling from "./settings/plan-billing";

import DeleteAccounts from "./settings/deleteAccount";
import DeleteAccountFinal from "./settings/deleteAccountFinal";


import Step1Form from './settings/formwizard/component/Step1Form';
import Step2Form from './settings/formwizard/component/Step2Form';
import Step3Form from './settings/formwizard/component/Step3Form';
import Step4Form from './settings/formwizard/component/Step4Form';
import Step5Form from './settings/formwizard/component/Step5Form';
import Step6Form from './settings/formwizard/component/Step6Form';


import ContactUs from "./support/contactUs";

import InvoiceList from "./invoices/invoice-list";
import AddInvoice from "./invoices/AddInvoice";

import AuthService from "./services/auth.service";


import QuotationReport from "./reports/quotationReport";

import InvoiceSummary from "./reports/invoiceSummary";

import InvoiceSummaryDetails from "./reports/invoiceSummaryDetails";

import Balances from "./reports/Balances";

import PaymentsReceived from "./reports/PaymentsReceived";
import TwentySixAS from "./reports/TwentySixAS";

import ClientLedeger from "./reports/ClientLedeger";
import TaxRegister from "./reports/TaxRegister";

import TaxRegisterDetail from "./reports/TaxRegisterDetail";

import ExpiringServices from "./reports/ExpiringServices";

import OTPAuth from "./authentication/OTPAuth";

import ClientLedger from "./invoices/ledger/index";

import ViewLedger from "./invoices/ledger/viewLedger";

import ReportLedger from "./reports/reportLedger";


import { GoogleOAuthProvider } from '@react-oauth/google';

const AppContainer = () => {
 
  const location = useLocation();
  const [authenticated, setAuthenticated] = useState(AuthService.getCurrentUser() ? true : false); // Define 'authenticated' state

  useEffect(() => {

    getUser();
  
  
  }, [location.pathname]);

  const getUser = async () => {
    try {
      let res = await AuthService.getCurrentUser();

      
    if (res.apptoken) {
      console.log("1111");
      setAuthenticated(true);
    } else {
      console.log("2222");
      setAuthenticated(false);
    }
    console.log("token --"+authenticated);
    console.log(res.apptoken);


    } catch (error) {
      console.error('Login failed:', error);
    }
  };

  useEffect(() => {
    console.log("token --", authenticated);
  }, [authenticated]);

  // PrivateRoute component for authenticated routes
  const PrivateRoute = ({ component: Component, ...rest }) => (
    <Route {...rest} render={(props) => (
      authenticated === true
        ? <Component {...props} />
        : <Redirect to='/login' />
    )} />
  );

  // PropTypes validation for PrivateRoute component
  PrivateRoute.propTypes = {
    component: PropTypes.elementType.isRequired, // Ensure 'component' prop is required and of type 'elementType'
  };

  return (
    <Router basename="/">
     
     
      <Switch>
     
        <PrivateRoute path="/index" component={Dashboard} />
        <PrivateRoute path="/customers" component={Customers} />
        <PrivateRoute path="/add-customer" component={AddCustomer} />
        <PrivateRoute path="/edit-customer" component={AddCustomer} />
        
     

        <PrivateRoute path="/product-list" component={ProductList} />
        <PrivateRoute path="/category" component={Category} />
        <PrivateRoute path="/units" component={Units} />
        <PrivateRoute path="/add-product" component={AddProduct} />
        <PrivateRoute path="/edit-product" component={AddProduct} />

        <PrivateRoute path="/settings" component={AccountSettings} />
        <PrivateRoute path="/company-settings" component={CompanySettings} />
        <PrivateRoute path="/invoice-settings" component={InvoiceSetting} />
        <PrivateRoute path="/invoice-templatesettings" component={InvoiceTemplateSettings} />
        <PrivateRoute path="/termsconditions" component={TermsConditionsSetting} />
        <PrivateRoute path="/paymentmethod" component={PaymentMethod} />
        <PrivateRoute path="/planbilling" component={PlanBilling} />
  

        
        <PrivateRoute path="/tax-rates" component={TaxRates} />
        <PrivateRoute path="/upload-logo" component={UploadLogo} />
        <PrivateRoute path="/upload-signature" component={UploadSignature} />
        
        <PrivateRoute path="/invoice-list" component={InvoiceList} />
        <PrivateRoute path="/estimate-list" component={InvoiceList} />
        <PrivateRoute path="/add-invoice" component={AddInvoice} />
        <PrivateRoute path="/add-estimate" component={AddInvoice} />
        <PrivateRoute path="/edit-invoice" component={AddInvoice} />
        <PrivateRoute path="/edit-estimate" component={AddInvoice} />

        <PrivateRoute path="/quotation-report" component={QuotationReport} />
        <PrivateRoute path="/invoice-summary" component={InvoiceSummary} />
        <PrivateRoute path="/invoice-detail" component={InvoiceSummaryDetails} />

        <PrivateRoute path="/balances" component={Balances} />

        <PrivateRoute path="/payments-received" component={PaymentsReceived} />

        <PrivateRoute path="/twentySixAS" component={TwentySixAS} />

        <PrivateRoute path="/client-ledeger" component={ClientLedeger} />

        <PrivateRoute path="/tax-register" component={TaxRegister} />
        
        <PrivateRoute path="/tax-register-detail" component={TaxRegisterDetail} />

        <PrivateRoute path="/expiring-services" component={ExpiringServices} />

        <PrivateRoute path="/client-ledger" component={ClientLedger} />

        <PrivateRoute path="/view-ledger" component={ViewLedger} />

        
        <PrivateRoute path="/report-ledger" component={ReportLedger} />

        <PrivateRoute path="/delete-account" component={DeleteAccounts} />
        <PrivateRoute path="/delete-account-final" component={DeleteAccountFinal} />

        <PrivateRoute path="/contactus" component={ContactUs} />

        <Route path="/step1form" component={Step1Form} />
        <Route path="/step2form" component={Step2Form} />
        <Route path="/step3form" component={Step3Form} />
        <Route path="/step4form" component={Step4Form} />
        <Route path="/step5form" component={Step5Form} />
        <Route path="/step6form" component={Step6Form} />


        
        
        {/* Redirect to login for any other unmatched routes */}
        <GoogleOAuthProvider clientId="1012812238419-1555d1j1qcklgs9l522gcikk56vknr78.apps.googleusercontent.com">
        <Route path="/login" exact component={Login} />
        <Route path="/register" component={Register} />
        <Route path="/forgot-password" component={ForgotPassword} />
        <Route path="/otp-auth" component={OTPAuth} />
        <Redirect to="/login" />
        </GoogleOAuthProvider>
      </Switch>
    </Router>
  );
};

export default AppContainer;
