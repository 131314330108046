import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom"; // useHistory
import Header from "../../layouts/Header";
import Sidebar from "../../layouts/Sidebar";


import UserService from "../../services/user.service";
import ItemService from '../../services/item.service';
import staticmessages from '../../services/staticmessages';
import "../../_components/antd.css";
import { Table, Tooltip } from "antd";

import { filterIcon } from "../../_components/Imagepath";

import alertify from "alertifyjs";
import "../../../node_modules/alertifyjs/build/css/alertify.css";
import "../../../node_modules/alertifyjs/build/css/themes/semantic.css";
import Spinners from '../../components/spinners';
import AddVendor from "../../customers/vendors/addVendor";

const ProductList = () => {
  const history = useHistory();
  const [menu, setMenu] = useState(false);
  const [show, setShow] = useState(false);

  // eslint-disable-next-line no-unused-vars
  const [items, setItems] = useState([]);
    // eslint-disable-next-line no-unused-vars
  const [error, setError] = useState(null);
    // eslint-disable-next-line no-unused-vars
  const [symbol, setSymbol] = useState("");

      // eslint-disable-next-line no-unused-vars
      const [page, setPage] = useState(1);
      // eslint-disable-next-line no-unused-vars
      const paginationSize = 10;


  // eslint-disable-next-line no-unused-vars
  const [searchTerm, setSearchTerm] = useState(""); 
  const [filteredItems, setFilteredItems] = useState([]);

  const [deleteact, setDeleteAct] = useState(null);

   // eslint-disable-next-line no-unused-vars
   const [loading, setLoading] = useState(true); // Loading state
 
    const handleApplyFilter = (searchTerm) => {
      console.log(searchTerm);
      setSearchTerm(searchTerm);
      filterData(searchTerm);
    };
    const filterData = (searchTerm) => {

    
      
      const filteredData = items.filter((record) =>
        (record.item_name.toLowerCase().includes(searchTerm.toLowerCase())  ||
      record.category.toLowerCase().includes(searchTerm.toLowerCase()))
      );
      setFilteredItems(filteredData);
    };
  

  const toggleMobileMenu = () => {
    console.log("toggleMobileMenu");
    setMenu(!menu);
  };


  useEffect(() => {

    UserService.getUserProfile()
    .then((response) => {
      
      setSymbol(response.data.symbol)
     
    })
    .catch((error) => {
      console.error("UserService error:", error);
    });

    innergetClients();
   
  }, []);

  const innergetClients = () => {
    ItemService.getItems()
      .then((response) => {
        console.log("ItemService response:", response.data);
        setItems(response.data);
        setFilteredItems(response.data);
        setLoading(false);
      })
      .catch((error) => {
        setError(error.message);
        setLoading(false);
      });
  };


  const setDelete = (id) => {
    if (id) {
      setDeleteAct(id);
    }
  };

  const toastrSuccess = (message) => {
    alertify.set("notifier", "position", "top-center");
    alertify.success(message);
  };

  const handleDelete = (id) => {
    if (id) {
      ItemService.deleteItem(id)
        .then(() => {
          toastrSuccess(staticmessages.ITEM_DELSUCCESS)
          innergetClients();
        })
        .catch((error) => {
          console.error('Login failed:', error);
        });
    }
  };

  const handleEdit = (item) => {
    console.log(item);
    if (item && item.id) {
     // item.unit = {unit: item.unit}
      history.push(`/edit-product`, { item });
    } else {
      history.push(`/add-product`, { item: {} });
    }
  };

  const handleTableChange = (pagination) => {
    setPage(pagination.current);
  };

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      render: (text, record, index) => (page - 1) * paginationSize + index + 1,
    },
    {
      title: "Item",
      dataIndex: "item_name",
      sorter: (a, b) => a.item_name.length - b.item_name.length,
      render: (img, record) => (
        <>
         
         <button type="button" className="btn btn-link"  onClick={ ()=> handleEdit(record)}>
         {record.item_name}
        </button>
         
        </>
      ),
    },
    {
      title: "HSN/SAC",
      dataIndex: "hsn_sac",
    
    },
    {
      title: "Category",
      dataIndex: "category",
      sorter: (a, b) => a.category.length - b.category.length,
    },
    {
      title: "Units",
      dataIndex: "unit",
     
    },
   
    {
      title: "Price",
      dataIndex: "price",
      render: (img, record) => (
        <>
         {symbol}{record.price}
        </>
      ),
      sorter: (a, b) => a.price.length - b.price.length,
     
    },
   
    {
      title: "Action",
      dataIndex: "Action",
      render: (text, record) => (
        <div className="d-flex align-items-center">
          <div className="dropdown dropdown-action">
            <Link
              to="#"
              className=" btn-action-icon "
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <i className="fas fa-ellipsis-v" />
            </Link>
            <div className="dropdown-menu dropdown-menu-right">
              <ul>
                <li>
                <button className="dropdown-item" onClick={() => handleEdit(record)}>
                
                    <i className="far fa-edit me-2" />
                    Edit
                  </button>
                </li>
                <li>
          
                  <Link
                    className="dropdown-item"
                    to="#"
                    data-bs-toggle="modal"
                    data-bs-target="#delete_modal" 
                    onClick={() => setDelete(record.id)}>
                    <i className="far fa-trash-alt me-2" />
                    Delete
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      ),
     
    },
  ];

  const forceMenuClose = () => {  
    setMenu(false);
  };

  if (loading) {
    return <Spinners />; // Display the spinner while loading
  }

  return (
    <>
      <div className={`main-wrapper ${menu ? "slide-nav" : ""}`}>
      <Header onMenuClick={() => toggleMobileMenu()}  forceMenuClose={forceMenuClose}  onApplyFilter={handleApplyFilter} searchTerm = {searchTerm} />
        <Sidebar />

        <div className="page-wrapper">
          <div className="content container-fluid">
            {/* Page Header */}
            <div className="page-header">
              <div className="content-page-header ">
                <h5>{staticmessages.ITEMS_LABEL_TITLE}</h5>
                <div className="list-btn">
                  <ul className="filter-list">
                    <li>
                      <Link
                        to="#"
                        className="btn btn-filters w-auto popup-toggle me-2"
                        onClick={() => setShow(!show)}
                      >
                        <span className="me-2">
                           <Tooltip title="Filter">
                            <img src={filterIcon} alt="filter" />
                          </Tooltip>{" "}
                        </span>
                        Filter
                      </Link>
                    </li>

                    
                   
                 
                    <li>
                      <Link className="btn btn-primary" to="/add-product">
                        <i
                          className="fa fa-plus-circle me-2"
                          aria-hidden="true"
                        />
                        {staticmessages.ITEMS_LABEL_ADDITEM}
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            {/* /Page Header */}
            {/* Search Filter */}
            <div id="filter_inputs" className="card filter-card">
              <div className="card-body pb-0">
                <div className="row">
                  <div className="col-sm-6 col-md-3">
                    <div className="input-block mb-3">
                      <label>Name</label>
                      <input type="text" className="form-control" />
                    </div>
                  </div>
                  <div className="col-sm-6 col-md-3">
                    <div className="input-block mb-3">
                      <label>Email</label>
                      <input type="text" className="form-control" />
                    </div>
                  </div>
                  <div className="col-sm-6 col-md-3">
                    <div className="input-block mb-3">
                      <label>Phone</label>
                      <input type="text" className="form-control" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* /Search Filter */}
            {/* All Invoice */}
            <div className="card invoices-tabs-card">
              <div className="invoices-main-tabs">
                <div className="row align-items-center">
                  <div className="col-lg-12">
                    <div className="invoices-tabs">
                      <ul>
                        <li>
                          <Link to="/product-list" className="active">
                            Product
                          </Link>
                        </li>
                        <li>
                          <Link to="/category">Category</Link>
                        </li>
                        <li>
                          <Link to="/units">Units</Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* /All Invoice */}
            {/* Table */}
            <div className="row">
              <div className="col-sm-12">
                <div className=" card-table">
                  <div className="card-body">
                    <div className="table-responsive">
										<div className="companies-table">
                      <Table
                        pagination={{
                          total: filteredItems.length,
                          showTotal: (total, range) =>
                            `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                          
                        }}
                        columns={columns}
                        dataSource={filteredItems}
                        rowKey={(record) => record.Id}
                        onChange={handleTableChange}
                      />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* /Table */}
          </div>
        </div>




        <AddVendor setShow={setShow} show={show} onApplyFilter={handleApplyFilter}  title="Item"  />



        <div
          className="modal custom-modal fade"
          id="delete_modal"
          role="dialog"
        >
          <div className="modal-dialog modal-dialog-centered modal-md">
            <div className="modal-content">
              <div className="modal-body">
                <div className="form-header">
                  <h3>Delete Products / Services</h3>
                  <p>Are you sure you want to delete?</p>
                </div>
                <div className="modal-btn delete-action">
                  <div className="row">
                    <div className="col-6">
                      <button
                        type="reset"
                        data-bs-dismiss="modal"
                        className="w-100 btn btn-primary paid-continue-btn"
                        onClick={() => handleDelete(deleteact)}
                      >
                        Delete
                      </button>
                    </div>
                    <div className="col-6">
                      <button
                        type="submit"
                        data-bs-dismiss="modal"
                        className="w-100 btn btn-primary "
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProductList;
