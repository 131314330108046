// eslint-disable-next-line no-unused-vars
import React from "react";
import { useHistory } from 'react-router-dom';
import { BillwizerLogoLight } from "../../../_components/Imagepath";
// eslint-disable-next-line no-unused-vars
const Step4Form = () => {
  const history = useHistory();

  const handlePrevious = () => {
    history.push('/step3form');
  };

  const handleNext = () => {
    history.push('/step5form');
  };

  return (
    <>
      <div className="main-wrapper container d-flex align-items-center justify-content-center boardingwizard" style={{ minHeight: "80vh" }}>
    <div className="content container-fluid">
    <img
          className="img-fluid logo-dark mb-2"
          src={BillwizerLogoLight}
          alt="Logo"
        />
    <div className="row justify-content-center">
        <div className="col-lg-6 col-md-6 col-sm-12">
          <div className="card">
            <div className="card-header">
            
            </div>
          
            <div className="card-body">
            

                  <div className="row">
                    <div className="col-xl-12 col-md-12">
                      <div className="card company-settings-new">
                        <div className="card-body w-100">
                          <div className="content-page-header">
                            <h5>Your Set Up is Complete! 🎉</h5>
                          </div>
                          <div className="row">
                            <div className="col-lg-12 col-12">
                              <div className="input-block mb-3">
                                <label>
                                  Now, we are creating a sample estimate for your reference. The same can be deleted later.
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="d-flex justify-content-center">
                    <button
                      className="btn btn-primary me-2"
                      onClick={handlePrevious}
                    >
                      Back
                    </button>

                    <button
                      className="btn btn-primary"
                      type="button"
                      onClick={handleNext}
                    >
                      Next
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Step4Form;
