/* eslint-disable */
import React, { useState, useEffect } from "react";
import staticmessages from '../../../services/staticmessages';
import Select from 'react-select';
import UserService from "../../../services/user.service";
import alertify from "alertifyjs";
import * as yup from 'yup';
import { useHistory } from 'react-router-dom';
import { BillwizerLogoLight } from "../../../_components/Imagepath";
import "../../../../node_modules/alertifyjs/build/css/alertify.css";
import "../../../../node_modules/alertifyjs/build/css/themes/semantic.css";

const Step2Form = () => {

  const history = useHistory();
  const [errors, setErrors] = useState({});



  const handlePrevious = () => {
    history.push('step1form');
  };

  const [step2Data, setStep2Data] = useState({
    tax_label:  "none",
    tax_value: "",
    taxdata: [],

  });



  // Validation schemas
  const step2Schema = yup.object().shape({
    tax_label: yup.string().required("Tax is required"),
   // tax_value: yup.string().required("Tax value is required"),
  
    
    tax_value: yup.string().when("tax_label", {
      is: (val) => {
        // This will output undefined
        console.log(val);
        return val === "ontotal";
      },
      then: (s) => s
        .required('Tax Rate is required')
        .matches(/^\d+(\.\d+)?$/, 'Tax Rate can only contain numeric characters and an optional decimal point')
        .test(
          'max-value',
          'Tax Rate must be less than or equal to 100',
          value => parseFloat(value) <= 100
        ),
      otherwise: (s) => s,
    }),
  
  });


  const validateStep = async () => {
    let schema;
    let data;

    schema = step2Schema;
    data = step2Data;



    try {
      await schema.validate(data, { abortEarly: false });
      setErrors({});
      return data; // Return validated data
    } catch (err) {
      const newErrors = {};
      err.inner.forEach((error) => {
        newErrors[error.path] = error.message;
      });
      setErrors(newErrors);
      return null;
    }
  };

  const handleNext = async () => {
    const validatedData = await validateStep();

    if (validatedData) {
      try {
        let response;
        let tempresponse;

        tempresponse = await UserService.UpdateTaxMasterSetting(validatedData);
        response = tempresponse[0];



        console.log("API Error:", response);

        if (response && response.id) {
          
          
         history.push('step3form');


        } else {
          throw new Error("Unexpected response from server.");
        }
      } catch (error) {
        alertify.set("notifier", "position", "top-center");
        alertify.error("Failed to save data. Please try again.");
        console.error("API Error:", error); // Log error details for debugging
      }
    } else {
      alertify.set("notifier", "position", "top-center");
      alertify.error("Please fill all the required fields to proceed.");
    }
  };

 // const [errors, setErrors] = useState({});
  const [taxdata, setTaxdata] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      await getCustomerTaxMasters();
      await fetchProfile();
    };
    fetchData();
  }, []);

  const [getuser, SetUser] = useState([]);


  useEffect(() => {
    const updateStep2Data = async () => {
      setStep2Data({
        tax_label: getuser.selectedtax ? getuser.selectedtax : "none",
        tax_value: getuser.taxrate ? getuser.taxrate : "",
        taxdata: [],
      });
    };
  
    updateStep2Data();
  }, [getuser.selectedtax]);
  


  const fetchProfile = async () => {
    try {
      const response = await UserService.getUserProfile();
      
      SetUser(response.data);

      console.error('Error fetching getCustomerTaxMasters:', response.data.selectedtax);
  
   
  
      if (response.data.onboard) {
        history.push('index');
      }
    } catch (error) {
      console.error("UserService error:", error);
    }
  };
  



  useEffect(() => {
    console.log("Updated step2Data in Step2Form:", step2Data);
  }, [step2Data]);

  const formattedOptions = (options) =>
    options.map((option) => ({
      value: option.value,
      label: option.label,
    }));

  const getCustomerTaxMasters = async () => {
    try {
      const response = await UserService.getCustomerTaxMasters();
      setTaxdata(response.data);
    } catch (error) {
      console.error('Error fetching getCustomerTaxMasters:', error);
    }
  };

  const handleSelectChange = (selectedOption, name) => {
 
    setStep2Data(prevState => ({
      ...prevState,
      [name]: selectedOption ? selectedOption.value : '',
    }));

  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setStep2Data(prevState => ({
      ...prevState,
      [name]: value,
    }));
  };


    // Load invoice from localStorage
    const loadInvoiceFromStorage = () => {
      const storedInvoice = localStorage.getItem('invoicewizard');
      return storedInvoice ? JSON.parse(storedInvoice) : null;
    };
  
    const [invoice, setInvoice] = useState(() => {
      const storedInvoice = loadInvoiceFromStorage();
      return storedInvoice && Object.keys(storedInvoice).length > 0 ? storedInvoice : {};
    });


    
    // Inside your component
useEffect(() => {
  setInvoice((prevInvoice) => {
    const updatedInvoice = {
      ...prevInvoice,
      selectedtax: step2Data.tax_label,
      invoice_taxrate: step2Data.tax_value,
    };
    console.log("setInvoice API Error 333 :", updatedInvoice);
    return updatedInvoice;
  });
}, [step2Data.tax_label,step2Data.tax_value]);


  return (
    <div className="main-wrapper container d-flex align-items-center justify-content-center boardingwizard" style={{ minHeight: "80vh" }}>
    <div className="content container-fluid">
    <img
          className="img-fluid logo-dark mb-2"
          src={BillwizerLogoLight}
          alt="Logo"
        />
    <div className="row justify-content-center">
        <div className="col-lg-6 col-md-6 col-sm-12">
          <div className="card">
            <div className="card-header">
            
            </div>
          
            <div className="card-body">
            

              <form autoComplete="off">
        <div className="row">
          <div className="col-lg-6 col-12">

        
            <div className="input-block mb-3">
              <label>Tax <span className="alertstrik">*</span></label>
          
     


              {taxdata && (
                <Select
                  name="tax_label"
                  value={
                    step2Data.tax_label
                      ? formattedOptions(staticmessages.TAX_SETTING).find(option => option.value === step2Data.tax_label)
                      : null
                  }
                  options={formattedOptions(staticmessages.TAX_SETTING)}
                  placeholder="Select Tax Label"
                  onChange={(selectedOption) => handleSelectChange(selectedOption, 'tax_label')}
                  className="fixzindex4"
                />
              )}
               {errors.tax_label && <small className="error">{errors.tax_label}</small>}
          
            </div>
          </div>

          {step2Data.tax_label === "ontotal" && (
            <div className="col-lg-6 col-12">
              <div className="input-block mb-3">
                <label>Tax Value(%)</label>
                <input
                  type="text"
                  name="tax_value"
                  className="form-control"
                  value={step2Data.tax_value || ''}
                  maxLength={5}
                  onChange={handleInputChange}
                  onClick={(e) => e.target.select()}
                />
               
                {errors.tax_value && <small className="error">{errors.tax_value}</small>}
              </div>
            </div>
          )}

<div className="d-flex justify-content-center">
                     
                        <button className="btn btn-primary me-2" onClick={handlePrevious}>
                          Back
                        </button>
                     
                        <button className="btn btn-primary" type="button" onClick={handleNext}>
                        Next
                      </button>
                    </div>


        </div>
      </form>
      </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};


export default Step2Form;
