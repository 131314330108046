import React from "react";

const Spinners = () => {
  return (
    <div className="d-flex justify-content-center align-items-center" style={{ height: "100vh" }}>
      <div className="spinner-border avatar-lg text-primary" role="status"></div>
    </div>
  );
};

export default Spinners;
