import React, { useState, useEffect } from "react";

import HeaderInvoice from "../layouts/HeaderInvoice";
import Sidebar from "../layouts/Sidebar";

//import "../dropdown";
import {  Table } from "antd";

//import AddVendor from "../customers/vendors/addVendor";
import InvoiceModalFilter from "../common/modal/invoiceModalFilter";
import InvoiceHead from "./invoice-head";

  // eslint-disable-next-line no-unused-vars
import staticmessages from '../services/staticmessages';
  // eslint-disable-next-line no-unused-vars
import InvoiceService from '../services/invoice.service';
  // eslint-disable-next-line no-unused-vars
import AuthService from "../services/auth.service";
  // eslint-disable-next-line no-unused-vars
import { useHistory, Link, useLocation } from 'react-router-dom';
import moment from "moment";


import alertify from "alertifyjs";
import "../../node_modules/alertifyjs/build/css/alertify.css";
import "../../node_modules/alertifyjs/build/css/themes/semantic.css";

  // eslint-disable-next-line no-unused-vars
  import Spinners from '../components/spinners';

const InvoiceList = () => {

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

      // eslint-disable-next-line no-unused-vars
  const [loading, setLoading] = useState(true); // Loading state

  const history = useHistory();
  
  const [menu, setMenu] = useState(false);
  const [show, setShow] = useState(false);
    // eslint-disable-next-line no-unused-vars
  const [deleteact, setDeleteAct] = useState(null);

      // eslint-disable-next-line no-unused-vars
  const [duplicate, setDuplicateAct] = useState(null);

        // eslint-disable-next-line no-unused-vars
        const [convertestinv, setConvertEstInvoiceAct] = useState(null);

  const [datasource, setInvoices] = useState([]);

  const [filteredDataSource, setFilteredDataSource] = useState([]);

  const location = useLocation();

  // eslint-disable-next-line no-unused-vars
  const [searchTerm, setSearchTerm] = useState("");

  const [listType, setListType] = useState("");

  const [customtitle, setCustomTitle] = useState(staticmessages.INVOICE_LIST_TITLE);


  
  // eslint-disable-next-line no-unused-vars
  const toastrError = (message) => {
    alertify.set("notifier", "position", "top-center");
    alertify.error(message);
  };
    // eslint-disable-next-line no-unused-vars
  const toastrSuccess = (message) => {
    alertify.set("notifier", "position", "top-center");
    alertify.success(message);
  };

      // eslint-disable-next-line no-unused-vars
  const [invoiceBlocks, setInvoicesBlocks] = useState([]);

        // eslint-disable-next-line no-unused-vars
  const [userdata, setUserData] = useState(null);





  useEffect(() => {
    console.log("getInvoiceslistType useEffect useEffect getInvoices "+location.pathname);
    if( location.pathname == "/invoice-list")
      {
        setListType("invoicelist");
        setCustomTitle(staticmessages.INVOICE_LIST_TITLE);
       
      }
      else
      {
        setListType("estimatelist");
        setCustomTitle(staticmessages.ESTIMATE_LIST_TITLE);
        
      }
      const number = generateRandomNumber();
        setMenuSwitch(number);
     
     
  }, [location.pathname]);


  const generateRandomNumber = () => {
    const randomNumber = Math.floor(Math.random() * 100) + 1; // Generates a number between 1 and 100
    return randomNumber;
  };

  const [menuswitch, setMenuSwitch] = useState(null);
  useEffect(() => {
    console.log("getInvoiceslistType getInvoices---" +menuswitch);
  //  toggleMobileMenu()
 

  }, [menuswitch]);


    // Define the Newfunc function
    const NewfuncGetData = () => {
      if (listType) {
        
        getInvoices();
        getInvoicesBlocks();
      }
    };


  useEffect(() => {
    if (listType) {
      NewfuncGetData();
    }
  }, [listType]);

  const getInvoicesBlocks = async () => {

    setInvoicesBlocks([]);
     
  
    InvoiceService.getInvoicesBlocks(listType)
      .then((response) => {
        setInvoicesBlocks(response.data);
        
      })
      .catch((error) => {
        console.log(error);
      });
  };


  const toggleMobileMenu = () => {
    setMenu(!menu);
  };

  // eslint-disable-next-line no-unused-vars
  const handleApplyFilter = (searchTerm) => {
    console.log(searchTerm);
    setSearchTerm(searchTerm);
    filterData(searchTerm);
  };

  const filterData = (searchTerm) => {
    const filteredData = datasource.filter((record) => {
        const clientNameMatch = record.client_name.toLowerCase().includes(searchTerm.toLowerCase());
        const invoiceNoMatch = record.invoice_number.toString().toLowerCase().includes(searchTerm.toLowerCase());
        return clientNameMatch || invoiceNoMatch;
    });
    
    setFilteredDataSource(filteredData);
};


  const getInvoices = async () => {

    console.log("getInvoices getInvoices" +listType);

    setInvoices([]);
        setFilteredDataSource([]);

    InvoiceService.getInvoices(listType, filteroption)
      .then((response) => {
        setInvoices(response.data);
        setFilteredDataSource(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
       setLoading(false);
      });
  };


  useEffect(() => {
   
   
    getUser();
  

  }, []);

  const getUser = async () => {
    try {
      let res = await InvoiceService.getUserProfile();

      setUserData(res.data);
      console.log("getCurrentUser", res);
    } catch (error) {
      console.error('Login failed:', error);
    }
  };
  // eslint-disable-next-line no-unused-vars
  const setDelete = (objdata) => {

    console.log(objdata)

    if (objdata) {
      setDeleteAct(objdata);
    }
  };

   // eslint-disable-next-line no-unused-vars
   const setDuplicate = (objdata) => {

    console.log(objdata)

    if (objdata) {
      setDuplicateAct(objdata);
    }
  };




    // eslint-disable-next-line no-unused-vars
    const handleDuplicate = (listType, objdata) => {

      objdata.id = null;

      handaleSubmenu("add", listType, objdata)

        console.error(objdata);
      
    };


     // eslint-disable-next-line no-unused-vars
   const setConvertEstInvoice = (objdata) => {

    console.log(objdata);

    

    if (objdata) {
      setConvertEstInvoiceAct(objdata);
    }
  };

       // eslint-disable-next-line no-unused-vars
       const handleConvertInvoice = (listType, objdata) => {

        objdata.id = null;
        const newdate = new Date();
        objdata.datetimestamp = newdate.getTime();
  
        handaleSubmenu("add", "invoicelist", objdata)
  
          console.error(objdata);
        
      };


  // eslint-disable-next-line no-unused-vars
  const handleDelete = (objdata) => {
    if (objdata) {
      InvoiceService.deleteinvoice(objdata.id,objdata.uniqueno,listType)
        .then(() => {
          let mess = `${customtitle} ${staticmessages.INVOICE_DELSUCCESS}`;
          toastrSuccess(mess);
          NewfuncGetData();
        
        })
        .catch((error) => {
          console.error('Delete Action Failed:', error);
        });
    }
  };
  // eslint-disable-next-line no-unused-vars
  const handleEdit = (client) => {
    if (client && client.id) {
      history.push(`/edit-customer`, { client });
    } else {
      history.push(`/add-customer`, { client: {} });
    }
  };




  // eslint-disable-next-line no-unused-vars
  const handaleSubmenu = (type, listType, selectedinvoice) => {

    console.log("handleSubmenu selectedinvoice: " + JSON.stringify(selectedinvoice));

    switch (listType) {
      case "invoicelist":
        console.log("handleSubmenu handleSubmenu: " + type + "---" + listType);
        switch (type) {
          case "print":


          InvoiceService.handlePrintPdfInvoiceEST("Invoice", listType, selectedinvoice);

          
            // Add your print handling code here
            break;

            case "downloadpdf":
              InvoiceService.handleDownloadPdfInvoiceEST("Invoice", listType, selectedinvoice);
              break;

            case "payment":
              history.push(`/client-ledger`, { selectedinvoice });
              break;

              case "add":
                history.push(`/add-invoice`, { selectedinvoice });
                break;

          case "edit":
            history.push(`/edit-invoice`, { selectedinvoice });
            break;
          default:
            break;
        }
        break;
    
      case "estimatelist":
        console.log("handleSubmenu handleSubmenu: " + type + "---" + listType);
        switch (type) {
          case "print":
            InvoiceService.handlePrintPdfInvoiceEST("Invoice", listType, selectedinvoice);
            break;
            case "downloadpdf":
              InvoiceService.handleDownloadPdfInvoiceEST("Invoice", listType, selectedinvoice);
              break;

            case "payment":
              history.push(`/client-ledger`, { selectedinvoice });
              break;

          case "edit":
            history.push(`/edit-estimate`, { selectedinvoice });
            break;
            case "add":
              history.push(`/add-estimate`, { selectedinvoice });
              break;

          default:
            break;
        }
        break;
    
      default:
        break;
    }

   
  };





  const onSelectChange = (newSelectedRowKeys) => {
    console.log("selectedRowKeys changed: ", selectedRowKeys);
    setSelectedRowKeys(newSelectedRowKeys);
  };


  // eslint-disable-next-line no-unused-vars
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };



  const columns = [
    {
      title: `${customtitle} No.`,
      dataIndex: "Invoice",
      render: (img,record) => (

      
        <button type="button" className="btn btn-link"  onClick={ ()=> handaleSubmenu("edit", listType, record)}>
          {record.invoice_number}
        </button>
      ),
      sorter: (a, b) => a.invoice_number.localeCompare(b.invoice_number),
    },
 
    {
      title: "Date",
      dataIndex: 'datetimestamp', // Ensure the dataIndex matches the field name in your data
      key: 'date',
      render: (text) => moment(text).format("DD-MM-YYYY"),
      sorter: (a, b) => moment(a.datetimestamp).unix() - moment(b.datetimestamp).unix(),
    },
    {
      title: `${customtitle} To`,
      dataIndex: "Name",
      render: (img,record) => (
        <h2 className="table-avatar">
         
        
            {record.client_name} 
         
        </h2>
      ),
      sorter: (a, b) => a.client_name.length - b.client_name.length,
    },

    {
      title: "Total Amount",
      dataIndex: "gtotal",
      render: (img, record) => (
        <>
         {record.symbol}{record.gtotal}
        </>
      ),
     
    },
    
    {
      title: "Due Date",
      dataIndex: 'datetimestampdue', // Ensure the dataIndex matches the field name in your data
      key: 'datetimestampdue',
      render: (text) => moment(text).format("DD-MM-YYYY"),
      sorter: (a, b) => moment(a.datetimestampdue).unix() - moment(b.datetimestampdue).unix(),
    },
  
    
    {
      title: "Action",
      dataIndex: "Action",
      render: (text, record) => (
      
          <div className="text-end">
            <div className="dropdown dropdown-action">
              <Link
                to="#"
                className="btn-action-icon"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <i className="fas fa-ellipsis-v" />
              </Link>
              <div className="dropdown-menu dropdown-menu-end">
        
                <button type="button" className="dropdown-item" onClick={() => handaleSubmenu("payment", listType, record)}>
                  <i className="far fa-edit me-2" />
                  Payment Reminder
                </button>


                <button type="button" className="dropdown-item" onClick={() => handaleSubmenu("print", listType, record)}>
                  <i className="far fa-edit me-2" />
                  Print
                </button>

                <button type="button" className="dropdown-item" onClick={() => handaleSubmenu("downloadpdf", listType, record)}>
                  <i className="far fa-edit me-2" />
                  Download
                </button>

               
                <Link
                  className="dropdown-item"
                  to="#"
                  data-bs-toggle="modal"
                  data-bs-target="#duplicate_modal"
                  onClick={() => setDuplicate(record)}
                >
                 <i className="far fa-edit me-2" />
                  Duplicate
                </Link>


                        

                <button type="button" className="dropdown-item"  onClick={ ()=> handaleSubmenu("edit", listType, record)}>
                  <i className="far fa-edit me-2" />
                  Edit
                </button>


                {listType === "estimatelist" && (
                <Link
                  className="dropdown-item"
                  to="#"
                  data-bs-toggle="modal"
                  data-bs-target="#esttoinvoice_modal"
                  onClick={() => setConvertEstInvoice(record)}
                >
                 <i className="far fa-edit me-2" />
                  Convert to Invoice
                </Link>
                )}

      

                <Link
                  className="dropdown-item"
                  to="#"
                  data-bs-toggle="modal"
                  data-bs-target="#delete_modal"
                  onClick={() => setDelete(record)}
                >
                  <i className="far fa-trash-alt me-2" />
                  Delete
                </Link>
              </div>
            </div>
          </div>
      
      ),
    
    },
  ];





  let currentdate =   new Date();
  let currentdateex =  new Date(currentdate.getTime() - (30 * 24 * 60 * 60 * 1000));


  const [filteroption, setFilterOptions] = useState({linkModeType: "invoicelist",
    fdate: currentdateex,
    tdate: currentdate,
    filterText:"",client_id:"", is_export:false});
  const handleApplyFilterOptions = () => {
    setShow(false);
    getInvoices(); // Reload parent data
  };
  const [forecereset, setForecereset] = useState(false);
  const handleApplyResetApply = () => {

    setFilterOptions((prev) => ({
      ...prev,
      searchterm: "",fdate:currentdateex ,tdate:currentdate, client_id: null
    }));
    setSearchTerm("");
   
    setShow(false);
    
    setForecereset(true);
  };

  useEffect(() => {
    if (forecereset) {
     
      getInvoices(); // Reload parent data
      setForecereset(false);
    }
  }, [forecereset]);


  // Close sidebar on outside click
   useEffect(() => {
  const handleClickOutside = () => {
    console.error("handleClickOutside: " + menu);

    // setTimeout(() => {
    //   if (menu) {
    //    setMenu(false);
    //  }

    // }, 2000); // Delay of 2 seconds (2000 milliseconds)

    // const targ = e.target.closest('.slide-nav'); // Find the closest element with class 'sidebar'

    // if (targ) {
    //   targ.classList.remove("slide-nav");
    // }
   
    
  };

  document.addEventListener("click", handleClickOutside);

  return () => {
    document.removeEventListener("click", handleClickOutside);
  };
}, [menu]); // Add 'menu' as a dependency

  // Close sidebar on outside click

  const forceMenuClose = () => {
    setMenu(false);
  };


  if (loading) {
    return <Spinners />; // Display the spinner while loading
  }

  return (
    <>
      <div className={`main-wrapper ${menu ? "slide-nav" : ""}`} >
        <HeaderInvoice onMenuClick={() => toggleMobileMenu()}    onApplyFilter={handleApplyFilter} searchTerm = {searchTerm} forceMenuClose={forceMenuClose}/>
        <Sidebar />

        <div className="page-wrapper">
          <div className="content container-fluid">
            <InvoiceHead setShow={setShow} show={show} customtitle={customtitle} listType = {listType}  invoiceBlocks = {invoiceBlocks} userdata = {userdata}  />

           
            {/* Table */}
            <div className="row">
              <div className="col-sm-12">
                <div className="card-table">
                  <div className="card-body invoiceList">
                    <div className="table-responsive table-hover">
                      <Table
                        pagination={{
                          total: filteredDataSource.length,
                          showTotal: (total, range) =>
                            `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                        
                        }}
                      
                        columns={columns}
                        dataSource={filteredDataSource}
                        rowKey={(record) => record.id}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* /Table */}
          </div>
        </div>

       

        <InvoiceModalFilter setShow={setShow} show={show}  listType = {listType}  filteroption = {filteroption} setFilterOptions ={setFilterOptions} 
            handleApplyFilterOptions = {handleApplyFilterOptions} handleApplyResetApply = {handleApplyResetApply}  />

            

        <div
          className="modal custom-modal fade"
          id="delete_modal"
          role="dialog"
        >
          <div className="modal-dialog modal-dialog-centered modal-md">
            <div className="modal-content">
              <div className="modal-body">
                <div className="form-header">
                  <h3>Delete {customtitle}</h3>
                  {deleteact && (
                  <p>Are you sure you want to delete the Invoice No.: {deleteact.invoice_number}?</p>
                  )}
                </div>
                <div className="modal-btn delete-action">
                  <div className="row">
                    <div className="col-6">
                      <button
                        type="reset"
                        data-bs-dismiss="modal"
                        className="w-100 btn btn-primary paid-continue-btn"
                        onClick={() => handleDelete(deleteact)}
                      >
                        Delete
                      </button>
                    </div>
                    <div className="col-6">
                      <button
                        type="submit"
                        data-bs-dismiss="modal"
                        className="w-100 btn btn-primary"
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


<div
className="modal custom-modal fade"
id="duplicate_modal"
role="dialog">
<div className="modal-dialog modal-dialog-centered modal-md">
  <div className="modal-content">
    <div className="modal-body">
      <div className="form-header">
        <h3>Duplicate {customtitle}</h3>
        {duplicate && (
        <p>Are you sure you want to Duplicate {duplicate.invoice_number}?</p>
        )}
      </div>
      <div className="modal-btn delete-action">
        <div className="row">
          <div className="col-6">
            <button
              type="reset"
              data-bs-dismiss="modal"
              className="w-100 btn btn-primary paid-continue-btn"
              onClick={() => handleDuplicate(listType, duplicate)}
            >
              Ok
            </button>
          </div>
          <div className="col-6">
            <button
              type="submit"
              data-bs-dismiss="modal"
              className="w-100 btn btn-primary "
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</div>



<div
className="modal custom-modal fade"
id="esttoinvoice_modal"
role="dialog">
<div className="modal-dialog modal-dialog-centered modal-md">
  <div className="modal-content">
    <div className="modal-body">
      <div className="form-header">
        <h3>Create Invoice</h3>
        {convertestinv && (
        <p>Are you sure you want to create an Invoice based on Estimate {convertestinv.invoice_number}?</p>
        )}
      </div>
      <div className="modal-btn delete-action">
        <div className="row">
          <div className="col-6">
            <button
              type="reset"
              data-bs-dismiss="modal"
              className="w-100 btn btn-primary paid-continue-btn"
              onClick={() => handleConvertInvoice(listType, convertestinv)}
            >
              Ok
            </button>
          </div>
          <div className="col-6">
            <button
              type="submit"
              data-bs-dismiss="modal"
              className="w-100 btn btn-primary Paymnet"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</div>

  </>

  );
};

export default InvoiceList;
