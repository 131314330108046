import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from 'react-router-dom';
import Header from "../../layouts/Header";
import Sidebar from "../../layouts/Sidebar";
import "../../_components/antd.css";
import { Table } from "antd";



import CreditHead from "./creditHead";
import AddVendor from "../../customers/vendors/addVendor";

import InvoiceService from '../../services/invoice.service';
// eslint-disable-next-line no-unused-vars
import { formatDateISO, getCurrentDateISO, getExtendDateISO } from '../../services/Helper';



const ClientLedger = () => {

  // eslint-disable-next-line no-unused-vars
  const history = useHistory();
  // eslint-disable-next-line no-unused-vars
  const location = useLocation();

  const [menu, setMenu] = useState(false);
  const [show, setShow] = useState(false);

  // eslint-disable-next-line no-unused-vars
  const [datasource, setLoadData] = useState(false);

  // eslint-disable-next-line no-unused-vars
  const [client, setClientDetail] = useState(null);
    // eslint-disable-next-line no-unused-vars
  const [filteroption, setFilterOptions] = useState({ client_id: "", linkModeType: "", fdate: "", tdate: "", uniqueno: "", filterText: "" });

  const [selectedinvoice, setSelectedInvoice] = useState(null);


  // eslint-disable-next-line no-unused-vars
  const [dataclosebal, setDataCloseBal] = useState({ cr_sum: 0, dr_sum: 0, closingbal_sum_dr: 0, closingbal_sum_cr: 0 });

  // eslint-disable-next-line no-unused-vars

  const toggleMobileMenu = () => {
    setMenu(!menu);
  };



  const handleParentViewLedger = () => {
    console.log("handleParentViewLedger 2222");
    console.log(selectedinvoice);

    history.push(`/view-ledger`, { selectedinvoice });
  };


  useEffect(() => {
    console.log("ViewLedger Data");


    if (selectedinvoice) {


      // Calculate sum of cr_amount and dr_amount
      const sumCrAmount = datasource.reduce((total, item) => total + item.cr_amount, 0);
      const sumDrAmount = datasource.reduce((total, item) => total + item.dr_amount, 0);

      let closingBalanceCr = 0;
      let closingBalanceDr = 0;

      if (sumCrAmount > sumDrAmount) {
        closingBalanceCr = sumCrAmount - sumDrAmount;
        closingBalanceCr = closingBalanceCr.toFixed(2);
      }
      if (sumDrAmount > sumCrAmount) {
        closingBalanceDr = sumDrAmount - sumCrAmount;
        closingBalanceDr = closingBalanceDr.toFixed(2);
      }

      console.log(sumCrAmount + '---' + sumDrAmount + '---' + closingBalanceCr + '::' + closingBalanceDr);



      // Update state with calculated sums `Showing ${range[0]} to ${range[1]} of ${total} entries`,
      setDataCloseBal({
        cr_sum: `${selectedinvoice.symbol}${sumCrAmount.toFixed(2)}`,
        dr_sum: `${selectedinvoice.symbol}${sumDrAmount.toFixed(2)}`,
        closingbal_sum_dr: closingBalanceDr > 0 ? `${selectedinvoice.symbol}${Math.abs(closingBalanceDr)} Dr` : '',
        closingbal_sum_cr: closingBalanceCr > 0 ? `${selectedinvoice.symbol}${Math.abs(closingBalanceCr)} Cr` : '',

      });
    }

  }, [datasource]);



  useEffect(() => {
    console.log("ClientLedger  " + JSON.stringify(location.state?.selectedinvoice.customer, null, 2));
    console.log(location.state?.selectedinvoice.customer);

    setSelectedInvoice(location.state?.selectedinvoice);
    setClientDetail(location.state?.selectedinvoice.customer)

    setFilterOptions((prev) => ({
      ...prev,
      client_id: location.state?.selectedinvoice.customer.id,
      uniqueno: location.state?.selectedinvoice.uniqueno,
      linkModeType: "invoicelist",
    }));

    



  }, [location.state?.selectedinvoice]);




  useEffect(() => {
    if (client && client.id) {
      console.log("ClientLedger   3333", JSON.stringify(client, null, 2));
  
      getLedgerCurrency(client.id);
      getLedgerData(filteroption);
  
      setFilterOptions((prev) => ({
        ...prev,
        client_id: client.id,
        fdate: "",
        tdate: "",
        linkModeType: "invoicelist",
      }));
    }
  }, [client]);
  
  // eslint-disable-next-line no-unused-vars
  const getLedgerData = async (filteroption) => {
    console.log("getLedgerData   filteroption", JSON.stringify(filteroption, null, 2));
    try {
      let res = await InvoiceService.getCustomerStandingBalance(filteroption);

      setLoadData(res.data);
      console.log("getLedgerData", res.data);
    } catch (error) {
      console.error('Login failed:', error);
    }
  };
  // eslint-disable-next-line no-unused-vars
  const getLedgerCurrency = async (client_id) => {
    try {
      let res = await InvoiceService.getCustomerTransCurr(client_id);
      //  setLoadData(res.data);
      console.log("getLedgerData", res);
    } catch (error) {
      console.error('Login failed:', error);
    }
  };





  const columns = [
    {
      title: "#",
      dataIndex: "id",
      render: (text, record, index) => index + 1,
      sorter: (a, b) => a.id.length - b.id.length,
    },


    {
      title: "Date",
      dataIndex: "payment_date",
      render: (text, record) => (
        <>
          <span>{formatDateISO(record.payment_date)}</span>

        </>
      ),
      sorter: (a, b) => a.payment_date.length - b.payment_date.length,
    },

    {
      title: "",
      dataIndex: "payment_date",
      render: (text, record) => (
        <>
          <span>{record.brought_forward}</span>

        </>
      ),
      sorter: (a, b) => a.payment_date.length - b.payment_date.length,
    },

    {
      title: "Debit",
      dataIndex: "dr_amount",
      render: (text, record) => (
        <>

          <span> {record.symbol}{record.dr_amount}</span>

        </>
      ),
      sorter: (a, b) => a.dr_amount.length - b.dr_amount.length,
    },

    {
      title: "Credit",
      dataIndex: "cr_amount",
      render: (text, record) => (
        <>

          <span> {record.symbol}{record.cr_amount}</span>

        </>
      ),
      sorter: (a, b) => a.cr_amount.length - b.cr_amount.length,
    },


  ];

  const forceMenuClose = () => {  
    setMenu(false);
  };

  return (
    <>
      <div className={`main-wrapper ${menu ? "slide-nav" : ""}`}>
        <Header onMenuClick={() => toggleMobileMenu()}  forceMenuClose={forceMenuClose}  />
        <Sidebar />

        <div className="page-wrapper">
          <div className="content container-fluid">
            {/* Page Header */}

          
            <CreditHead setShow={setShow} show={show} selectedinvoice={selectedinvoice} handleParentViewLedger={handleParentViewLedger} />


            <div className="row">
              <div className="col-sm-12">
                <div className="card-table">
                  <div className="card-body ClientLedger">
                    <div className="table-responsive table-hover">
                      <Table
                        pagination={{
                          total: datasource.length,
                          showTotal: (total, range) =>
                            `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                          // showSizeChanger: true,

                        }}
                        columns={columns}
                        dataSource={datasource}
                      />



                      <div className="row">
                        <div className="col-lg-6">

                        </div>
                        <div className="col-lg-6">
                          <div className="card">
                            <div className="card-header">
                              <h5 className="card-title">Closing Balance</h5>
                            </div>
                            <div className="card-body">
                              <div className="table-responsive">
                                <table className="table table-striped mb-0">

                                  <tbody>
                                    <tr>
                                      <td>Total</td>
                                      <td>{dataclosebal.dr_sum} Dr</td>
                                      <td>{dataclosebal.cr_sum} Cr</td>
                                    </tr>
                                    <tr>
                                      <td>Closing Balance</td>
                                      <td>{dataclosebal.closingbal_sum_dr}</td>
                                      <td>{dataclosebal.closingbal_sum_cr}</td>
                                    </tr>

                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>



                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* /Table */}
          </div>
        </div>

        <AddVendor setShow={setShow} show={show} />

        <div
          className="modal custom-modal fade"
          id="delete_modal"
          role="dialog"
        >
          <div className="modal-dialog modal-dialog-centered modal-md">
            <div className="modal-content">
              <div className="modal-body">
                <div className="form-header">
                  <h3>Delete Credit Notes</h3>
                  <p>Are you sure you want to delete?</p>
                </div>
                <div className="modal-btn delete-action">
                  <div className="row">
                    <div className="col-6">
                      <button
                        type="reset"
                        data-bs-dismiss="modal"
                        className="w-100 btn btn-primary paid-continue-btn"
                      >
                        Delete
                      </button>
                    </div>
                    <div className="col-6">
                      <button
                        type="submit"
                        data-bs-dismiss="modal"
                        className="w-100 btn btn-primary "
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ClientLedger;
